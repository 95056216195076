import React, { useState, useEffect } from 'react';
import {
    Loading,
    Card,
    Page,
    DescriptionList,
} from '@shopify/polaris';

import { Redirect, useParams } from 'react-router-dom';
import axios from 'axios';
import baseUrl from '../../Url';

export default function DoctorDetails(props: any) {
    const [toSignin, setToSignin] = useState(false);

    const { id } = useParams() as {
        id: string;
    };

    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;
    const [qualification, setQualification] = useState('');
    const [secondHighestqualification, setSecondHighestqualification] = useState('');
    const [yearexp, setYearexp] = useState('');
    const [mbbsDegree, setMbbsDegree] = useState('');
    const [registration, setRegistration] = useState<any[]>([]);
    const [workExpName, setWorkExpName] = useState<any[]>([]);
    const [awardName, setAwardName] = useState<any[]>([]);
    const [languageName, setLanguageName] = useState<any[]>([]);
    const [membershipName, setMembershipName] = useState<any[]>([]);
   
    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    // doctor list GET API
    const getDoctorDetailsList = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + `/landingeapp/api/doctor-details-by-id/${id}`, { headers })
            .then((res) => {
                setIsLoading(false);
                let doctorData = res.data.doctorDetails;
                setQualification((doctorData.qualification == "" || doctorData.qualification == null) ? " " : doctorData.qualification.highestQualification.label);
                setSecondHighestqualification((doctorData.qualification == "" || doctorData.qualification == null || doctorData.qualification.secondHighestQualification == null) ? " " : doctorData.qualification.secondHighestQualification.label);
                setMbbsDegree((doctorData.qualification == "" || doctorData.qualification == null || doctorData.qualification.mbbsDegree == null) ? " " : doctorData.qualification.mbbsDegree.label);

                setYearexp((doctorData.yearofExperience == "" || doctorData.yearofExperience == null) ? " " : doctorData.yearofExperience.totalYearsOfPracticeAfterHighestQualification);
                let doctorexp = res.data.doctorDetails.workexperience;
                console.log("docexp===>", doctorexp);
                let workValueList = [];
                for (var i = 0; i < doctorexp.length; i++) {
                    console.log("docexplist===>", doctorexp[i].name);
                    let description = (doctorexp[i].name == "") ? "No Details Available" : doctorexp[i].name;
                    workValueList.push([
                        description

                    ]);
                }
                setWorkExpName(workValueList);
                let doctorAward = res.data.doctorDetails.awards;
                let awardList = [];
                for (var j = 0; j < doctorAward.length; j++) {
                    let awards = (doctorAward[j].description == "" || doctorAward[j].description == null) ? "No Details Available" : doctorAward[j].description;
                    awardList.push([
                        awards

                    ]);
                }
                setAwardName(awardList);
                let doctorLanguage = res.data.doctorDetails.language;
                let languageList = [];
                for (var k = 0; k < doctorLanguage.length; k++) {
                    let languages = (doctorLanguage[k].label == "") ? "No Details Available" : doctorLanguage[k].label;
                    languageList.push([
                        languages

                    ]);
                }
                setLanguageName(languageList);
                let doctorMembership = res.data.doctorDetails.membership;
                let membershipList = [];
                for (var l = 0; l < doctorMembership.length; l++) {
                    let membership = (doctorMembership[l].name == "") ? "No Details Available" : doctorMembership[l].name;
                    membershipList.push([
                        membership

                    ]);
                }
                setMembershipName(membershipList);
                let doctorRegIdNumber = res.data.doctorDetails.registration;
                let doctorRegIdNumberList = [];
                for (var m = 0; m < doctorRegIdNumber.length; m++) {
                    let regIdNumber = (doctorRegIdNumber[m].idNumber == "") ? "No Details Available" : doctorRegIdNumber[m].idNumber;
                    let regIdType = (doctorRegIdNumber[m].idType == null) ? "No Details Available" : doctorRegIdNumber[m].idType.label;
                    let regNumber = regIdNumber + " " + regIdType;
                    doctorRegIdNumberList.push([
                        regNumber
                    ]);
                }
                setRegistration(doctorRegIdNumberList);


            })
            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getDoctorDetailsList()
    }, []);





    const doctorDetails = (
        <Card>
            <Card.Section>

                <DescriptionList
                    items={[
                        {
                            term: 'Academic Qualifications:',
                            description: `${qualification}` + "," + `${secondHighestqualification}` + "," + `${mbbsDegree}`
                        },
                        {
                            term: 'Registration with Medical Council:',
                            description: `${registration.join(", ")}`
                        },
                        {
                            term: 'Language Skills:',
                            description: `${languageName.join(", ")}`
                        },
                        {
                            term: 'Years of Experience after Post-graduation:',
                            description: `${yearexp} years`
                        },
                        {
                            term: 'Work Experience:',
                            description: `${workExpName.join(", ")}`
                        },
                        {
                            term: 'Professional Affiliations:',
                            description: `${membershipName.join(", ")}`
                        },
                        {
                            term: 'Awards Received:',
                            description: `${awardName.join(", ")}`
                        },
                    ]}
                />
            </Card.Section>
        </Card >
    )

    return (
        <Page
            fullWidth
            title="Doctor Details"
        >
            {loadingMarkup}
            {doctorDetails}
        </Page>
    );
}