import React, { useState, useCallback, useEffect } from 'react';
import {
    Loading,
    Layout,
    Banner,
    Heading,
    Page
} from '@shopify/polaris';
import { Box } from '@primer/components';
import { initializeIcons } from '@uifabric/icons'
import { SelectPicker, } from 'rsuite';
import axios from 'axios';
import baseUrl from '../../Url';
import { Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
initializeIcons();

interface AccountProps {
    onAction(): void;
}

export default function AppointmentSelectPatient(props: any) {
    const { patientid } = useParams() as {
        patientid: string;
    };
    const [toSignin, setToSignin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;
    const [title, setTitle] = useState('Patient Appointment');
    const [bannerbody, setbannerbody] = useState('Patient should be selected for booking an appointment. If patient is not available please add them from add patient.');
    const { patientIdsend } = props;

    const [selectedPatientName, setSelectedPatientName] = useState(0);
    const handlePatientNameChange = useCallback((value) => {
        setSelectedPatientName(value);
        console.log("VALUE IN ApptSelectPatientcomponent==>", value);
        patientIdsend(value);
    }, []);
    const [patientName, setPatientName] = useState([]);
    const userToken = localStorage.getItem("token");

    const closebanner = () => {
        setbannerbody('');
        setTitle('');
    }

    const bannermarkup = bannerbody
        ? <Banner onDismiss={closebanner}>
            <Heading>{title}</Heading>
            <p>{bannerbody}</p>
        </Banner>
        : '';

    const getPatientList = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/patientapp/api/patient/patient_list_dropdown', {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Token ${userToken} `
            }
        })
            .then((res) => {
                setIsLoading(false);
                let response = res.data.patientList;
                console.log(response);
                setPatientName(response);
            })
            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
                console.log(toSignin);
            })
    }

    useEffect(() => {
        // Do api call here.... 
        window.scrollTo(0,0);
        getPatientList();
        if(patientid!= null || patientid!= undefined){
            setSelectedPatientName(Number(patientid));
        }
        else{
            setSelectedPatientName(0); 
        }
    }, []);

    const symptomsAddInfo = (
        <Layout>
            <Layout.Section>
                {bannermarkup}
            </Layout.Section>
            <Layout.AnnotatedSection
                title="Select Patient"
                description="Let Select Patient from patient list for whom you want an appointment."
            >
                <Box p={5}>
                    <Typography style={{ marginBottom: "5px" }}>Patient Name</Typography>
                    <SelectPicker
                        block
                        onChange={handlePatientNameChange}
                        data={patientName}
                        value={selectedPatientName}
                    />
                </Box>
            </Layout.AnnotatedSection>
        </Layout>
    )
    return (
       <Page fullWidth>
            {loadingMarkup}
            {symptomsAddInfo}
        </Page>
    );
}