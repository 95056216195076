import React, { useState, useCallback, useEffect } from 'react';
import {
    Card,
    FormLayout,
    TextField,
    InlineError,
    Banner,
    TextStyle,
    Button,
    Loading,
    DataTable,
    Link,
    Scrollable,
    Modal,
    ExceptionList
} from '@shopify/polaris';
import { Box } from '@primer/components';
import { initializeIcons } from '@uifabric/icons';
import baseUrl from '../../../Url';
import axios from 'axios';
import { NoteMinor } from '@shopify/polaris-icons';
initializeIcons();

interface AccountProps {
    onAction(): void;
}

export default function MasterImportedConfig() {
    const [importEmail, setImportEmail] = useState('');
    const [importWhatsapp, setImportWhatsapp] = useState('');
    const [editMasterConfigId, setEditMasterConfigId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const handleEmailChange = useCallback((valueA) => {
        setImportEmail(valueA)
    }, []);

    const handleWhatsappChange = useCallback((valueB) => {
        setImportWhatsapp(valueB)
    }, []);

    const [phonenumber, setPhonenumber] = useState('');
    const handleChange = useCallback((newValue) => {
        setPhonenumber(newValue)
    }, []);
    const loadingMarkup = isLoading ? <Loading /> : null;

    const closeerror = () => {
        setError('')
    }


    const [error, setError] = useState('');
    const errorDiv = error
        ? <Banner
            onDismiss={closeerror}
            status="critical">
            <p>{error}</p>
        </Banner>
        : '';

    const closemanualerror = () => {
        setManualError('')
    }

    const [manualerror, setManualError] = useState('');
    const manualerrorDiv = manualerror
        ? <Banner
            onDismiss={closemanualerror}
            status="critical">
            <p>{error}</p>
        </Banner>
        : '';

    const [editsuccess, setEditMasterimportsuccess] = useState('');
    const [addsuccess, setaddMasterimportsuccess] = useState('');

    const closeSuccess = () => {
        setEditMasterimportsuccess('');
        setaddMasterimportsuccess('');
    }
    const successDiv = editsuccess
        ? <Banner
            onDismiss={closeSuccess}
            status="success">
            <p>{editsuccess}</p>
        </Banner>
        : '';
    const addsuccessDiv = addsuccess
        ? <Banner
            onDismiss={closeSuccess}
            status="success">
            <p>{addsuccess}</p>
        </Banner>
        : '';

    const [instituteerror] = useState('');
    const instituteErrordiv = instituteerror
        ? <TextStyle variation="negative">{instituteerror}</TextStyle>
        : '';

    const [modalActive, setModalActive] = useState(false);
    const handleToggle = () => {
        setModalActive((modalActive) => !modalActive);
        setEditMasterConfigId('');
        setImportEmail('');
        setImportWhatsapp('');
        setPhonenumber('');
        setEditMasterimportsuccess("");
        setaddMasterimportsuccess("");
        setError("");
    }
    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }
    const addmasterconfigbody = {
        "email": importEmail,
        "phoneNumber": phonenumber,
        "whatsappNo": importWhatsapp
    }

    const addSymptomSubmit = () => {

        let url, newAwardBody;
        if (editMasterConfigId) {
            url = baseUrl.baseUrl + '/patientapp/api/dataimport/edit_master_import_config';
            newAwardBody = Object.assign(addmasterconfigbody, { masterConfigId: editMasterConfigId })
        }
        else {
            url = baseUrl.baseUrl + '/patientapp/api/dataimport/add_master_import_config';

        }
        console.log(newAwardBody);
        axios.post(url, JSON.stringify(addmasterconfigbody), { headers }).then(result => {
            setIsLoading(false);
            if (result) {
                setError('');
                setError("");
                setEditMasterimportsuccess("");
                setaddMasterimportsuccess(result.data.message);
                getMasterConfig();
            }
        }).catch((error) => {
            setIsLoading(false);
            setEditMasterimportsuccess("");
            setaddMasterimportsuccess("");
            setError(error.response.data.message);
        });

    }

    const [masterConfigList, setMasterConfigList] = useState<any[]>([]);
    const getMasterConfig = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/patientapp/api/dataimport/get_master_import_config_list', {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Token ${userToken} `
            }
        })
            .then((res) => {
                setIsLoading(false);
                let response = res.data.masterDataImportConfig;
                let masterConfig = [];
                for (var i = 0; i < response.length; i++) {
                    let masterConfigId = response[i].id;
                    let masterConfigEmail = response[i].email;
                    let masterConfigCreatedate = (new Date(response[i].createDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let masterConfigModifieddate = (new Date(response[i].modifiedDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let masterConfigPhno = response[i].phoneNumber;
                    let masterConfigWhatsappno = response[i].whatsappNo;
                    masterConfig.push([
                        <Link key="phone-number">{response[i].email}</Link>,
                        (new Date(response[i].createDate)).toLocaleDateString('en-US', DATE_OPTIONS),
                        (new Date(response[i].modifiedDate)).toLocaleDateString('en-US', DATE_OPTIONS),
                        response[i].phoneNumber,
                        response[i].whatsappNo,
                        <Button onClick={() => editConfigSubmit(masterConfigId, masterConfigEmail, masterConfigCreatedate, masterConfigModifieddate, masterConfigPhno, masterConfigWhatsappno)}>Edit</Button>]);
                }
                setMasterConfigList(masterConfig);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);

            })
    }
    // edit onclick func....................

    function editConfigSubmit(masterConfigId: any, masterConfigEmail: any, masterConfigCreatedate: any, masterConfigModifieddate: any, masterConfigPhno: any, masterConfigWhatsappno: any) {
        setModalActive((modalActive) => !modalActive);
        setEditMasterConfigId(masterConfigId);
        setImportEmail(masterConfigEmail);
        setImportWhatsapp(masterConfigPhno);
        setPhonenumber(masterConfigWhatsappno);
        setEditMasterimportsuccess("");
        setaddMasterimportsuccess("");
        setError("");
    }

    useEffect(() => {
        // get call here.... 
        getMasterConfig();
    }, []);

    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const masterImportConfigList = masterConfigList ? (
        <Scrollable shadow style={{ height: '400px' }} >
            <Card.Section>
                {masterConfigList && masterConfigList.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'numeric',
                            'numeric',
                            'numeric',
                            'numeric',
                        ]}
                        headings={[
                            'Email',
                            'Create date',
                            'Modified date',
                            'Phone Number',
                            'Whatsapp Number',
                            'Edit'
                        ]}
                        rows={masterConfigList}
                    />
                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Documents is available!',
                            },
                        ]}
                    />)}
            </Card.Section>
        </Scrollable>
    ) : (
        <p>No Details available</p>
    );

    const symptomsAddInfo = (
        <div>
            {loadingMarkup}
            <Card>
                <Card.Header title="Import Configs">
                    <Button primary={true} onClick={handleToggle}>Add config</Button>
                </Card.Header>
                {masterImportConfigList}
            </Card>
            <br />
            <br />
            <Modal
                large
                open={modalActive}
                onClose={handleToggle}
                title={editMasterConfigId ? "Update Import Config" : "Add Import Config"}
                primaryAction={{
                    content: 'Submit',
                    onAction: addSymptomSubmit,
                }}
            >
                <Modal.Section>
                    <Box p={3}>
                        <FormLayout>
                            <FormLayout.Group condensed>
                                <div>
                                    <TextField
                                        value={importEmail}
                                        onChange={handleEmailChange}
                                        label=" Email to be used to import patient data"
                                        type="email"
                                        placeholder="Enter Your Email Address"
                                        id="Emailname"
                                    /><br />
                                    <InlineError message={instituteErrordiv} fieldID="Emailname" />

                                    <TextField
                                        value={importWhatsapp}
                                        onChange={handleWhatsappChange}
                                        label=" Whatsapp number to be used to import patient data"
                                        type="number"
                                        placeholder="Enter Whatsapp Number"
                                    />

                                </div>
                                <div>
                                    <TextField
                                        label="Phone Number"
                                        type="number"
                                        value={phonenumber}
                                        onChange={handleChange}
                                        placeholder="Please enter phone number"
                                    />
                                </div>
                            </FormLayout.Group>
                            <div>{addsuccessDiv}</div>
                            <div>{successDiv}</div>
                            <div>{errorDiv}</div>
                            <div>{manualerrorDiv}</div>
                        </FormLayout>
                    </Box>
                </Modal.Section>
            </Modal>
        </div >
    )
    return (
        <div>
            {symptomsAddInfo}
        </div>
    );
}