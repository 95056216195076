import React, { useState, useEffect } from 'react';
import {
    Loading,
    Card,
    Page,
    Button,
    ExceptionList
} from '@shopify/polaris';

import { useHistory, Redirect } from 'react-router-dom';
import { AccessibilityMajor, NoteMinor } from '@shopify/polaris-icons';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../redux/store/Store';
import { getTaskList } from '../../../redux/effects/ApiCall';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { DataGrid, GridCellParams, GridColDef } from '@material-ui/data-grid';
export default function Task() {
    const defaultMaterialTheme = createMuiTheme({
        palette: {
            primary: {
                // light: will be calculated from palette.primary.main,
                main: '#BBE5B3',
                // dark: will be calculated from palette.primary.main,
                // contrastText: will be calculated to contrast with palette.primary.main
            },
            secondary: {
                main: '#50B83C',
            }
        },
        typography: {
            fontSize: 22,
        }
    });
    const loadingMarkup = <Loading />;
    const dispatch = useDispatch();
    const history = useHistory();
    function viewTaskDetails(rowIndex: any) {
        history.push(`/taskdetails/${rowIndex}`);
    }
    useEffect(() => {
        dispatch(getTaskList());
    }, [dispatch]);

    const refreshTaskList = () => {
        dispatch(getTaskList());
    }

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'View',
            width: 100,
            renderCell: (params: GridCellParams) => (
                <Button icon={AccessibilityMajor} onClick={() => {
                    viewTaskDetails(params.value)
                }}>View</Button>),
        },
        { field: 'title', headerName: 'Title', width: 270 },
        { field: 'whyWant2ndOpinion', headerName: 'Problem Name', width: 307 },
        { field: 'patientName', headerName: 'Patient name', width: 150 },

        {
            field: 'consultationType',
            headerName: 'Type',
            width: 307
        },
    ];

    const taskReduxValue = useSelector((state: AppState) => state.taskValue);
    let taskList = taskReduxValue.taskValue;
    let taskListConfig: any = [];
    const taskListValue: any[] = taskListConfig;
    console.log("IndexArray===>", taskListValue);

    return (
        <Page fullWidth title='Task List'>
            {taskReduxValue.loading ? loadingMarkup : ''}
            <ThemeProvider theme={defaultMaterialTheme}>
                <CssBaseline />
                <Card>
                    <Card.Header>
                        <Button primary onClick={refreshTaskList}>Reload Task List</Button>
                    </Card.Header>
                    <Card.Section>
                        {taskList && taskList.length ? (
                            <div style={{ height: 600, width: '100%' }}>
                                <DataGrid
                                    rows={taskList}
                                    columns={columns}
                                    pageSize={10}
                                    hideFooter={true}
                                    disableSelectionOnClick
                                />
                            </div>
                        ) : (
                            <ExceptionList
                                items={[
                                    {
                                        icon: NoteMinor,
                                        description: 'No Task is available!',
                                    },
                                ]}
                            />)}
                    </Card.Section>
                </Card>
            </ThemeProvider>
        </Page>
    );
}