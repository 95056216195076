import React, { useState, useEffect, useCallback } from 'react';
import {
    Loading,
    Layout,
    Banner,
    Button,
    Heading,
    Page,
    Card,
    DataTable,
    Stack,
    TextStyle,
    Modal,
    FormLayout,
    TextField,
    InlineError,
    ExceptionList,
    Toast,
} from '@shopify/polaris';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import baseUrl from '../../Url';
import { Redirect, useParams } from 'react-router-dom';
import { Box } from '@primer/components';
import { PaymentsMajor, AccessibilityMajor, NoteMinor, RefreshMinor } from '@shopify/polaris-icons';


export default function AppointmentPayment(props: any) {
    const [toSignin, setToSignin] = useState(false);
    const { caseid } = useParams() as {
        caseid: string;
    };
    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;
    // const { paymentDetails } = props;
    const [, setIsDisable] = useState(true);

    const [consultationType, setConsultationType] = useState('');
    const [doctorTeamType, setDoctorTeamType] = useState('');
    const [responseTimeType, setResponseTimeType] = useState('');

    const [toastSuccessMessage, setToastSuccessMessage] = useState('');
    const [toastSuccessActive, setToastSuccessActive] = useState(false);
    const toggleToastSuccessActive = useCallback(() => setToastSuccessActive((toastSuccessActive) => !toastSuccessActive), []);

    const toastSuccessMarkup = toastSuccessActive ? (
        <Toast content={toastSuccessMessage} onDismiss={toggleToastSuccessActive} />
    ) : null;

    const [toastErrorMessage, setToastErrorMessage] = useState('');
    const [toastErrorActive, setToastErrorActive] = useState(false);
    const toggleToastErrorActive = useCallback(() => setToastErrorActive((toastErrorActive) => !toastErrorActive), []);

    const toastErrorMarkup = toastErrorActive ? (
        <Toast content={toastErrorMessage} onDismiss={toggleToastErrorActive} />
    ) : null;

    const [paymentDetailsId, setPaymentDetailsId] = useState('');
    function checkPaymentStatusDetails(id: any) {
        setPaymentDetailsId(id);
        checkPaymentStatusDetailsApicall(id);
    }
    const [appoinmentRequestListValue, setAppoinmentRequestListValue] = useState<any[]>([]);


    function checkPaymentStatusDetailsApicall(id: any) {
        console.log("PAYMENTID==>", id);
        axios.post(baseUrl.baseUrl + '/paymentapp/api/payment/get_payment_status',
            { "paymentId": id }, { headers })
            .then(result => {
                setIsLoading(false);
                if (result) {
                    setToastSuccessMessage(result.data.message);
                    getPaymentList();
                }
            })
            .catch((error) => {
                setToastErrorMessage(error.response.data.message);
                setIsLoading(false);
            })
    }

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    const body = {
        "caseId": caseid
    };

    const getPaymentList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/paymentapp/api/payment/get_payment_link', body, { headers })
            .then((res) => {
                setIsLoading(false);
                let resData = res.data;
                let consultationValue = (resData.consultationType === "") ? "Not Selected" : resData.consultationType;
                let responseTimeValue = (resData.responseTimeType === "") ? "Not Selected" : resData.responseTimeType;
                let doctorSelect = (resData.doctorTeamType === "") ? "Not Selected" : resData.doctorTeamType;
                setConsultationType(consultationValue);
                setResponseTimeType(responseTimeValue);
                setDoctorTeamType(doctorSelect);
                let paymentresData = res.data.paymentList;
                console.log("PAYMENTLIST==>", paymentresData);
                let paymentsValueList = [];
                for (var i = 0; i < paymentresData.length; i++) {
                    let paymentid = paymentresData[i].id;
                    let selectedResponse = paymentresData[i].paymentLink;
                    let description = (paymentresData[i].note == "") ? "No Details Available" : paymentresData[i].note;
                    let amount = (paymentresData[i].amount == "") ? "Amount Not Available" : paymentresData[i].amount;
                    let round_amount = amount / 100;
                    let appoinmentPaidTime = (paymentresData[i].hasOwnProperty('paidTime')) ? (paymentresData[i].paidTime === "" || paymentresData[i].paidTime === null) ? "" : (new Date(paymentresData[i].paidTime)).toLocaleDateString('en-US', DATE_OPTIONS) + " at " + (new Date(paymentresData[i].paidTime)).getHours() + ":" + (new Date(paymentresData[i].paidTime)).getMinutes(): "";
                    let createDate = (paymentresData[i].create_date == null) ? "" : (new Date(paymentresData[i].create_date)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let paymentstatus = (paymentresData[i].status === null) ? "" : paymentresData[i].status;
                    console.log("DATEDATA==>", createDate);
                    paymentsValueList.push([
                        description,
                        createDate,
                        "Rs. " + round_amount,
                        <Button icon={PaymentsMajor} size="slim" disabled={ (paymentstatus === "paid" || paymentstatus === "free") ? true : false} onClick={() => paymentLink(selectedResponse)}> Pay Now</Button>,
                        appoinmentPaidTime,
                        <TextStyle>{paymentstatus}</TextStyle>,
                        <Button icon={AccessibilityMajor} size="slim" disabled={ (paymentstatus === "paid" || paymentstatus === "free") ? true : false} onClick={() => checkPaymentStatusDetails(paymentid)}></Button>
                    ]);
                }
                setChiefComplaintListValue(paymentsValueList);

            })
            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            })
    }
    const selectionTypeBody = {
        "caseId": caseid
    };
    // Selection Type GET API
    const getAppoinmentRequestList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/appointmentapp/api/appointment/get_appointment_details_for_case', selectionTypeBody, { headers })
            .then((res) => {
                setIsLoading(false);
                let resData = res.data;
                let consultationValue = (resData.consultationType === "") ? "Not Selected" : resData.consultationType;
                let responseTimeValue = (resData.responseTimeType === "") ? "Not Selected" : resData.responseTimeType;
                let doctorSelect = (resData.doctorTeamType === "") ? "Not Selected" : resData.doctorTeamType;
                setConsultationType(consultationValue);
                setResponseTimeType(responseTimeValue);
                setDoctorTeamType(doctorSelect);
                let bookData = res.data.appointmentRequestList;
                console.log("AppoinmentLIST==>", bookData);
                let appoinmentValueList = [];
                for (var i = 0; i < bookData.length; i++) {
                    let description = (bookData[i].name == "" || bookData[i].name == null) ? "No Details Available" : bookData[i].name;
                    let createdDate = (bookData[i].createDate == null) ? "" : (new Date(bookData[i].createDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let modifiedDate = (bookData[i].modifiedDate == null) ? "" : (new Date(bookData[i].modifiedDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let appoinmentStatus = (bookData[i].hasOwnProperty('status')) ? (bookData[i].status === null || bookData[i].status === "") ? "" : bookData[i].status : "";
                    let isActivestatus = (bookData[i].isActive == true) ? "True" : "False";
                    appoinmentValueList.push([
                        description,
                        createdDate,
                        isActivestatus,
                        modifiedDate,
                        appoinmentStatus
                    ]);
                }
                setAppoinmentRequestListValue(appoinmentValueList);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error.config);
            })
    }
    const [chiefComplaintListValue, setChiefComplaintListValue] = useState<any[]>([]);
    const truncate = (str: string,) => {
        return str.length > 10 ? str.substring(0, 20) + "..." : str;
    }

    function paymentLink(response: any) {
        window.open(response);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        setIsDisable(false);
        getPaymentList();
        getAppoinmentRequestList();
    }, []);



    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const tableMarkup = (
        <Card >
            <Layout>
                <Layout.Section>
                    <Box pt={2} pl={5} pr={5}>
                        <label><h4>Selectioned Options</h4></label>
                        <Box pt={5}>
                            <Stack distribution="fill">
                                <Stack.Item fill>
                                    <Heading>Consultation Type</Heading>
                                    <hr />
                                    <Box mt={2}>
                                        <Banner>
                                            {consultationType}
                                        </Banner>
                                    </Box>
                                </Stack.Item>
                                <Stack.Item fill>
                                    <Heading>Response Timing</Heading>
                                    <hr />
                                    <Box mt={2}>
                                        <Banner>
                                            {responseTimeType}
                                        </Banner>
                                    </Box>
                                </Stack.Item>
                                <Stack.Item fill>
                                    <Heading>Doctor Selection Type</Heading>
                                    <hr />
                                    <Box mt={2}>
                                        <Banner>
                                            {doctorTeamType}
                                        </Banner>
                                    </Box>
                                </Stack.Item>
                            </Stack>
                        </Box>
                    </Box>
                </Layout.Section>

                <Layout.Section>
                    <Card.Section>
                        <Box pl={3}>
                            <Card.Header title="Payment List">
                                <Button icon={RefreshMinor} primary={false} onClick={getPaymentList}>Reload Payment List</Button>
                            </Card.Header>
                            {chiefComplaintListValue && chiefComplaintListValue.length ? (
                                <DataTable
                                    columnContentTypes={[
                                        'text',
                                        'text',
                                        'text',
                                        'text',
                                        'text',
                                        'text',
                                        'text',
                                        'text'
                                    ]}
                                    headings={[
                                        'Description',
                                        'Create Date',
                                        'Amount',
                                        'Pay',
                                        'Paid On',
                                        'Payment Status',
                                        'Check Status'
                                    ]}
                                    rows={chiefComplaintListValue}
                                />
                            ) : (
                                <ExceptionList
                                    items={[
                                        {
                                            icon: NoteMinor,
                                            description: 'No Payment Details is available!',
                                        },
                                    ]}
                                />)}
                        </Box>
                    </Card.Section>
                </Layout.Section>
                <Layout.Section>
                    <Card.Section>
                        <Box pl={3}>
                            <Card.Header title="Existing Appointment Details">
                                <Button icon={RefreshMinor} primary={false} onClick={getAppoinmentRequestList}>Reload Appointment List</Button>
                            </Card.Header>

                            {appoinmentRequestListValue && appoinmentRequestListValue.length ? (
                                <DataTable
                                    columnContentTypes={[
                                        'text',
                                        'text',
                                        'text',
                                        'text',
                                        'text',
                                    ]}
                                    headings={[
                                        'Description',
                                        'Create Date',
                                        'Active',
                                        'Modified Date',
                                        'Status',
                                    ]}
                                    rows={appoinmentRequestListValue}
                                />
                            ) : (
                                <ExceptionList
                                    items={[
                                        {
                                            icon: NoteMinor,
                                            description: 'No Appointment Details is available!',
                                        },
                                    ]}
                                />)}
                        </Box>
                    </Card.Section>
                </Layout.Section>
            </Layout>
        </Card>
    );

    return (
        <Page fullWidth>
            {toSignin ? <Redirect to="/signin" /> : null}
            {loadingMarkup}
            {tableMarkup}
            {toastSuccessMarkup}
            {toastErrorMarkup}
        </Page>
    );
}