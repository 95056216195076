import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import baseUrl from '../../../Url';

import {
    Banner,
    Button,
    FormLayout,
    InlineError,
    Loading,
    TextField,
    TextStyle,
} from '@shopify/polaris';

interface AccountProps {
    onAction(): void;
}

export default function Loginwithotpcomponent() {

    const [otpemail, setOtpEmail] = useState('');
    const [errorotpemail, setErrorOtpEmail] = useState('');
    const [isLoadingforotpgenerate, setIsLoadingforotpgenerate] = useState(false);
    const [isLoadingforotpvalidate, setIsLoadingforotpvalidate] = useState(false);
    const [isDisableOTPField, setIsDisableOTPField] = useState(true);
    const [otpsuccess, setOtpSuccess] = useState('');
    const [otpError, setOtpError] = useState('');
    const [otpinvlid, setOtpInvalid] = useState('');
    const [buttonText, setButtonText] = useState('Send OTP');
    const [validotp, setvalidOtp] = useState('');
    const [errorotp, setOtp] = useState('');

    const history = useHistory();

    const handleEmailChange = useCallback((value) => {
        setOtpEmail(value);
        setErrorOtpEmail('');
    }, []);
    const handleValidOtpChange = useCallback((value) => setvalidOtp(value), []);

    const errorOtpEmail = errorotpemail
        ? <TextStyle variation="negative">{errorotpemail}</TextStyle>
        : '';
    const closeOTPErrorBanner = () => {
        setOtpError('');
    }
    const OTPErrorDiv = otpError
        ? <Banner status="critical" onDismiss={closeOTPErrorBanner}><p>{otpError}</p></Banner>
        : '';

    const closeSuccessBanner = () => {
        setOtpSuccess('');
    }

    const closeErrorBanner = () => {
        setOtpInvalid('');
    }

    const OTPText = otpsuccess
        ? <Banner status="success" onDismiss={closeSuccessBanner}><p>{otpsuccess}</p></Banner>
        : '';

    const errorOtp = errorotp
        ? <TextStyle variation="negative">{errorotp}</TextStyle>
        : '';

    const wrongOtp = otpinvlid
        ? <Banner status="critical" title={otpinvlid} onDismiss={closeErrorBanner}></Banner>
        : '';

    const sectionForm = isLoadingforotpgenerate ? <Loading /> : null;

    const sectionFormforvalidateotp = isLoadingforotpvalidate ? <Loading /> : null;

    const emailotpbody = JSON.stringify({
        email: otpemail.toLowerCase()
    });

    const otpbody = JSON.stringify({
        email: otpemail.toLowerCase(),
        otp: validotp
    });

    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json'
    }

    //handle button click of otp generation
    const handleLoginwithotp = () => {
        if (otpemail === '') {
            setErrorOtpEmail("Please enter email id or phone number");
            return;
        }
        else if ((new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(otpemail.toLowerCase())) === false && (new RegExp(/^[0]?[6789]\d{9}$/).test(otpemail.toLowerCase())) === false) {
            setErrorOtpEmail('Please enter valid email id or phone number');
        }
        else {
            setIsLoadingforotpgenerate(true);
            setErrorOtpEmail('');
            axios.post(baseUrl.baseUrl + '/patientapp/email-otp-generation/',
                emailotpbody, { headers })
                .then(result => {
                    setIsLoadingforotpgenerate(false);
                    if (result) {
                        setOtpError("");
                        setOtpSuccess(result.data.message);
                        setIsDisableOTPField(false);
                        setButtonText("Resend OTP");
                    }
                    else {
                        history.push("/signin");
                    }
                })
                .catch((error) => {
                    setOtpError(error.response.data.message);
                    setIsLoadingforotpgenerate(false);
                    setButtonText("Send OTP");
                })
        }
    }

    // otp validation
    const handleLoginwithValidotp = () => {
        if (validotp === '') {
            setOtp("Please enter valid Otp");
            return;
        }
        else {
            setIsLoadingforotpvalidate(true);
            axios.post(baseUrl.baseUrl + '/patientapp/email-otp-auth/',
                otpbody, { headers })
                .then(result => {
                    setIsLoadingforotpvalidate(false);
                    if (result) {
                        setOtpError("");
                        localStorage.setItem("token", result.data.token);
                        localStorage.setItem("email", result.data.user.email);
                        localStorage.setItem("firstname", result.data.user.first_name);
                        localStorage.setItem("lastname", result.data.user.last_name);
                        history.push("/dashboard");
                        window.location.reload();
                    }
                    else {
                        history.push("/signin");
                        console.log("====error===");
                    }
                })
                .catch((error) => {
                    setOtpError("");
                    setOtpInvalid(error.response.data.message || error.response.data.non_field_errors[0]);
                    setIsLoadingforotpvalidate(false);
                })
        }
    }
    const otpText = buttonText;

    const actualPageMarkup = (
        <FormLayout>
            {sectionForm}
            {sectionFormforvalidateotp}
            <TextField
                value={otpemail}
                label="Email or Phone Number"
                placeholder="Email or Phone Number"
                id="emailFieldID"
                onChange={handleEmailChange}
            />
            <InlineError message={errorOtpEmail} fieldID="otpemailFieldID" />
            <div>{OTPErrorDiv}</div>
            <div>
                <Button fullWidth primary onClick={handleLoginwithotp}>{otpText}</Button>
            </div>
            <div>{OTPText}</div>

            {/*............ for validate otp code .............. */}
            <TextField
                value={validotp}
                label="OTP"
                placeholder="Enter OTP"
                id="OTPFieldID"
                onChange={handleValidOtpChange}
                disabled={isDisableOTPField}
            />
            <InlineError message={errorOtp} fieldID="OTPFieldID" />
            <div>
                <Button fullWidth primary onClick={handleLoginwithValidotp} disabled={isDisableOTPField}>Log In</Button>
            </div>
            <div>{wrongOtp}</div>
        </FormLayout>
    );
    return (
        <div>
            {actualPageMarkup}
        </div>
    );
}