import React, { useState, useEffect } from 'react';
import {
    Page,
    Loading,
    TextStyle,
    Caption,
    Stack,
    Form,
    Button,
    Modal,
    Toast,
} from '@shopify/polaris';
import { SelectPicker } from 'rsuite';
import { useCallback } from 'react';
import axios from 'axios';
import baseUrl from '../../Url';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles, Theme, createStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { InputLabel } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { Box } from '@primer/components';
import Grid from "@material-ui/core/Grid";
import { CardHeader, Avatar } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import { useHistory } from 'react-router-dom';

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#DE3618',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#BF0711',
        },
    },
    typography: {
        fontSize: 22,
    }
});
const useOutlinedInputStyles = makeStyles(theme => ({
    root: {
        "& $notchedOutline": {
            borderColor: "#50B83C",
            outlineWidth: 0,
        },
        "&:hover $notchedOutline": {
            borderColor: "#50B83C",
            outlineWidth: 0,
        },
        "&$focused $notchedOutline": {
            borderColor: "#50B83C",
            outlineWidth: 0,
        },
        height: "38px",
        background: "white"
    },
    input: {
        "&::placeholder": {
            color: "#50B83C"
        },
        color: "#00000", // if you also want to change the color of the input, this is the prop you'd use   
    },
    focused: {},
    notchedOutline: {}
}));
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        content: {
            flex: '1 0 auto',
        },
        media: {
            height: 0,
            paddingTop: '65.25%', // 16:9
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        avatar: {
            backgroundColor: "#DE3618",
        },
    }),
);

export default function FindDoctor() {
    const history = useHistory();
    const theme = createMuiTheme({
        palette: {
            primary: {
                main: "#50B83C",
            },
            secondary: {
                main: '#330101',
            },
            text: {
                primary: "#000000",
                secondary: "#50B83C"
            }
        },
        typography: {
            fontSize: 22,
        },
        overrides: {
            MuiInputBase: {
                input: {
                    '&:-webkit-autofill': {
                        transitionDelay: '9999s',
                        transitionProperty: 'background-color, color',
                    },
                },
            },
        },

    });
    const classes = useStyles();
    const outlinedInputClasses = useOutlinedInputStyles();
    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;

    interface State {
        doctorName: string;
    }
    const [doctorName, setDoctorName] = React.useState<State>({
        doctorName: '',
    });

    const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setDoctorName({ ...doctorName, [prop]: event.target.value });
    };

    const clearDoctorName = () => {
        setDoctorName({ ...doctorName, doctorName: '' });
    };
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const [specialistValues, setSpecialistValues] = useState([]);
    const [categoryValues, setCategoryValues] = useState([]);


    const [selectedCategory, setSelectedCategory] = useState('');
    const handleSelectedCategoryChange = useCallback((value) => {
        setSelectedCategory(value);
    }, []);


    const [selectedSpeciality, setSelectedSpeciality] = useState('');
    const handleSpecialityChange = useCallback((value) => {
        setSelectedSpeciality(value);
    }, []);

    const [doctorListValue, setDoctorListValue] = useState<any[]>([]);

    const [bookAppointmentModalActive, setBookAppointmentModalActive] = useState(false);
    const handleBookAppointmentToggle = (doctorId: any) => {
        localStorage.setItem("docotorId", doctorId);
        setBookAppointmentModalActive((bookAppointmentModalActive) => !bookAppointmentModalActive);
        getPatientList();
    }
    const closehandleBookAppointmentToggle = () => {
        setBookAppointmentModalActive((bookAppointmentModalActive) => !bookAppointmentModalActive);
    }
    const [patientCaseListValue, setPatientCaseListValue] = useState<any[]>([]);
    const [selectedPatient, setSelectedPatient] = useState('');
    const handleSelectedPatientChange = useCallback((value) => {
        setSelectedPatient(value);
    }, []);

    const [caseListDisable, setCaseListDisable] = useState(true);
    const [patientListValue, setPatientListValue] = useState<any[]>([]);
    const [selectedPatientcase, setSelectedPatientCase] = useState('');
    const handleSelectedPatientCaseChange = useCallback((value) => {
        setSelectedPatientCase(value);
    }, []);

    const [categorySearchErrorActive, setCategorySearchErrorActive] = useState(false);
    const [categorySearchErrorMessage, setCategorySearchErrorMessage] = useState('');
    const toggleCategorySearchErrorActive = useCallback(() => setCategorySearchErrorActive((categorySearchErrorActive) => !categorySearchErrorActive), []);
    const categorySearchToastMarkup = categorySearchErrorActive ? (
        <Toast content={categorySearchErrorMessage} error onDismiss={toggleCategorySearchErrorActive} />
    ) : null;

    const [specialitySearchErrorActive, setSpecialitySearchErrorActive] = useState(false);
    const [specialitySearchErrorMessage, setSpecialitySearchErrorMessage] = useState('');
    const toggleSpecialitySearchErrorActive = useCallback(() => setSpecialitySearchErrorActive((specialitySearchErrorActive) => !specialitySearchErrorActive), []);
    const specialitySearchToastMarkup = specialitySearchErrorActive ? (
        <Toast content={specialitySearchErrorMessage} error onDismiss={toggleSpecialitySearchErrorActive} />
    ) : null;

    const [drNameSearchErrorActive, setDrNameSearchErrorActive] = useState(false);
    const [drNameSearchErrorMessage, setDrNameSearchErrorMessage] = useState('');
    const toggleDrNameSearchErrorActive = useCallback(() => setDrNameSearchErrorActive((drNameSearchErrorActive) => !drNameSearchErrorActive), []);
    const drNameSearchToastMarkup = drNameSearchErrorActive ? (
        <Toast content={drNameSearchErrorMessage} error onDismiss={toggleDrNameSearchErrorActive} />
    ) : null;

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };
    // Speciality List dropdown.........
    const getCategoryList = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/secondopinionapp/api/doctorCategory/doctor_category_list', { headers })
            .then((res) => {
                setIsLoading(false);
                setCategoryValues(res.data.doctorCategoryList);

            })
            .catch((error) => {
                setIsLoading(false);
                console.log("error record====", error);
            })
    }

    // Speciality List dropdown.........
    const getSpecialityList = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/doctorapp/api/onboarding/speciality_list', { headers })
            .then((res) => {
                setIsLoading(false);
                setSpecialistValues(res.data.doctorSpecialtyList);

            })
            .catch((error) => {
                setIsLoading(false);
                console.log("error record====", error);
            })
    }
    // Search API call on select Category
    const onSelectCategory = (selectedCategory: any) => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/search-doctor/',
            { "doctorCategoryId": selectedCategory, "speciality": selectedSpeciality, "searchKey": doctorName.doctorName },
            { headers })
            .then((res) => {
                setIsLoading(false);
                setDoctorListValue(res.data.doctorsList);
            })
            .catch((error) => {
                setIsLoading(false);
                setCategorySearchErrorMessage("No doctor found as per selected category");
                toggleCategorySearchErrorActive();
            })
    }

    // Search API call on select Speciality
    const onSelectSpeciality = (selectedSpeciality: any) => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/search-doctor/',
            { "doctorCategoryId": selectedCategory, "speciality": selectedSpeciality, "searchKey": doctorName.doctorName },
            { headers })
            .then((res) => {
                setIsLoading(false);
                setDoctorListValue(res.data.doctorsList);
            })
            .catch((error) => {
                setIsLoading(false);
                setSpecialitySearchErrorMessage("No doctor found as per selected speciality");
                toggleSpecialitySearchErrorActive();
            })
    }

    const searchBody = {
        "searchKey": doctorName.doctorName,
        "speciality": selectedSpeciality,
        "doctorCategoryId": selectedCategory
    }
    const searchDoctor = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/search-doctor/',
            searchBody, { headers })
            .then((res) => {
                setIsLoading(false);
                setDoctorListValue(res.data.doctorsList);
            })
            .catch((error) => {
                setIsLoading(false);
                setDrNameSearchErrorMessage(error.response.data.message);
                toggleDrNameSearchErrorActive();
            })
    };

    // Speciality List dropdown.........
    const getPatientList = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/patientapp/api/patient/patient_list', { headers })
            .then((res) => {
                setIsLoading(false);
                let values = res.data.patientList;
                let patientList: Array<{ label: string, value: string, }> = [];
                values.forEach((element: { firstname: any; lastname: any; id: any; }) => {
                    patientList.push({
                        label: element.firstname + " " + element.lastname,
                        value: element.id
                    });
                });
                setPatientListValue(patientList);

            })
            .catch((error) => {
                setIsLoading(false);
                console.log("error record====", error);
            })
    }
    // Search API call on select Category
    const onSelectPatient = (selectedPatient: any) => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patient/patient_case_list', { "patientId": selectedPatient }, { headers })
            .then((res) => {
                setIsLoading(false);
                let values = res.data.patientCaseList;
                let patientCaseList: Array<{ label: string, value: string, }> = [];
                values.forEach((element: { whyWant2ndOpinion: any; caseId: any; }) => {
                    patientCaseList.push({
                        label: element.whyWant2ndOpinion,
                        value: element.caseId
                    });
                });
                setPatientCaseListValue(patientCaseList);
                setCaseListDisable(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setCategorySearchErrorMessage("No doctor found as per selected category");
                toggleCategorySearchErrorActive();
            })
    }

    const gotoCaseDetails = () => {
        const paymentRedirect = Number(2);
        localStorage.setItem("paymentRedirect", `${paymentRedirect}`);
        history.push(`/filepreparation/${selectedPatient}/${selectedPatientcase}/${paymentRedirect}`);
        // localStorage.setItem("paymentRedirect", `${paymentRedirect}`);
        // history.push(`/filepreparation/${selectedPatient}/${selectedPatientcase}`)
    }
    useEffect(() => {
        getCategoryList();
        getSpecialityList();
    }, []);

    const actualPageMarkup = (
        <div>
            <Stack distribution="fillEvenly">
                <Stack.Item>
                    <div style={{ marginBottom: "5px" }}>Category</div>
                    <SelectPicker
                        block
                        onChange={handleSelectedCategoryChange}
                        onSelect={onSelectCategory}
                        data={categoryValues}
                        value={selectedCategory}
                        placeholder="Select category"
                    />
                </Stack.Item>
                <Stack.Item >
                    <div style={{ marginBottom: "5px" }}>Speciality</div>
                    <SelectPicker
                        block
                        onChange={handleSpecialityChange}
                        onSelect={onSelectSpeciality}
                        data={specialistValues}
                        value={selectedSpeciality}
                        placeholder="Select speciality"
                    />
                </Stack.Item>
                <Stack.Item>
                    <div style={{ marginBottom: "5px" }}>Select by name</div>
                    <ThemeProvider theme={theme}>
                        <Form onSubmit={searchDoctor}>
                            <InputLabel htmlFor="outlined-adornment-doctorName"></InputLabel>
                            <OutlinedInput
                                id="doctorNameID"
                                placeholder="Search doctor"
                                value={doctorName.doctorName}
                                onChange={handleChange('doctorName')}
                                fullWidth
                                classes={outlinedInputClasses}
                                endAdornment={
                                    <InputAdornment position="end">
                                        {doctorName.doctorName === "" ? "" :
                                            <IconButton
                                                aria-label="toggle clear doctor name"
                                                onClick={clearDoctorName}
                                                edge="end"
                                            >
                                                <ClearIcon />
                                            </IconButton>}
                                        <IconButton
                                            aria-label="search doctor by name"
                                            onClick={searchDoctor}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={0}
                            />
                        </Form>
                    </ThemeProvider>
                </Stack.Item>
            </Stack>
            <Box m={3}>
                <ThemeProvider theme={defaultMaterialTheme}>
                    <Grid container spacing={1}>
                        <Grid container item sm={12} spacing={3}>
                            <React.Fragment>
                                {doctorListValue.map(doctorItem => (
                                    <Grid item md={4}>
                                        <Card className={classes.root}>
                                            <div style={{ height: '480px' }}>
                                                <div style={{ height: '105px' }}>
                                                    <CardHeader
                                                        avatar={
                                                            <Avatar aria-label="recipe" className={classes.avatar}>
                                                                <LocalHospitalIcon />
                                                            </Avatar>
                                                        }
                                                        title={<h5>{doctorItem.fullName}</h5>}
                                                        subheader={<small>{doctorItem.qualification}</small>}
                                                    />
                                                </div>
                                                <Grid container
                                                    alignItems='center'
                                                    justify='center'
                                                >
                                                    <div style={{ height: '300px', width: '300px' }}>
                                                        <img
                                                            alt=""
                                                            width="100%"
                                                            height="100%"
                                                            src={doctorItem.image?.document}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Box alignItems="center" my={1} display={'flex'} className={classes.content}>
                                                    <Box justifyContent="center" p={2} flex={'auto'}>
                                                        <Caption>Specialty</Caption>
                                                        <TextStyle variation="strong">{doctorItem.specialtyWithYearsOfExperience}</TextStyle>
                                                    </Box>
                                                </Box>
                                            </div>
                                            <Box p={2}>
                                                <Stack>
                                                    <Stack.Item fill>
                                                        &nbsp;
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        <Button size="slim" primary onClick={() => handleBookAppointmentToggle(doctorItem.id!)}>
                                                            Book Appointment
                                                        </Button>
                                                    </Stack.Item>
                                                </Stack>
                                            </Box>
                                        </Card>
                                    </Grid>
                                ))}
                            </React.Fragment>
                        </Grid>
                    </Grid>
                </ThemeProvider>
            </Box>
        </div>
    )
    const appointmentModalBody = (
        <Modal
            large
            open={bookAppointmentModalActive}
            onClose={closehandleBookAppointmentToggle}
            title="Book Appointment"
            primaryAction={{
                content: 'Submit',
                onAction: gotoCaseDetails,
            }}
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: closehandleBookAppointmentToggle,
                },
            ]}
        >
            <Modal.Section>
                <Stack>
                    <Stack.Item fill>
                        <div>
                            <div style={{ marginBottom: "5px" }}>Select Patient</div>
                            <SelectPicker
                                block
                                onChange={handleSelectedPatientChange}
                                onSelect={onSelectPatient}
                                data={patientListValue}
                                value={selectedPatient}
                                placeholder="Select patient"
                                menuStyle={{ zIndex: 1000, color: "rgb(76, 2, 2) !important" }}
                            />
                        </div>
                    </Stack.Item>
                </Stack>
                <Stack>
                    <Stack.Item fill>
                        <div>
                            <div style={{ marginBottom: "5px" }}>Select Patient's Case</div>
                            <SelectPicker
                                block
                                onChange={handleSelectedPatientCaseChange}
                                data={patientCaseListValue}
                                value={selectedPatientcase}
                                disabled={caseListDisable}
                                placeholder="Choose selected patient's case"
                                menuStyle={{ zIndex: 1000, color: "rgb(76, 2, 2) !important" }}
                            />
                        </div>
                    </Stack.Item>
                </Stack>
            </Modal.Section>
        </Modal>
    )
    return (
        <Page fullWidth>
            {loadingMarkup}
            {actualPageMarkup}
            {appointmentModalBody}
            {categorySearchToastMarkup}
            {specialitySearchToastMarkup}
            {drNameSearchToastMarkup}
        </Page>
    )
}