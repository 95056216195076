import React, { useState, useCallback, useEffect } from 'react';
import {
    Loading,
    Layout,
    Banner,
    Button,
    Stack,
    Card,
    RadioButton,
    FormLayout,
    Scrollable,
    ExceptionList,
    DataTable,
    Modal,
    TextContainer,
    Subheading,
    Toast
} from '@shopify/polaris';
import { NoteMinor, DeleteMinor, RefreshMinor } from '@shopify/polaris-icons';
import { Box } from '@primer/components';
import { initializeIcons } from '@uifabric/icons'
import { SelectPicker, } from 'rsuite';
import axios from 'axios';
import baseUrl from '../../Url';
import { Redirect, useParams } from 'react-router-dom';
initializeIcons();

interface AccountProps {
    onAction(): void;
}

export default function AppointmentSelectDoctor(props: any) {
    const [toSignin, setToSignin] = useState(false);

    // const { patientid } = useParams() as {
    //     patientid: string;
    // };

    const { caseid } = useParams() as {
        caseid: string;
    };
    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;
    const [doctorName, setDoctorName] = useState("");
    const [selectedDoctorId, setSelectedDoctorId] = useState("");
    const [doctorList, setDoctorList] = useState<any[]>([]);
    const { doctorIdsend } = props;

    const [doctorTeamTypeList, setoctorTeamTypeList] = useState<any[]>([]);
    const [value, setValue] = useState();
    const [getdoctorValue, setdoctorValue] = useState();
    const handleDoctorTeamChange = useCallback(
        (_checked, newValue) => setValue(newValue),
        [],
    );

    const [isEdit, setIsEdit] = useState(true);
    const [isEnableSubmit, setEnableSubmit] = useState(true);

    const closeDoctortypeSuccess = () => {
        setDoctorTypeSuccess('');
    }
    const [doctorTypeSuccess, setDoctorTypeSuccess] = useState('');
    const successDiv = doctorTypeSuccess
        ? <Banner
            onDismiss={closeDoctortypeSuccess}
            status="success">
            <p>{doctorTypeSuccess}</p>
        </Banner>
        : '';

    const closeDoctortypeError = () => {
        setDoctorTypeError('');
    }
    const [doctorTypeError, setDoctorTypeError] = useState('');
    const errorDoctorTypeDiv = doctorTypeError
        ? <Banner
            onDismiss={closeDoctortypeError}
            status="critical">
            <p>{doctorTypeError}</p>
        </Banner>
        : '';

    const [doctorTeamTypeErrorMessage, setDoctorTeamTypeErrorMessage] = useState("");
    const [doctorTeamTypeErrorActive, setDoctorTeamTypeErrorActive] = useState(false);
    const doctorTeamTypeErrorToggleActive = useCallback(() => setDoctorTeamTypeErrorActive((doctorTeamTypeErrorActive) => !doctorTeamTypeErrorActive), []);

    const doctorTeamTypeErrorMarkup = doctorTeamTypeErrorActive ? (
        <Toast content={doctorTeamTypeErrorMessage} error onDismiss={doctorTeamTypeErrorToggleActive} />
    ) : null;
    const closeDoctorAddSuccess = () => {
        setDoctorAddSuccess('');
        setDoctorAddError('');
    }
    const [doctoraddSuccess, setDoctorAddSuccess] = useState('');
    const adddoctorsuccessDiv = doctoraddSuccess
        ? <Banner
            onDismiss={closeDoctorAddSuccess}
            status="success">
            <p>{doctoraddSuccess}</p>
        </Banner>
        : '';

    const [doctoraddError, setDoctorAddError] = useState('');
    const adddoctorsuccessError = doctoraddError
        ? <Banner
            onDismiss={closeDoctorAddSuccess}
            status="critical">
            <p>{doctoraddError}</p>
        </Banner>
        : '';
    const closeDoctorDeleteSuccess = () => {
        setDoctorDeleteSuccess('');
    }
    const [doctorDeleteSuccess, setDoctorDeleteSuccess] = useState('');
    const deleteDoctorsuccessDiv = doctorDeleteSuccess
        ? <Banner
            onDismiss={closeDoctorDeleteSuccess}
            status="success">
            <p>{doctorDeleteSuccess}</p>
        </Banner>
        : '';
    const closeDoctorDeleteError = () => {
        setDoctorDeleteError('');
    }

    const [doctorDeleteError, setDoctorDeleteError] = useState('');
    const deleteDoctorErrorDiv = doctorDeleteError
        ? <Banner
            onDismiss={closeDoctorDeleteError}
            status="critical">
            <p>{doctorDeleteError}</p>
        </Banner>
        : '';

    const [selectedDocName, setSelectedDocName] = useState('');
    const handleDocNameChange = useCallback((value) => {
        setSelectedDocName(value);
        //doctorIdsend(value)
    }, []);

    const [selectedDoctorListValue, setSelectedDoctorListValue] = useState<any[]>([]);
    console.log("DoctorList==>", selectedDoctorListValue);


    const [modalDeleteDoctorActive, setModalDeleteDoctor] = useState(false);
    const handleDeleteDoctorToggle = () => {
        setModalDeleteDoctor((modalDeleteDoctorActive) => !modalDeleteDoctorActive);
        setDoctorTypeSuccess("");
        setDoctorTypeError("");
        setDoctorAddSuccess("");
        setDoctorAddError('');
        setDoctorDeleteSuccess('');
        setDoctorDeleteError('');
    }

    function deleteDoctor(doctorId: any, doctorName: any) {
        setModalDeleteDoctor((modalDeleteDoctorActive) => !modalDeleteDoctorActive);
        setDoctorName(doctorName);
        setSelectedDoctorId(doctorId);
        setDoctorTypeSuccess("");
        setDoctorTypeError("");
        setDoctorAddSuccess("");
        setDoctorAddError('');
        setDoctorDeleteSuccess('');
        setDoctorDeleteError('');
    }

    // Select category
    const [doctorListValue, setDoctorListValue] = useState<any[]>([]);
    const [categoryValues, setCategoryValues] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const handleSelectedCategoryChange = useCallback((value) => {
        setSelectedCategory(value);
        if (selectedCategory === '' || selectedCategory === null) {
            console.log("CategoryChange Null check");
            setIsDoctorlistDisable(true);
            setDoctorList([]);
        }
    }, []);

    const [categorySearchErrorActive, setCategorySearchErrorActive] = useState(false);
    const [categorySearchErrorMessage, setCategorySearchErrorMessage] = useState('');
    const toggleCategorySearchErrorActive = useCallback(() => setCategorySearchErrorActive((categorySearchErrorActive) => !categorySearchErrorActive), []);
    const categorySearchToastMarkup = categorySearchErrorActive ? (
        <Toast content={categorySearchErrorMessage} error onDismiss={toggleCategorySearchErrorActive} />
    ) : null;

    const [isDoctorlistDisable, setIsDoctorlistDisable] = useState(true);


    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    // get Doctor Team Type List
    const getDocTeamtype = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/doctor_team_typeList', {
            "caseId": caseid
        }, { headers })
            .then((res) => {
                let doctorTeamTypeList: Array<{ value: number, label: string }> = [];
                if (res.data.doctorTeamTypeList.length > 0) {
                    let values = res.data.doctorTeamTypeList;
                    setIsLoading(false);
                    values.forEach((element: { value: any, label: any }) => {
                        doctorTeamTypeList.push({
                            value: element.value,
                            label: element.label
                        });
                    });
                }
                setoctorTeamTypeList(doctorTeamTypeList);
                setIsLoading(false);
            }).catch((error) => {
                if (error.response.statues === -1) {
                    setIsLoading(false);
                    setDoctorTeamTypeErrorMessage(error.response.data.message);
                    doctorTeamTypeErrorToggleActive();
                }
                else if (error.response.status === -2) {
                    setIsLoading(false);
                    setDoctorTeamTypeErrorMessage(error.response.data.message);
                    doctorTeamTypeErrorToggleActive();
                }
                else {
                    setIsLoading(false);
                    setDoctorTeamTypeErrorMessage(error.response.data.message);
                    doctorTeamTypeErrorToggleActive();
                }
            })
    }

    // Doctor Team Type Edit Body
    const doctorTypeEditBody = {
        "caseId": caseid,
        "doctorTeamTypeId": String(value)
    }

    // doctor type submit
    const doctorTypeSubmit = () => {
        console.log(doctorTypeEditBody);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patient/edit_patient_case',
            doctorTypeEditBody, { headers })
            .then(result => {
                setIsLoading(false);
                if (result) {
                    setDoctorTypeError('');
                    setDoctorTypeSuccess(result.data.message);
                    getDocSelectedTeamtype();
                    setIsEdit(true);
                    setEnableSubmit(true);
                }
            })
            .catch((error) => {
                console.log(error.response);
                setDoctorTypeSuccess('');
                setDoctorTypeError(error.response.data.message);
                setIsLoading(false);
            })
    }

    // get Doctor Team Type Selected value
    const getDocSelectedTeamtype = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/doctorTeamType_for_patientCase', {
            "caseId": caseid
        }, { headers }).then((res) => {
            setValue(res.data.caseDoctorTeamTypelist[0].value);
            setdoctorValue(res.data.caseDoctorTeamTypelist[0].value);
            setIsLoading(false);
        }).catch((error) => {
            console.log(error);
            setIsLoading(false);
        })
    };

    // get Doctor List
    // const getDocList = () => {
    //     setIsLoading(true);
    //     axios.post(baseUrl.baseUrl + '/patientapp/api/doctor/active_doctor_list_for_dropdown', {
    //         "caseId": caseid
    //     }, { headers }).then((res) => {
    //         setIsLoading(false);
    //         let values = res.data.doctorsList;
    //         setDoctorList(values);
    //     }).catch((error) => {
    //         console.log(error);
    //         setIsLoading(false);
    //     })
    // };


    // Doctor Add api body
    const doctorAddBody = {
        "caseId": caseid,
        "doctorId": selectedDocName,
        "doctorCategoryId": selectedCategory
    };

    // Add Doctor in Patient case API
    const handleAddDoctor = () => {
        console.log("DocAddBody", doctorAddBody);
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/add_doctorTo_patientCase',
            doctorAddBody, { headers })
            .then((res) => {
                setIsLoading(false);
                setDoctorAddSuccess(res.data.message);
                getSelectedDoc();
            })
            .catch((error) => {
                console.log("===>", error.response.data.message);
                setIsLoading(false);
                setDoctorAddError(error.response.data.message);
            })

    }

    // Delete Doctor Body
    const doctorDeleteBody = {
        "caseId": caseid,
        "doctorId": selectedDoctorId
    }

    // Get Selected Doctor API
    const getSelectedDoc = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/doctorList_for_patientCase',
            { "caseId": caseid }, { headers }).then((res) => {
                setIsLoading(false);
                let doctorList = res.data.patientCaseDoctorlist;
                let doctorListConfig = [];
                for (var i = 0; i < doctorList.length; i++) {
                    let doctorId = doctorList[i].id;
                    let doctorName = ((doctorList[i].firstname === "") || (doctorList[i].firstname === null) && (doctorList[i].lastname === "") || (doctorList[i].lastname === null)) ? ' ' : doctorList[i].firstname + " " + doctorList[i].lastname;
                    let qualification = (doctorList[i].qualification === "") || (doctorList[i].qualification === null) ? ' ' : doctorList[i].qualification;
                    let speciality = (doctorList[i].speciality === "") || (doctorList[i].speciality === null) ? ' ' : doctorList[i].speciality;
                    let doctorBookStatus = (doctorList[i].requestStatus === "") || (doctorList[i].requestStatus === null) ? ' ' : doctorList[i].requestStatus;
                    doctorListConfig.push([
                        doctorName,
                        qualification,
                        speciality,
                        doctorBookStatus,
                        <Button icon={DeleteMinor} size="slim" onClick={() => deleteDoctor(doctorId,
                            doctorName)}>
                        </Button>
                    ]);
                }
                setSelectedDoctorListValue(doctorListConfig);
            })
            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
                console.log(toSignin);
            });
    };

    // doctor Delete submit
    const deleteDoctorAPI = () => {
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/delete_selected_doctor',
            doctorDeleteBody, { headers })
            .then(result => {
                setIsLoading(false);
                if (result) {
                    setDoctorDeleteSuccess(result.data.message);
                    handleDeleteDoctorToggle();
                    getSelectedDoc();
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setDoctorTypeSuccess('');
                setDoctorTypeError('');
                setDoctorAddSuccess('');
                setDoctorAddError('');
                setSelectedDocName('');
                setDoctorDeleteSuccess('');
                setDoctorDeleteError(error.response.data.message);
            })
    }

    // Speciality List dropdown.........
    const getCategoryList = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/secondopinionapp/api/doctorCategory/doctor_category_list', { headers })
            .then((res) => {
                setIsLoading(false);
                setCategoryValues(res.data.doctorCategoryList);

            })
            .catch((error) => {
                setIsLoading(false);
                console.log("error record====", error);
            })
    }

    const selectDocategory = (selectedCategory: any) => {
        if (selectedCategory === '' || selectedCategory === null) {
            console.log("if")
            setIsDoctorlistDisable(true);
            setDoctorList([]);
        }
        else {
            console.log("Else");
            setIsLoading(true);
            axios.post(baseUrl.baseUrl + '/patientapp/api/search-doctor/',
                { "doctorCategoryId": String(selectedCategory) },
                { headers })
                .then((res) => {
                    setIsDoctorlistDisable(false);
                    setIsLoading(false);
                    setDoctorList(res.data.doctorsList);
                })
                .catch((error) => {
                    setIsLoading(false);
                    setCategorySearchErrorMessage("No doctor found as per selected category");
                    toggleCategorySearchErrorActive();
                })
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getDocTeamtype();
        getDocSelectedTeamtype();
        getCategoryList();
        // getDocList();
        getSelectedDoc();
        if (localStorage.getItem("docotorId")) {
            axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/add_doctorTo_patientCase',
                { "caseId": caseid, "doctorId": localStorage.getItem("docotorId") }, { headers })
                .then((res) => {
                    setIsLoading(false);
                    setDoctorAddSuccess(res.data.message);
                    getSelectedDoc();
                    localStorage.removeItem("docotorId")
                })
                .catch((error) => {
                    console.log("===>", error.response.data.message);
                    setIsLoading(false);
                    setDoctorAddError(error.response.data.message);
                    localStorage.removeItem("docotorId")
                })
        }
        else {
            console.log("DO NOT CALL ADD DOCTOR FROM FIND DOCTOR PAGE");
        }

    }, []);

    const doctorAddInfo = (
        <div>
            {loadingMarkup}
            <Layout>
                <Layout.Section>
                    {getdoctorValue ? (
                        <Card title="Doctor Team Type" sectioned secondaryFooterActions={[{
                            content: isEdit ? 'Edit' : 'Cancel', onAction: () => {
                                setIsEdit((isEdit) => !isEdit);
                                setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
                            }
                        }]}
                            primaryFooterAction={{ content: 'Update', onAction: doctorTypeSubmit, disabled: isEnableSubmit }}
                        >
                            <FormLayout>
                                {doctorTeamTypeList.map((doctorTeam) => (
                                    <RadioButton
                                        label={<Box><h5>{doctorTeam.label}</h5></Box>}
                                        id={doctorTeam.value}
                                        name="category"
                                        checked={value === doctorTeam.value}
                                        onChange={handleDoctorTeamChange}
                                        disabled={isEdit}
                                    />

                                ))}
                            </FormLayout>
                            <Box p={1}>{successDiv}</Box>
                            <Box p={1}>{errorDoctorTypeDiv}</Box>
                        </Card>
                    ) : (
                        <Card title="Doctor Team Type" sectioned primaryFooterAction={{ content: 'Select Doctor Team', onAction: doctorTypeSubmit }}
                        >
                            <FormLayout>
                                {doctorTeamTypeList.map((doctorTeam) => (
                                    <RadioButton
                                        label={<Box><h5>{doctorTeam.label}</h5></Box>}
                                        id={doctorTeam.value}
                                        name="category"
                                        checked={value === doctorTeam.value}
                                        onChange={handleDoctorTeamChange}
                                    />

                                ))}
                            </FormLayout>
                            <Box p={1}>{successDiv}</Box>
                            <Box p={1}>{errorDoctorTypeDiv}</Box>
                        </Card>
                    )}
                </Layout.Section>
                <Layout.Section>
                    <Card>
                        <Card.Header title="Select Doctor">

                        </Card.Header>
                        <Box p={5}>
                            <Stack>
                                <Stack.Item>
                                    <div style={{ marginBottom: "5px" }}>Category</div>
                                    <SelectPicker
                                        block
                                        onChange={handleSelectedCategoryChange}
                                        data={categoryValues}
                                        value={selectedCategory}
                                        onSelect={selectDocategory}
                                        placeholder="Select category"
                                    />
                                </Stack.Item>
                                <Stack.Item fill>
                                    <div style={{ marginBottom: "5px" }}>Select Doctor</div>
                                    <SelectPicker
                                        block
                                        onChange={handleDocNameChange}
                                        data={doctorList}
                                        value={selectedDocName}
                                        disabled={isDoctorlistDisable}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <Box pt={4}>
                                        <Button primary={false} disabled={isDoctorlistDisable} outline onClick={handleAddDoctor}>Add</Button>
                                    </Box>
                                </Stack.Item>
                            </Stack>
                            <Box p={1}>{adddoctorsuccessDiv}</Box>
                            <Box p={1}>{adddoctorsuccessError}</Box>
                        </Box>
                    </Card>
                </Layout.Section>
                <Layout.Section>
                    <Card>
                        <Card.Header title="Selected Doctor List">
                            <Button icon={RefreshMinor} primary={false} onClick={getSelectedDoc}>Reload Doctor List</Button>
                        </Card.Header>
                        <Card.Section>
                            <Scrollable shadow style={{ height: '200px' }} focusable horizontal={false}>
                                {selectedDoctorListValue && selectedDoctorListValue.length ? (
                                    <DataTable
                                        columnContentTypes={[
                                            'text',
                                            'text',
                                            'text',
                                            'text',
                                            'text'
                                        ]}
                                        headings={[
                                            'Dr. Name',
                                            'Qualification',
                                            'Speciality',
                                            'Appoinment Status',
                                            'Delete',
                                        ]}
                                        rows={selectedDoctorListValue}
                                    />
                                ) : (
                                    <ExceptionList
                                        items={[
                                            {
                                                icon: NoteMinor,
                                                description: 'No Doctor Details is available!',
                                            },
                                        ]}
                                    />)}
                            </Scrollable>
                        </Card.Section>
                    </Card>
                </Layout.Section>
            </Layout>
        </div >
    )
    const deleteDoctorModal = (
        <Modal
            large
            open={modalDeleteDoctorActive}
            onClose={handleDeleteDoctorToggle}
            title="Delete Doctor"
            primaryAction={{
                content: "Yes",
                onAction: deleteDoctorAPI,
            }}
            secondaryActions={[
                {
                    content: 'No',
                    onAction: handleDeleteDoctorToggle,
                },
            ]}
        >
            <Modal.Section>
                <TextContainer>
                    <Subheading>
                        Are you sure, you want to delete this "{doctorName}" from your doctor team ?
                    </Subheading>
                    <div><Box p={1}>{deleteDoctorsuccessDiv}</Box></div>
                    <div><Box p={1}>{deleteDoctorErrorDiv}</Box></div>
                </TextContainer>
            </Modal.Section>
        </Modal>
    )
    return (
        <div>
            {toSignin ? <Redirect to="/signin" /> : null}
            {doctorAddInfo}
            {deleteDoctorModal}
            {doctorTeamTypeErrorMarkup}
        </div>
    );
}