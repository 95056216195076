import React, { useState, useEffect, useCallback } from 'react';
import {
    Loading,
    Card,
    Button,
    Toast,
    DataTable,
    ExceptionList,
    Page,
} from '@shopify/polaris';
import { NoteMinor, RefreshMinor } from '@shopify/polaris-icons';
import {  useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { appoinmentRequestListForOwner } from '../../redux/effects/ApiCall';
import { AppState } from '../../redux/store/Store';
export default function AppointmentBooking(props: any) {

    const { caseid } = useParams() as {
        caseid: string;
    };
    const loadingMarkup = <Loading />;
    
    const history = useHistory();
    const gotoCaseDetails = (patientid: any, caseid: any ) => {
        history.push(`filepreparation/${patientid}/${caseid}`);
    }

    const dispatch = useDispatch();

    function getAppoinmentRequestList() {
        dispatch(appoinmentRequestListForOwner(selectionTypeBody));
    }

    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

    const selectionTypeBody = {
        "caseId": caseid
    };
    // appoinment details from redux store....
    const OwnerAppoinmentReduxValue = useSelector((state: AppState) => state.AppoinmentListValue);
    let appoinmentList = OwnerAppoinmentReduxValue.OwnerAppoinmentListValue;
    console.log("store value=======>", OwnerAppoinmentReduxValue);
    let appoinmentValueList: any = [];
    for (var i = 0; i < appoinmentList.length; i++) {
        let caseid = (appoinmentList[i].caseId == null) ? "" : appoinmentList[i].caseId;
        let patientid = (appoinmentList[i].patientId == null) ? "" : appoinmentList[i].patientId;
        let description = (appoinmentList[i].name == "" || appoinmentList[i].name == null) ? "No Details Available" : appoinmentList[i].name;
        let createdDate = (appoinmentList[i].createDate == null) ? "" : (new Date(appoinmentList[i].createDate)).toLocaleDateString('en-US', DATE_OPTIONS);
        let endDate = (appoinmentList[i].end == null) ? "" : (new Date(appoinmentList[i].end)).toLocaleDateString('en-US', DATE_OPTIONS);
        let modifiedDate = (appoinmentList[i].modifiedDate == null) ? "" : (new Date(appoinmentList[i].modifiedDate)).toLocaleDateString('en-US', DATE_OPTIONS);
        let appoinmentStatus = (appoinmentList[i].hasOwnProperty('status')) ? (appoinmentList[i].status === null || appoinmentList[i].status === "") ? "" : appoinmentList[i].status : "";
        appoinmentValueList.push([
            <Button onClick={()=>gotoCaseDetails(patientid,caseid)}>View</Button>,
            description,
            createdDate,
            modifiedDate,
            endDate,
            appoinmentStatus
        ]);
    }
    const ownerAppoinmentList: any[] = appoinmentValueList;
    useEffect(() => {
        // Do api call here....
        dispatch(appoinmentRequestListForOwner(selectionTypeBody));
    }, [dispatch]);


    const appointmentTableBody = (
        <div>
            <Card >
                <Card.Header title="Existing Appointment Details">
                    <Button icon={RefreshMinor} primary={false} onClick={getAppoinmentRequestList}>Reload Appointment List</Button>
                </Card.Header>
                <Card.Section>
                    {ownerAppoinmentList && ownerAppoinmentList.length ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'text',
                                'text',
                                'text',
                                'text',
                            ]}
                            headings={[
                                'View Case Details',
                                'Description',
                                'Create Date',
                                'Modified Date',
                                'End Date',
                                'Status',
                            ]}
                            rows={ownerAppoinmentList}
                        />
                    ) : (
                            <ExceptionList
                                items={[
                                    {
                                        icon: NoteMinor,
                                        description: 'No Appointment Details is available!',
                                    },
                                ]}
                            />)}
                </Card.Section>
            </Card>
            <br />
        </div >
    )

    return (
        <Page
            fullWidth
            title="Appointment Details"
        >
            {appointmentTableBody}
            {OwnerAppoinmentReduxValue.loading ? loadingMarkup : ''}
        </Page>
    );
}
