import { combineReducers } from 'redux';
import { addCCReducer, deleteDocumentReducer, deletePatientReducer, getCaseReducer, getCCReducer, 
    getDoctorReducer, getPatientReducer,getSharedPatientReducer,  getQuestionReducer, getTaskReducer,getOwnerAppoinmentListReducer, getCaseAppoinmentListReducer } from './Reducer';

const Reducer = combineReducers({
    doctorValue: getDoctorReducer, 
    patientValue: getPatientReducer,
    sharedPatientValue: getSharedPatientReducer, 
    caseValue: getCaseReducer, 
    questionValue: getQuestionReducer, 
    ccValue: getCCReducer, 
    ccAddVlaue: addCCReducer,
    deleteDocumentValue: deleteDocumentReducer,
    deletePatientValues: deletePatientReducer,
    taskValue: getTaskReducer,
    AppoinmentListValue:getOwnerAppoinmentListReducer,
    CaseAppoinmentListValue:getCaseAppoinmentListReducer});

export default Reducer;
