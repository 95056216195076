import React, { useEffect, useState, useCallback } from 'react';
import { Button, Card, DataTable, ExceptionList, Loading, Modal, Page, TextContainer } from '@shopify/polaris';
import { NoteMinor } from '@shopify/polaris-icons';
import { Redirect, useParams } from 'react-router-dom';
import axios from 'axios';
import baseUrl from '../../../Url';

export default function ImportedDataList() {

    const [toSignin, setToSignin] = useState(false);

    // const { patientid } = useParams() as {
    //     patientid: string;
    // };
    const { caseid } = useParams() as {
        caseid: string;
    };

    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;
    const [imageInfo, setImageInfo] = useState('');

    const [importedDataListValue, setImportedDataListValue] = useState<any[]>([]);

    const [docViwerActive, setDocViwerActive] = useState(false);
    const handleDocViewerChange = useCallback(() => setDocViwerActive(!docViwerActive), [docViwerActive]);

    const truncate = (str: string,) => {
        return str.length > 0 ? str.substring(0, 60) + "..." : str;
    }

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    const getImportedDataBody = {
        "caseId": caseid,
        "secondOpinionUiSection": "importedData"
    }

    // Documents List
    const getImportedDataList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/get_patientDetailsList',
            getImportedDataBody, { headers })
            .then((res) => {
                setIsLoading(false);
                let resData = res.data.patientDetailList;
                let importedDataConfig = [];
                for (var i = 0; i < resData.length; i++) {
                    let description = (resData[i].description == null) ? "" : truncate(resData[i].description);
                    let dateStart = (resData[i].startDate == null) ? "" : (new Date(resData[i].startDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let dateEnd = (resData[i].endDate == null) ? "" : (new Date(resData[i].endDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let supportingDocuments = (resData[i].patientDocument == null) ? '' : resData[i].patientDocument.document;
                    importedDataConfig.push([
                        <Button icon={NoteMinor} size="large" onClick={() => {
                            setDocViwerActive(true);
                            setImageInfo(supportingDocuments);
                        }}></Button>,
                        description,
                        dateStart,
                        dateEnd,
                    ]);
                }
                setImportedDataListValue(importedDataConfig);
            })
            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }

    useEffect(() => {
        getImportedDataList();
    }, []);

    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const inportedDataTable = (
        <div>
            <Card>
                <Card.Header title="Import Data Details">
                </Card.Header>
                <Card.Section>
                    {importedDataListValue && importedDataListValue.length ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'text',
                                'text'
                            ]}
                            headings={[
                                'Document',
                                'Description',
                                'Start Date',
                                'End Date',
                                'Delete'
                            ]}
                            rows={importedDataListValue}
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Import Data is available!',
                                },
                            ]}
                        />)}
                </Card.Section>
            </Card>
            <br />
        </div >
    )

    const docViewerComponent = (
        <Modal
            open={docViwerActive}
            onClose={handleDocViewerChange}
        >
            <Modal.Section>
                <TextContainer>
                    <p>
                        <iframe src={imageInfo} title='Small document' width='100%' height='700' frameBorder='0'>This is an embedded <a target='_blank' href='http://office.com' rel="noopener noreferrer">Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps' rel="noopener noreferrer">Office Online</a>.</iframe>
                    </p>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );
    return (
        <Page title="Import data" fullWidth
        >
            {toSignin ? <Redirect to="/signin" /> : null}
            {loadingMarkup}
            {inportedDataTable}
            {docViewerComponent}
        </Page>
    );
}