import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import InformConsent from './InformConsent';
import ChiefComplaints from './ChiefComplaints';
import SystemReview from './SystemsReview';
import PersonalHistory from './PersonalHistory';
import Importeddata from './ImportedData';
import IdeasConcernsExpectations from './IdeasConcernsExpectations';
import Allergy from './Allergies';
import FamilyHistory from './FamilyHistory';
import Immunization from './Immunization';
import ProblemList from './ProblemList';
import Diagnosis from './Diagnosis';
import NutritionalHistory from './NutritionalHistory';
import HealthMaintenanceHistory from './HealthMaintenanceHistory';
import RiskFactors from './RiskFactors';
import TestsProceduresAndInvestigations from './TestsProceduresAndInvestigations';
import MedicationPrescriptions from './MedicationPrescriptions'; 
import PastHistory from './PastHistory';
import MedicalSummary from './MedicalSummary';
import HistoryOfChiefPresentingComplaint from './HistoryOfChiefPresentingComplaint';
import TreatmentPlan from './TreatmentPlan';
import DemographicDetails from './DemographicDetails';
import {
    Page,
    Banner,
    TextStyle,
    Modal,
    TextContainer,
    Scrollable,
    Stack
} from '@shopify/polaris';
import { Box } from '@primer/components';
import { useParams } from 'react-router-dom';
import { createMuiTheme, makeStyles, Tabs, Tab, Typography, } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#BBE5B3',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#50B83C',
        }
    },
    typography: {
        fontSize: 20,
    },
});
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(() => ({
    root: {
        width: '280px',
        backgroundColor: '#f2f8f0',
        borderRadius: '5px',
    },
    tabs: {
        textAlign: "start",
    },
    tab: {
        textAlign: "center",
        textTransform: "none",
        fontSize: "1.5rem",
    },
}));


export default function AddlHealthRecord() {
    const { patientid } = useParams() as {
        patientid: string;
    };
    const classes = useStyles();
    const [tabValue, setTabValue] = React.useState(1);
    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
        //localStorage.setItem("selctedprofiletab", String(newValue));
        window.scrollTo(0, 0);
    };
    //information modal fot patient consent.....
    const [active, setActive] = useState(false);

    const [disableTab, setDisableTab] = useState(false);

    const handleCosentChange = useCallback(() => setActive(!active), [active]);

    useEffect(() => {
        //setTabValue(Number(localStorage.getItem("selctedprofiletab")));
        window.scrollTo(0, 0);
        if (localStorage.getItem("consultationopt") === 'Comprehensive ( Recommended )') {
            //setTabValue(19);
            setTabValue(1);
            // disable tab 
            //setDisableTab(false);
        }
        else if (localStorage.getItem("consultationopt") === 'Medical Report Based') {
            setTabValue(1);
            // disable tab 
            //setDisableTab(false);
        }
        else if (localStorage.getItem("opiniontype") === String(2) &&
            localStorage.getItem("responseTime") === 'Speedy Consult (First response by a doctor in 8 working hours)') {
            setTabValue(1);
            // disable tab 
            // setDisableTab(true);
        }
    }, []);
    // Consent 
    const ConsentModal = (
        <Modal
            open={active}
            onClose={handleCosentChange}
            title="INFORMED CONSENT FOR TELEMEDICINE SERVICES"
            primaryAction={{
                content: 'Agree',
                onAction: (handleCosentChange)
            }}
        >
            <Modal.Section>
                <Box style={{ border: "1px #50B83C solid", borderRadius: "3px" }} p={3}>
                    <TextContainer>
                        <InformConsent />
                    </TextContainer>
                </Box>
            </Modal.Section>
        </Modal>
    );

    const patientAddDesc = patientid ? (
        <ThemeProvider theme={defaultMaterialTheme}>
            <div className={classes.root}>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="Patient Health Record"
                    className={classes.tabs}
                    indicatorColor="secondary"
                >
                    <Tab className={classes.tab} label="Demographic Details" {...a11yProps(0)} />
                    <Tab className={classes.tab} label="Presenting Complaint(s)" {...a11yProps(1)} />
                    <Tab disabled={disableTab} className={classes.tab} label="Ideas,Concerns,Expectations" {...a11yProps(2)} />
                    <Tab disabled={disableTab} className={classes.tab} label="History of Presenting Complaint(s)" {...a11yProps(3)} />
                    <Tab disabled={disableTab} className={classes.tab} label="Review of Systems" {...a11yProps(4)} />
                    <Tab disabled={disableTab} className={classes.tab} label="Past History" {...a11yProps(5)} />
                    <Tab disabled={disableTab} className={classes.tab} label="Tests,Procedures,Investigations" {...a11yProps(6)} />
                    <Tab disabled={disableTab} className={classes.tab} label="Medication / Prescriptions" {...a11yProps(7)} />
                    <Tab disabled={disableTab} className={classes.tab} label="Allergies" {...a11yProps(8)} />
                    <Tab disabled={disableTab} className={classes.tab} label="Immunization" {...a11yProps(9)} />
                    <Tab disabled={disableTab} className={classes.tab} label="Family History" {...a11yProps(10)} />
                    <Tab disabled={disableTab} className={classes.tab} label="Nutritional History" {...a11yProps(11)} />
                    <Tab disabled={disableTab} className={classes.tab} label="Personal and Social History" {...a11yProps(12)} />
                    <Tab disabled={disableTab} className={classes.tab} label="Health Maintenance History" {...a11yProps(13)} />
                    <Tab disabled={disableTab} className={classes.tab} label="Risk Factors" {...a11yProps(14)} />
                    <Tab disabled={disableTab} className={classes.tab} label="Diagnosis" {...a11yProps(15)} />
                    <Tab disabled={disableTab} className={classes.tab} label="Medical Summary" {...a11yProps(16)} />
                    <Tab disabled={disableTab} className={classes.tab} label="Problem List" {...a11yProps(17)} />
                    <Tab disabled={disableTab} className={classes.tab} label="Treatment Plan" {...a11yProps(18)} />
                    <Tab className={classes.tab} label="Imported Data" {...a11yProps(19)} />
                </Tabs>
            </div>
        </ThemeProvider>
    ) : (
        <div>
            <Banner>
                <Scrollable shadow style={{ height: '200px' }}>
                    <InformConsent />
                </Scrollable>
            </Banner>
            <Box py="5">
                <TextStyle>By clicking “Submit” you have agreed to this informed consent</TextStyle>
            </Box>
        </div>
    );

    const ActualPageMarkup = (
        //edit health record part.........
        <Stack spacing="extraTight" wrap={false}>
            <Stack.Item>
                <Box width="280px" pt={2}>
                    {patientAddDesc}
                </Box>
            </Stack.Item>
            <Stack.Item fill>
                {patientid ? (
                    <ThemeProvider theme={defaultMaterialTheme}>
                        <TabPanel value={tabValue} index={0}>
                            <DemographicDetails />
                        </TabPanel>

                        <TabPanel value={tabValue} index={1}>
                            <ChiefComplaints />
                        </TabPanel>

                        <TabPanel value={tabValue} index={2}>
                            <IdeasConcernsExpectations />
                        </TabPanel>

                        <TabPanel value={tabValue} index={3}>
                            <HistoryOfChiefPresentingComplaint />
                        </TabPanel>

                        <TabPanel value={tabValue} index={4}>
                            <SystemReview />
                        </TabPanel>

                        <TabPanel value={tabValue} index={5}>
                            <PastHistory />
                        </TabPanel>

                        <TabPanel value={tabValue} index={6}>
                            <TestsProceduresAndInvestigations />
                        </TabPanel>

                        <TabPanel value={tabValue} index={7}>
                            <MedicationPrescriptions />
                        </TabPanel>

                        <TabPanel value={tabValue} index={8}>
                            <Allergy />
                        </TabPanel>

                        <TabPanel value={tabValue} index={9}>
                            <Immunization />
                        </TabPanel>

                        <TabPanel value={tabValue} index={10}>
                            <FamilyHistory />
                        </TabPanel>

                        <TabPanel value={tabValue} index={11}>
                            <NutritionalHistory />
                        </TabPanel>

                        <TabPanel value={tabValue} index={12}>
                            <PersonalHistory />
                        </TabPanel>

                        <TabPanel value={tabValue} index={13}>
                            <HealthMaintenanceHistory />
                        </TabPanel>

                        <TabPanel value={tabValue} index={14}>
                            <RiskFactors />
                        </TabPanel>

                        <TabPanel value={tabValue} index={15}>
                            <Diagnosis />
                        </TabPanel>

                        <TabPanel value={tabValue} index={16}>
                            <MedicalSummary />
                        </TabPanel>

                        <TabPanel value={tabValue} index={17}>
                            <ProblemList />
                        </TabPanel>

                        <TabPanel value={tabValue} index={18}>
                            <TreatmentPlan />
                        </TabPanel>

                        <TabPanel value={tabValue} index={19}>
                            <Importeddata />
                        </TabPanel>
                    </ThemeProvider>
                ) : ("")
                }
            </Stack.Item>
        </Stack>
    );


    return (
        <Page fullWidth>
            {ActualPageMarkup}
            {ConsentModal}
        </Page>
    );
}