import React, { useState, useCallback } from 'react';
import { Box, Flex } from '@primer/components';
import {
    Tabs,
    Page,
    Layout,
} from '@shopify/polaris';
import AllDoctor from './AllDoctor';
import FindDoctor from './FindDoctor';

export default function Doctor() {
    const [selected, setSelected] = useState(0);
    const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), []);

    const tabs = [

        {
            id: 'allDoctor',
            content: 'All Doctor',
            body: (
                <AllDoctor />
            ),
            panelID: 'all-doctor',
        },
        {
            id: 'findDoctor',
            content: 'Find Doctor',
            body: (
                <FindDoctor />
            ),
            panelID: 'find- doctor',
        },
    ];

    return (
        <Page fullWidth>
            <Layout>
                <Box my={3} style={{ height: "350px", width: "100%" }} >
                    <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                        <Box py={3}>{tabs[selected].body}</Box>
                    </Tabs>
                </Box>
            </Layout>
        </Page>

    );
}