import React, { useState, useEffect } from 'react';
import {
    Page,
    Loading,
    TextStyle,
    Caption,
    DisplayText,
    MediaCard,
} from '@shopify/polaris';
import { makeStyles, createStyles, Theme, createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Box } from '@primer/components';
import Grid from "@material-ui/core/Grid";
import { CardHeader, CardMedia, Avatar, Divider } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DoctorList } from '../../redux/interfaces/Model';
import { AppState } from '../../redux/store/Store';
import { getDoctorListValue } from '../../redux/effects/ApiCall';
import { Link } from 'react-router-dom';
const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#DE3618',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#BF0711',
        },
    },
    typography: {
        fontSize: 22,
    }
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
        },
        content: {
            flex: '1 0 auto',
        },
        media: {
            height: 0,
            //paddingTop: '65.25%', // 16:9
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        avatar: {
            backgroundColor: "#DE3618",
        },
    }),
);

export default function AllDoctor() {
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    const history = useHistory();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDoctorListValue());
    }, [dispatch]);

    const loadingMarkup = <Loading />;
    const handleBookAppointment = (doctorItem: any) => {
        console.log("===DOCID===>", doctorItem.id);
        history.push('/appointment/' + doctorItem.id);
    };
    function doctorDetails() {
        history.push('/doctordetails');
    }
    const docotorList = useSelector((state: AppState) => state.doctorValue);
    const actualPageMarkup = (
        <Box m={3}>
            <ThemeProvider theme={defaultMaterialTheme}>
                <Grid container spacing={1}>
                    <Grid container item sm={12} spacing={1}>
                        <React.Fragment>
                            {docotorList.doctorValue.map((doctorItem: DoctorList) => (
                                <Grid item md={4}>
                                    <Link to={`/doctordetails/${doctorItem.id}`}>
                                        <Card className={classes.root}>
                                            <div style={{ height: '500px' }}>
                                                <div style={{ height: '105px' }}>
                                                    <CardHeader
                                                        avatar={
                                                            <Avatar aria-label="recipe" className={classes.avatar}>
                                                                <LocalHospitalIcon />
                                                            </Avatar>
                                                        }
                                                        title={<h5>{doctorItem.fullName}</h5>}
                                                        subheader={<small>{doctorItem.qualification}</small>}
                                                    />
                                                </div>
                                                <Grid container
                                                    alignItems='center'
                                                    justify='center'
                                                >
                                                    <div style={{ height: '300px', width: '300px' }}>
                                                        <img
                                                            alt=""
                                                            width="100%"
                                                            height="100%"
                                                            src={doctorItem.image?.document}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Box alignItems="center" my={1} display={'flex'} className={classes.content}>
                                                    <Box justifyContent="center" p={2} flex={'auto'}>
                                                        <Caption>Specialty</Caption>
                                                        <TextStyle variation="strong">{doctorItem.specialtyWithYearsOfExperience}</TextStyle>
                                                    </Box>
                                                </Box>
                                            </div>
                                        </Card>
                                    </Link>
                                </Grid>
                            ))}
                        </React.Fragment>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </Box >
    );

    return (
        <Page title="Doctor's List" fullWidth>
            {docotorList.loading ? loadingMarkup : ''}
            {actualPageMarkup}
        </Page>
    )
}
