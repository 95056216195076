import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useState, useCallback } from 'react';
import baseUrl from '../../Url';
import {
    Card,
    FormLayout,
    Page,
    TextField,
    Button,
    Layout,
    Loading,
    Banner,
} from '@shopify/polaris';
import { Box } from '@primer/components';
import axios from 'axios';
import { Icon, SelectPicker } from 'rsuite';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme, IconButton } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import DateFnsUtils from '@date-io/date-fns';
const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#BBE5B3',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#50B83C',
        }
    },
    typography: {
        fontSize: 20,
    }
});
interface AccountProps {
    onAction(): void;
}

export default function NeedHelp() {
    const [toSignin, setToSignin] = useState(false)

    const [helpPhoneNumber, setHelpPhoneNumber] = useState('');
    const [textValue, setTextValue] = useState('');
    const [patientName, setPatientName] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const handleChanePhoneNumber = useCallback((value) => setHelpPhoneNumber(value), []);
    const handleChange = useCallback((newValue) => setTextValue(newValue), []);
    const handleNameChange = useCallback((value) => setSelectedPatientName(value), []);
    const loadingMarkup = isLoading ? <Loading /> : null;
    const [selectedPatientName, setSelectedPatientName] = useState('');
    const [helpQueryMessage, setHelpQueryMessage] = useState('');
    const [helpQueryError, setHelpQueryError] = useState('');
    const handleBannerDismiss = () => {
        setHelpQueryMessage('');
        setHelpQueryError('');
    }

    const successMessage = helpQueryMessage ?
        <Banner onDismiss={handleBannerDismiss} status="success" title={helpQueryMessage}></Banner> : '';

    const errorHelpDiv = helpQueryError ? (
        <Banner status="critical" onDismiss={handleBannerDismiss}>{helpQueryError}</Banner>
    ) : '';

    const [selectedDate, setSelectedDate] = React.useState<Date | null>(
        //new Date(''),
    );
    const today = new Date();
    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
        console.log(selectedDate);
    };

    // Submit help api call section  ......
    const userToken = localStorage.getItem("token");
    const submitQuaryBody = {
        "queryText": textValue,
        "phoneNumber": helpPhoneNumber,
        "patientId": selectedPatientName
    }
    const needHelp = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patienthelp/submit_query',
            submitQuaryBody, {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Token ${userToken} `
            }
        }
        )
            .then(result => {
                console.log(result);
                setHelpQueryMessage(result.data.message);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
                setHelpQueryError(error.response.data.message);
            })

    }

    useEffect(() => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/patientapp/api/patient/patient_list_dropdown', {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Token ${userToken}`
            }
        })
            .then((res) => {
                setIsLoading(false);
                let response = res.data.patientList;
                setPatientName(response);
            })
            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }, []);

    const pagebody = (
        <Layout>
            <Layout.AnnotatedSection
                title="Confused whether to take 2nd opinion? "
                description="Need help to get right doctor consultation? Need assistance in uploading medical record? We will be happy to help."
            >
                <Card sectioned title="Get call back from AIMS2Health">
                    <FormLayout>
                        <FormLayout.Group condensed>
                            <div>
                                <TextField
                                    label="Patient Phone Number"
                                    value={helpPhoneNumber}
                                    placeholder="Please enter your phone number"
                                    id="othersignificantillness"
                                    onChange={handleChanePhoneNumber} /><br />
                                <div>
                                    <p style={{ marginBottom: "5px" }}>Patient Name  (Optional)</p>
                                    <SelectPicker
                                        block
                                        onChange={handleNameChange}
                                        data={patientName}
                                        value={selectedPatientName}
                                    /><br />
                                </div>
                                <div>
                                    <p style={{ marginBottom: "5px" }}>Preferred Date (Optional)</p>
                                    <ThemeProvider theme={defaultMaterialTheme}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker minDate={today}
                                                autoOk
                                                inputVariant="outlined"
                                                fullWidth
                                                format="dd/MM/yyyy"
                                                value={selectedDate}
                                                InputAdornmentProps={{ position: "start" }}
                                                onChange={date => handleDateChange(date)}
                                                size="small"
                                                focused
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton onClick={() => handleDateChange(null)}>
                                                            <Icon icon="close" />
                                                        </IconButton>
                                                    )
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </ThemeProvider>
                                </div>
                            </div>
                            <div>
                                <TextField
                                    label="Description  (Optional)"
                                    value={textValue}
                                    onChange={handleChange}
                                    placeholder="Please describe patient's problem."
                                    multiline={8} />
                            </div>
                        </FormLayout.Group>
                    </FormLayout>
                    <FormLayout.Group condensed>
                        <div style={{ float: 'right' }}>
                            <Button primary onClick={needHelp}>Submit</Button>
                        </div>
                    </FormLayout.Group>
                    <Box my={3}>{successMessage}</Box>
                    <Box my={3}>{errorHelpDiv}</Box>
                </Card>
            </Layout.AnnotatedSection>
        </Layout>
    )

    return (
        <div>
            {toSignin ? <Redirect to="/signin" /> : null}
            <Page
                fullWidth
                title="Need Help"
            >
                    {pagebody}
                    {loadingMarkup}
            </Page>
        </div>
    );
}