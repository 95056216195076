import React, { useState, useCallback, useEffect } from 'react';
import {
    Card,
    FormLayout,
    TextField,
    InlineError,
    Banner,
    TextStyle,
    Thumbnail,
    Loading,
    Caption,
    Page,
    DataTable,
    Modal,
    Button,
    TextContainer,
    Subheading,
    ExceptionList
} from '@shopify/polaris';
import { Box } from '@primer/components';
import { Icon, Loader, Uploader } from 'rsuite';
import { initializeIcons } from '@uifabric/icons';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { createMuiTheme, IconButton } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { ViewMinor, NoteMinor, DeleteMinor } from '@shopify/polaris-icons';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import baseUrl from '../../../Url';

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#50B83C',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        }
    },
    typography: {
        fontSize: 18,
    }
});
initializeIcons();

interface AccountProps {
    onAction(): void;
}

export default function RiskFactors(props: any) {

    const styles = {
        width: 80,
        height: 80,
        borderRadius: 40
    };
    const { patientid } = useParams() as {
        patientid: string;
    };
    const { caseid } = useParams() as {
        caseid: string;
    };
    const [patientDetailsId, setPatientDetailsId] = useState('');

    const [toSignin, setToSignin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;

    const [imageId, setImageId] = useState('');
    const [uploading] = React.useState(false);
    const [imageInfo, setImageInfo] = useState('');

    const [riskDescription, setRiskDescription] = useState('');
    const handleRiskDescriptionChange = useCallback((newValue) => {
        setRiskDescription(newValue);
    }, []);

    const [selectedDate, setSelectedDate] = React.useState<Date | null>(
        new Date(),
    );
    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
    };

    const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(
        new Date(),
    );
    const handleEndDateChange = (date: Date | null) => {
        setSelectedEndDate(date);
    };

    const closeRiskError = () => {
        setRiskError('')
    }
    const [riskError, setRiskError] = useState('');
    const errorDiv = riskError
        ? <Banner
            onDismiss={closeRiskError}
            status="critical">
            <p>{riskError}</p>
        </Banner>
        : '';

    const closeRiskSuccess = () => {
        setriskSuccess('');
    }
    const [riskSuccess, setriskSuccess] = useState('');
    const successDiv = riskSuccess
        ? <Banner
            onDismiss={closeRiskSuccess}
            status="success">
            <p>{riskSuccess}</p>
        </Banner>
        : '';



    const [riskDescriptionError, setRiskDescriptionError] = useState('');
    const riskDescriptionErrordiv = riskDescriptionError
        ? <TextStyle variation="negative">{riskDescriptionError}</TextStyle>
        : '';

    const uploadbody = {
        'typeName': 'patient-image'
    };

    const [modalActive, setModalActive] = useState(false);

    const handleToggle = () => {
        setModalActive((modalActive) => !modalActive);
        setSelectedDate(new Date());
        setSelectedEndDate(new Date());
        setImageInfo('');
        setRiskDescriptionError('');
        setRiskDescription('');
        setriskSuccess('');
        setRiskError('');
    }

    const [descriptionModalActive, setDescriptionModalActive] = useState(false);
    const handleDescriptionToggle = () => {
        setDescriptionModalActive((descriptionModalActive) => !descriptionModalActive);
        setSelectedDate(new Date());
        setSelectedEndDate(null);
        setImageInfo('');
        setRiskDescriptionError('');
        setRiskDescription('');
        setriskSuccess('');
        setRiskError('');
    }

    const [docViwerActive, setDocViwerActive] = useState(false);
    const handleDocViewerChange = useCallback(() => setDocViwerActive(!docViwerActive), [docViwerActive]);

    const [deleteModalActive, setDeleteModalActive] = useState(false);
    const handleDeleteRiskDetailsModalChange = useCallback(() => setDeleteModalActive(!deleteModalActive), [deleteModalActive]);

    // Delete popup open ........   
    function deleteriskFactors(response: any) {
        setPatientDetailsId(response);
        setDeleteModalActive((deleteModalActive) => !deleteModalActive);
        setDeleteRiskSuccess('');
    }

    const closeDeleteRiskModalSucessError = () => {
        setDeleteRiskSuccess('');
    }

    const [deleteRiskSuccess, setDeleteRiskSuccess] = useState('');
    const successDeleteRiskModalDiv = deleteRiskSuccess
        ? <Banner
            onDismiss={closeDeleteRiskModalSucessError}
            status="success">
            <p>{deleteRiskSuccess}</p>
        </Banner>
        : '';

    const [viewDescriptionModalActive, setViewDescriptionModalActive] = useState(false);
    const viewDescription = (description: any, dateStart: any, dateEnd: any, supportingDocuments: any) => {
        setViewDescriptionModalActive((viewDescriptionModalActive) => !viewDescriptionModalActive);
        setRiskDescription(description);
        setSelectedDate(dateStart);
        handleEndDateChange(null);
        setSelectedEndDate(dateEnd);
        setImageInfo(supportingDocuments);
    }
    const handleViewDescriptionToggle = () => {
        setViewDescriptionModalActive((viewDescriptionModalActive) => !viewDescriptionModalActive);
        setRiskDescription('');
        setSelectedDate(new Date());
        handleEndDateChange(null);
        setImageInfo('');
    }

    //child to parent value send section.....
    const symptomBody = {
        "patientId": patientid,
        "caseId": caseid,
        "secondOpinionUiSection": "riskFactors",
        "description": riskDescription,
        "patientDocumentId": imageId,
        "startDate": selectedDate,
        "endDate": selectedEndDate
    };

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    const [riskFactorListValue, setRiskFactorListValue] = useState<any[]>([]);
    const truncate = (str: string,) => {
        return str.length > 0 ? str.substring(0, 60) + "..." : str;
    }

    const getRiskBody = {
        "caseId": caseid,
        "secondOpinionUiSection": "riskFactors"
    }

    const [questionListValue, setQuestionListValue] = useState<any[]>([]);
    // Get questionnair list
    const getQuestionnaitList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/get_patientQuestionnaireList',
            getRiskBody, { headers })
            .then((res) => {
                setIsLoading(false);
                let resultData = res.data.patientQuestionnaireList;

                console.log("SymptomList=>", resultData);
                console.log("question ", resultData[0].questionnaireType.displayValue);
                let ideasConfig = [];
                for (var i = 0; i < resultData.length; i++) {
                    let questionName = resultData[i].questionnaireType.displayValue;
                    let supportingDocuments = resultData[i].templateUrl;
                    ideasConfig.push([
                        questionName,
                        <Button onClick={() => {
                            setDocViwerActive(true);
                            setImageInfo(supportingDocuments);
                        }}>View Question</Button>,
                    ]);
                }
                setQuestionListValue(ideasConfig);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            })
    }

    // get document list
    const getRiskfactorList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/get_patientDetailsList',
            getRiskBody, { headers })
            .then((res) => {
                setIsLoading(false);
                let resData = res.data.patientDetailList;
                let symptomConfig = [];
                for (var i = 0; i < resData.length; i++) {
                    let selectedResponse = resData[i];
                    let patientDetailsID = resData[i].id;
                    let tableDescription = (resData[i].description == null) ? "" : truncate(resData[i].description);
                    let description = (resData[i].description == null) ? "" : resData[i].description;
                    let tableDateStart = (resData[i].startDate == null) ? "" : (new Date(resData[i].startDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let dateStart = (resData[i].startDate == null) ? "" : resData[i].startDate;
                    let tablDateEnd = (resData[i].endDate == null) ? "" : (new Date(resData[i].endDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let dateEnd = (resData[i].endDate == null) ? null : resData[i].endDate;
                    let supportingDocuments = (resData[i].patientDocument == null) ? '' : resData[i].patientDocument.document;
                    symptomConfig.push([
                        <Button icon={NoteMinor} size="large" onClick={() => {
                            setDocViwerActive(true);
                            setImageInfo(supportingDocuments);
                        }}></Button>,
                        <Button icon={ViewMinor} onClick={() => {
                            viewDescription(description, dateStart!, dateEnd!, supportingDocuments);
                        }} />,
                        tableDescription,
                        tableDateStart,
                        tablDateEnd,
                        <Button icon={DeleteMinor} size="slim" onClick={() => deleteriskFactors(patientDetailsID)}></Button>
                    ]);
                }
                setRiskFactorListValue(symptomConfig);
            })
            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
                console.log(toSignin);
            })
    }

    const problemSubmit = () => {
        console.log(symptomBody);
        if (riskDescription === '') {
            setRiskDescriptionError("Please add description");
        }
        else {
            axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/add_patientDetails',
                symptomBody, { headers })
                .then(result => {
                    setIsLoading(false);
                    if (result) {
                        setRiskError('');
                        setRiskDescriptionError('');
                        setriskSuccess(result.data.message);
                        getRiskfactorList();
                        handleDescriptionToggle();
                    }
                })
                .catch((error) => {
                    console.log(error.response);
                    setRiskDescriptionError('');
                    setriskSuccess('');
                    setRiskError(error.response.data.message);
                    setIsLoading(false);
                })
        }
    }
    // Delete body....
    const deleteChiefComplaintBody = {
        "caseId": caseid,
        "patientDetailId": patientDetailsId
    }
    // delete api call here ....
    const DeleteRiskDetailsSubmit = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/delete_patientDetails',
            deleteChiefComplaintBody, { headers })
            .then((res) => {
                setIsLoading(false);
                setDeleteRiskSuccess(res.data.message);
                getRiskfactorList();
                handleDeleteRiskDetailsModalChange();
            })

            .catch((error) => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        getQuestionnaitList();
        getRiskfactorList();
    }, []);
    const questionBody = (
        <div>
            <Card>
                <Card.Header title="Questionnaire">
                </Card.Header>
                <Card.Section>
                    {questionListValue && questionListValue.length ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                            ]}
                            headings={[
                                'Question Name',
                                'View'
                            ]}
                            rows={questionListValue}
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Question is available!',
                                },
                            ]}
                        />)}
                </Card.Section>
            </Card>
            <br />
        </div >
    )

    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const problemListandTreatmentPlanAddInfo = (
        <div>
            <Card >
                <Card.Header title="Documents">
                    <Button primary={true} onClick={handleDescriptionToggle}>Upload Data</Button>
                </Card.Header>
                <Card.Section>
                    {riskFactorListValue && riskFactorListValue.length ? (
                        <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                            'text',
                            'text'
                        ]}
                        headings={[
                            'Document',
                            'View',
                            'Description',
                            'Start Date',
                            'End Date',
                            'Delete'
                        ]}
                            rows={riskFactorListValue}
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Documents is available!',
                                },
                            ]}
                        />)}
                </Card.Section>
            </Card>
        </div >
    )

    const docViewerComponent = (
        <Modal
            open={docViwerActive}
            onClose={handleDocViewerChange}
        >
            <Modal.Section>
                <TextContainer>
                    <p>
                        <iframe src={imageInfo} title='Small document' width='100%' height='700' frameBorder='0'>This is an embedded <a target='_blank' href='http://office.com' rel="noopener noreferrer">Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps' rel="noopener noreferrer">Office Online</a>.</iframe>
                    </p>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );

    const addSymptomDetails = (
        <Modal
            large
            open={modalActive}
            onClose={handleToggle}
            title={"Add Risk Factors"}
            primaryAction={{
                content: "Submit",
                onAction: problemSubmit,
            }}
        >
            <Modal.Section>
                <Page>
                    <Box style={{
                        position: "relative",
                        paddingBottom: "56.25%",
                        paddingTop: "25px",
                        height: "0"
                    }}>
                        <iframe style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            border: "0px"
                        }} src="https://virtualsathi-technicise-ehr.herokuapp.com/survey/14" title='Small document'></iframe>
                    </Box>
                </Page>
            </Modal.Section>
        </Modal>
    );
    // Delete modal section ....
    const deleteProblemsPageBody = (
        <Modal
            open={deleteModalActive}
            onClose={handleDeleteRiskDetailsModalChange}
            title={" Delete Risk Factor Details"}
            primaryAction={{
                content: "Yes",
                onAction: DeleteRiskDetailsSubmit,
            }}
            secondaryActions={[
                {
                    content: 'No',
                    onAction: handleDeleteRiskDetailsModalChange,
                },
            ]}
            footer={successDeleteRiskModalDiv}
        >
            <Modal.Section>
                <TextContainer>
                    <Subheading>
                        Are you sure, you want to delete this Risk Factor Details?
                    </Subheading>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );

    const uploadDescriptionBody = (
        <Modal
            large
            open={descriptionModalActive}
            onClose={handleDescriptionToggle}
            title={"Upload Documents of Risk Factors Details"}
            primaryAction={{
                content: "Submit",
                onAction: problemSubmit,
            }}
        >
            <Modal.Section>
                <Page>
                    <FormLayout>
                        <FormLayout.Group condensed>
                            <div>
                                <div>
                                    <TextField
                                        label="Risk Description"
                                        value={riskDescription}
                                        onChange={handleRiskDescriptionChange}
                                        placeholder="Please describe risk description"
                                        multiline={4}
                                        id="riskDescription"
                                    />
                                    <InlineError message={riskDescriptionErrordiv} fieldID="riskDescription" />
                                </div>
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Upload Supporting Document</p>
                                <Box style={{ textAlign: "center", border: "1px #50B83C solid", borderRadius: "3px" }} p={1}>
                                    <Uploader
                                        action="https://aims2health-webserver.herokuapp.com/storageapp/api/upload-image/"
                                        draggable
                                        data={uploadbody}
                                        name="document"
                                        listType="text"
                                        onSuccess={(response: Object,) => {
                                            let res = JSON.stringify(response);
                                            setImageId(JSON.parse(res).image.id);
                                            setImageInfo(JSON.parse(res).image.document);
                                            console.log(imageId);
                                        }}
                                    >
                                        <button style={styles} type="button">
                                            {uploading && <Loader backdrop center />}
                                            {imageInfo ? (
                                                <img src={imageInfo} width="100%" height="100%" alt="" />
                                            ) : (
                                                <Thumbnail source={NoteMinor} size="large" alt="Small document" />

                                            )}
                                        </button>
                                    </Uploader>
                                </Box>
                                <Caption>
                                    <p style={{ color: 'gray' }}>Document size should be maximum 3MB.</p>
                                </Caption>
                            </div>
                        </FormLayout.Group>
                        <FormLayout.Group condensed>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Start Date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => handleDateChange(date)}
                                            size="small"
                                            focused
                                            id="startDate"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>End Date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedEndDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => handleEndDateChange(date)}
                                            size="small"
                                            focused
                                            id="endDate"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleEndDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                        </FormLayout.Group>
                        <div>{successDiv}</div>
                        <div>{errorDiv}</div>
                    </FormLayout>
                </Page>
            </Modal.Section>
        </Modal>
    )
    // View details markup
    const viewDocumentBody = (
        <Modal
            large
            open={viewDescriptionModalActive}
            onClose={handleViewDescriptionToggle}
            title={"View Risk Factors Details "}
            primaryAction={{
                content: "Close",
                onAction: handleViewDescriptionToggle,
            }}
        >
            <Modal.Section>
                <Page>
                    <FormLayout>
                        <FormLayout.Group condensed>
                            <div>
                                <TextField
                                    label="Risk Description"
                                    value={riskDescription}
                                    onChange={handleRiskDescriptionChange}
                                    placeholder="Please describe risk description"
                                    multiline={4}
                                    id="riskDescription"
                                />
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Upload Supporting Document</p>
                                <Box style={{ textAlign: "center", border: "1px #50B83C solid", borderRadius: "3px" }} p={1}>
                                    <Uploader
                                        action="https://aims2health-webserver.herokuapp.com/storageapp/api/upload-image/"
                                        draggable
                                        data={uploadbody}
                                        name="document"
                                        listType="text"
                                        onSuccess={(response: Object,) => {
                                            let res = JSON.stringify(response);
                                            setImageId(JSON.parse(res).image.id);
                                            setImageInfo(JSON.parse(res).image.document);
                                        }}
                                    >
                                        <button style={styles} type="button">
                                            {uploading && <Loader backdrop center />}
                                            {imageInfo ? (
                                                <img src={imageInfo} width="100%" height="100%" alt="" />
                                            ) : (
                                                <Thumbnail source={NoteMinor} size="large" alt="Small document" />

                                            )}
                                        </button>
                                    </Uploader>
                                </Box>
                                <Caption>
                                    <p style={{ color: 'gray' }}>Document size should be maximum 3MB.</p>
                                </Caption>
                            </div>
                        </FormLayout.Group>
                        <FormLayout.Group condensed>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Start Date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => handleDateChange(date)}
                                            size="small"
                                            focused
                                            id="startDate"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>End Date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedEndDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => handleEndDateChange(date)}
                                            size="small"
                                            focused
                                            id="endDate"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleEndDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                        </FormLayout.Group>
                    </FormLayout>
                </Page>
            </Modal.Section>
        </Modal>
    )
    return (
        <Page title="Risk Factors">
            {loadingMarkup}
            {viewDocumentBody}
            {questionBody}
            {problemListandTreatmentPlanAddInfo}
            {addSymptomDetails}
            {uploadDescriptionBody}
            {docViewerComponent}
            {deleteProblemsPageBody}
        </Page>
    );
}