import React, { useState, useCallback, useEffect } from 'react';
import {
    Page, Card, FormLayout, Subheading, TextField, Stack, TextStyle,
    Loading, Modal, DataTable, Button, TextContainer, InlineError, Banner,
    Caption,
    Thumbnail,
    Heading,
    Toast,
    ExceptionList,
} from '@shopify/polaris';
import { Box } from '@primer/components';
import { Redirect, useParams } from 'react-router-dom';
import { NoteMinor, EditMinor, AccessibilityMajor } from '@shopify/polaris-icons';
import axios from 'axios';
import { Icon, Uploader, Loader } from 'rsuite';
import baseUrl from '../../Url';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme, IconButton } from "@material-ui/core";
import { initializeIcons } from '@uifabric/icons';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from "@material-ui/styles";

export default function DoctorPatientInteraction() {

    const defaultMaterialTheme = createMuiTheme({
        palette: {
            primary: {
                // light: will be calculated from palette.primary.main,
                main: '#50B83C',
                // dark: will be calculated from palette.primary.main,
                // contrastText: will be calculated to contrast with palette.primary.main
            }
        },
        typography: {
            fontSize: 18,
        }
    });
    initializeIcons();

    const styles = {
        width: 80,
        height: 80,
        borderRadius: 40
    };

    // const { patientid } = useParams();
    const { patientid } = useParams() as {
        patientid: string;
    };
    // const { caseid } = useParams();
    const { caseid } = useParams() as {
        caseid: string;
    };

    const [patientDetailsId, setPatientDetailsId] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;

    const [joinUrl, setJoinUrl] = useState('');

    const [imageId, setImageId] = useState('');
    const [uploading] = React.useState(false);
    const [imageInfo, setImageInfo] = useState('');

    const [prescriptionDescription, setPrescriptionDescription] = useState('');
    const handlePrescriptionDescriptionChange = useCallback((newValue) => {
        setPrescriptionDescription(newValue);
    }, []);

    const [prescriptionStartDate, setPrescriptionStartDate] = React.useState<Date | null>(
        new Date(),
    );
    const handlePrescriptionStartChange = (prescriptionSDate: Date | null) => {
        setPrescriptionStartDate(prescriptionSDate);
    };

    const [prescriptionEndDate, setPrescriptionEndDate] = React.useState<Date | null>(
        new Date(),
    );
    const handlePrescriptionEndDateChange = (prescriptionEDate: Date | null) => {
        setPrescriptionEndDate(prescriptionEDate);
    };

    const [prescriptionDescriptionError, setPrescriptionDescriptionError] = useState('');
    const prescriptionDescriptionErrordiv = prescriptionDescriptionError
        ? <TextStyle variation="negative">{prescriptionDescriptionError}</TextStyle>
        : '';

    // Prescription API Error 
    const closePrescriptionError = () => {
        setPrescriptionError('')
    }

    const [prescriptionError, setPrescriptionError] = useState('');
    const prescriptionErrorDiv = prescriptionError
        ? <Banner
            onDismiss={closePrescriptionError}
            status="critical">
            <p>{prescriptionError}</p>
        </Banner>
        : '';
    //

    // Prescription API Success
    const closePrescriptionSuccess = () => {
        setPrescriptionSuccess('');
    }
    const [prescriptionSuccess, setPrescriptionSuccess] = useState('');
    const prescriptionSuccessDiv = prescriptionSuccess
        ? <Banner
            onDismiss={closePrescriptionSuccess}
            status="success">
            <p>{prescriptionSuccess}</p>
        </Banner>
        : '';
    //

    const uploadPrescriptionbody = {
        'typeName': 'patient-image'
    };

    const [prescriptionModalActive, setPrescriptionModalActive] = useState(false);
    const handlePrescriptionModalToggle = () => {
        setPrescriptionModalActive((prescriptionModalActive) => !prescriptionModalActive);
        setPrescriptionStartDate(new Date());
        setPrescriptionEndDate(null);
        setImageInfo('');
        setPrescriptionDescription('');
        setPrescriptionSuccess('');
        setPrescriptionError('');
    }

    const [deletePrescriptionModalActive, setDeletePrescriptionModalActive] = useState(false);
    const handleDeletePrescriptionModalChange = useCallback(() => setDeletePrescriptionModalActive(!deletePrescriptionModalActive), [deletePrescriptionModalActive]);

    const closeDeletePrescriptionModalSucessError = () => {
        setDeletePrescriptionSuccess('');
    }

    const [deletePrescriptionSuccess, setDeletePrescriptionSuccess] = useState('');
    const successDeleteProblemModalDiv = deletePrescriptionSuccess
        ? <Banner
            onDismiss={closeDeletePrescriptionModalSucessError}
            status="success">
            <p>{deletePrescriptionSuccess}</p>
        </Banner>
        : '';


    // Question section variable description

    const [questionImageId, setQuestionImageId] = useState('');
    const [questionUploading] = React.useState(false);
    const [questionImageInfo, setQuestionImageInfo] = useState('');

    const uploadQuestionBody = {
        'typeName': 'patient-image'
    };

    const [questionDescription, setQuestionDescription] = useState('');
    const handleQuestionDescriptionChange = useCallback((newValue) => {
        setQuestionDescription(newValue);
    }, []);

    const [questionStartDate, setQuestionStartDate] = React.useState<Date | null>(
        new Date(),
    );
    const handleQuestionStartDateChange = (questionDate: Date | null) => {
        setQuestionStartDate(questionDate);
    };

    const [questionEndDate, setQuestionEndDate] = React.useState<Date | null>(
        new Date(),
    );
    const handleQuestionEndDateChange = (questionEnddate: Date | null) => {
        setQuestionEndDate(questionEnddate);
    };

    const [questionDescriptionError, setQuestionDescriptionError] = useState('');
    const questionDescriptionErrordiv = questionDescriptionError
        ? <TextStyle variation="negative">{questionDescriptionError}</TextStyle>
        : '';

    const closeQuestionError = () => {
        setQuestionnError('')
    }

    const [QuestionError, setQuestionnError] = useState('');
    const questionErrorDiv = QuestionError
        ? <Banner
            onDismiss={closeQuestionError}
            status="critical">
            <p>{QuestionError}</p>
        </Banner>
        : '';

    const closeQuestionSuccess = () => {
        setPrescriptionSuccess('');
        setQuestionSuccess('');
    }
    const [questionSuccess, setQuestionSuccess] = useState('');
    const questionSuccessDiv = questionSuccess
        ? <Banner
            onDismiss={closeQuestionSuccess}
            status="success">
            <p>{questionSuccess}</p>
        </Banner>
        : '';

    const [questionModalActive, setQuestionModalActive] = useState(false);
    const handleQuestionModalToggle = () => {
        setQuestionModalActive((questionModalActive) => !questionModalActive);
        setQuestionDescription('');
        setQuestionDescriptionError('');
        setQuestionSuccess('');
        setQuestionnError('');
    }
    const [answerModalactive, setAnswerActive] = useState(true);

    const [docViwerActive, setDocViwerActive] = useState(false);
    const handleDocViewerChange = useCallback(() => setDocViwerActive(!docViwerActive), [docViwerActive]);

    const [questionDocViwerActive, setQuestionDocViwerActive] = useState(false);
    const handleQuestionDocViewerChange = useCallback(() => setQuestionDocViwerActive(!questionDocViwerActive), [questionDocViwerActive]);

    const [deleteQuestionModalActive, setDeleteQuestionModalActive] = useState(false);
    const handleDeleteQuestionModalChange = useCallback(() => setDeleteQuestionModalActive(!deleteQuestionModalActive), [deleteQuestionModalActive]);

    const closeDeleteRiskModalSucessError = () => {
        setDeleteQuestionModalActive((deleteQuestionModalActive) => !deleteQuestionModalActive);
        setDeleteQuestionSuccess('');
    }

    const [deleteQuestionSuccess, setDeleteQuestionSuccess] = useState('');
    const deleteQuestionSuccessDiv = deleteQuestionSuccess
        ? <Banner
            onDismiss={closeDeleteRiskModalSucessError}
            status="success">
            <p>{deleteQuestionSuccess}</p>
        </Banner>
        : '';

    const [toastSuccessMessage, setToastSuccessMessage] = useState('');
    const [toastSuccessActive, setToastSuccessActive] = useState(false);
    const toggleToastSuccessActive = useCallback(() => setToastSuccessActive((toastSuccessActive) => !toastSuccessActive), []);

    const toastSuccessMarkup = toastSuccessActive ? (
        <Toast content={toastSuccessMessage} onDismiss={toggleToastSuccessActive} />
    ) : null;

    const [toastErrorMessage, setToastErrorMessage] = useState('');
    const [toastErrorActive, setToastErrorActive] = useState(false);
    const toggleToastErrorActive = useCallback(() => setToastErrorActive((toastErrorActive) => !toastErrorActive), []);

    const toastErrorMarkup = toastErrorActive ? (
        <Toast content={toastErrorMessage} onDismiss={toggleToastErrorActive} />
    ) : null;

    const [documentListValue, setDocumentListValue] = useState<any[]>([]);
    const [askedQuestionListValue, setAskedQuestionListValue] = useState<any[]>([]);
    const truncate = (str: string,) => {
        return str.length > 10 ? str.substring(0, 20) + "..." : str;
    }

    const [question, setQuestion] = useState("");
    const [questionId, setQuestionId] = useState("");
    const [answer, setAnswer] = useState("");
    const [answerquestion, setAnswerquestion] = useState("");
    const handleAnswerQuestionChange = useCallback((newValue) => setAnswerquestion(newValue), []);

    const [answerModalActive, setAnswerModalActive] = useState(false);
    const handleAnswerModalActive = () => {
        setAnswerModalActive((answerModalActive) => !answerModalActive);
        setAnswerquestion('');
        setAsnwerSubmitError('');
        setAnswerSubmitSuccess('');
    }

    const [viewAnswerModalActive, setViewAnswerModalActive] = useState(false);
    const handleViewAnswerModalActive = () => {
        setViewAnswerModalActive((viewAnswerModalActive) => !viewAnswerModalActive);
        setAnswerquestion('');
        setAsnwerSubmitError('');
        setAnswerSubmitSuccess('');
    }


    const [telehealthBannerTitle] = useState('Telehealth');
    const [telehealthBannerBody] = useState('You can access the telehealth using following link.');

    function answerById(questionID: any, questionText: any) {
        handleAnswerModalActive();
        setQuestionId(questionID);
        setQuestion(questionText);
    }

    function viewAnswerById(questionID: any, questionText: any, fullAnswer: any) {
        handleViewAnswerModalActive();
        setQuestionId(questionID);
        setQuestion(questionText);
        setAnswer(fullAnswer);
    }


    const closeAnswerSubmitError = () => {
        setAsnwerSubmitError('')
    }

    const [asnwerSubmitError, setAsnwerSubmitError] = useState('');
    const errorAnswerSubmitDiv = asnwerSubmitError
        ? <Banner
            onDismiss={closeAnswerSubmitError}
            status="critical">
            <p>{asnwerSubmitError}</p>
        </Banner>
        : '';

    const [answerSubmitSuccess, setAnswerSubmitSuccess] = useState('');
    const closeAnswerSubmitSuccess = () => {
        setAnswerSubmitSuccess('');
    }
    const successAnswerSubmitDiv = answerSubmitSuccess
        ? <Banner
            onDismiss={closeAnswerSubmitSuccess}
            status="success">
            <p>{answerSubmitSuccess}</p>
        </Banner>
        : '';

    const [toSignin, setToSignin] = useState(false);

    const [isEdit, setIsEdit] = useState(true);

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    // Get Zoom Meeting URL
    const getMeetingDetails = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/telehealthapp/api/telehealth/getInstantMeeting',
            { caseId: caseid }, { headers })
            .then(result => {
                if (result.data.status == 1) {
                    setIsLoading(false);
                    setIsEdit(false);
                    setToastSuccessActive(true);
                    setToastSuccessMessage(result.data.message);
                    console.log("Status Message===>", result.data.status);
                    setJoinUrl(result.data.joinUrl);
                }
                else if (result.data.status == -1) {
                    setIsLoading(false);
                    setIsEdit(true);
                    setToastSuccessActive(true);
                    setToastSuccessMessage(result.data.message);
                    console.log("Status Message===>", result.data.status);
                }
                else {
                    // setIsLoading(false);
                    // setIsEdit(false);
                    // setToastSuccessActive(true);
                    // setToastSuccessMessage(result.data.message);
                    // console.log("Status Message===>", result.data.status);
                    // setJoinUrl(result.data.joinUrl);
                }
            })
            .catch((error) => {
                if (error.data.status == 0) {
                    setToastErrorActive(true);
                    setIsLoading(false);
                    setIsEdit(true);
                    setToastErrorMessage(error.response.data.message);
                }
                else if (error.data.status == -3) {
                    setIsLoading(false);
                    setToastErrorActive(true);
                    setIsEdit(true);
                    setToastErrorMessage(error.response.data.message);
                }
                else if (error.data.status == -2) {
                    setIsLoading(false);
                    setToastErrorActive(true);
                    setIsEdit(true);
                    setToastErrorMessage(error.response.data.message);
                }
                else {
                    // setIsLoading(false);
                    // setToastErrorActive(true);
                    // setIsEdit(true);
                    // setToastErrorMessage(error.response.data.message);
                    // setJoinUrl(error.data.joinUrl);
                }
                console.log(error.response);
                setIsLoading(false);
            })
    }

    const getprescriptionBody = {
        "caseId": caseid,
        "secondOpinionUiSection": "ordersAndPrescriptions"
    }
    // Get Orders List API
    const getSecondOpinionList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/get_patientDetailsList',
            getprescriptionBody, { headers })
            .then((res) => {
                setIsLoading(false);
                let resData = res.data.patientDetailList;
                console.log("Symptom List=======>", resData);
                let symptomConfig = [];
                for (var i = 0; i < resData.length; i++) {
                    let selectedResponse = resData[i];
                    let patientDetailsID = resData[i].id;
                    let description = (resData[i].description == null) ? "" : truncate(resData[i].description);
                    console.log("Award Name " + description);
                    let dateStart = (resData[i].startDate == null) ? "" : (new Date(resData[i].startDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let dateEnd = (resData[i].endDate == null) ? "" : (new Date(resData[i].endDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let supportingDocuments = (resData[i].patientDocument == null) ? '' : resData[i].patientDocument.document;
                    console.log("supportingDocuments==> ", supportingDocuments);
                    setPatientDetailsId(patientDetailsID);
                    symptomConfig.push([
                        <Button icon={NoteMinor} size="large" onClick={() => {
                            setDocViwerActive(true);
                            setImageInfo(supportingDocuments);
                        }}></Button>,
                        description,
                        dateStart,
                        dateEnd,
                        // <Button icon={DeleteMinor} size="slim" onClick={() => deleteMedicalSummary(selectedResponse)}></Button>
                    ]);
                    console.log("Symptoms List ====> ", symptomConfig);
                }
                setDocumentListValue(symptomConfig);
            })
            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
                console.log(toSignin);
            })
    }

    // Orders API Submit Body.....
    const prescriptionBody = {
        "patientId": patientid,
        "caseId": caseid,
        "secondOpinionUiSection": "ordersAndPrescriptions",
        "description": prescriptionDescription,
        "patientDocumentId": imageId,
        "startDate": prescriptionStartDate,
        "endDate": prescriptionEndDate
    };

    // Submit Orders & Prescription API
    const prescriptionSubmit = () => {
        console.log(prescriptionBody);
        if (prescriptionDescription === '') {
            setPrescriptionDescriptionError("Please add description");
        }
        else {
            axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/add_patientDetails',
                prescriptionBody, { headers })
                .then(result => {
                    setIsLoading(false);
                    if (result) {
                        setPrescriptionSuccess(result.data.message);
                        handlePrescriptionModalToggle();
                        getSecondOpinionList();
                    }
                })
                .catch((error) => {
                    console.log(error.response);
                    setIsLoading(false);
                })
        }
    }

    // Delete body....
    const deletePrescriptionAPIBody = {
        "caseId": caseid,
        "patientDetailId": patientDetailsId
    }
    // delete api call here ....
    const DeletePrescriptionSubmit = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/delete_patientDetails',
            deletePrescriptionAPIBody, { headers })
            .then((res) => {
                setIsLoading(false);
                setDeletePrescriptionSuccess(res.data.message);
                // getHealthList();
                handleDeletePrescriptionModalChange();
            })

            .catch((error) => {
                setIsLoading(false);
                // console.log("error record====" + error);
            })
    }

    // Question Submit API body
    const questionBody = {
        "caseId": caseid,
        "questionText": questionDescription,
        "questionType": "PatientToDoctor"
    };

    // Question Submit API 
    const questionSubmit = () => {
        console.log(questionBody);
        if (questionDescription === '') {
            setQuestionDescriptionError("Please add question");
        }
        else {
            axios.post(baseUrl.baseUrl + '/questionandanswerapp/api/question/ask_Question',
                questionBody, { headers })
                .then(result => {
                    setIsLoading(false);
                    if (result) {
                        setQuestionDescriptionError("");
                        setQuestionSuccess(result.data.message);
                        handleQuestionModalToggle();
                        getAskedQuestionList();
                    }
                })
                .catch((error) => {
                    console.log(error.response);
                    setQuestionDescriptionError("");
                    setQuestionnError(error.response.data.mesage);
                    setIsLoading(false);
                })
        }
    }

    // Delete body....
    const deleteQuestionAPIBody = {
        "caseId": caseid,
        "patientDetailId": patientDetailsId
    }
    // delete question api call here ....
    const DeleteQuestionSubmit = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/delete_patientDetails',
            deleteQuestionAPIBody, { headers })
            .then((res) => {
                setIsLoading(false);
                setDeleteQuestionSuccess(res.data.message);
                // getHealthList();
                handleDeleteQuestionModalChange();
            })

            .catch((error) => {
                setIsLoading(false);
                // console.log("error record====" + error);
            })
    }

    // Get Question API Body
    const getAskQuestionBody = {
        "caseId": caseid
    }

    // Get Question Answer List
    const getAskedQuestionList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/questionandanswerapp/api/question/list_Question_for_patient',
            getAskQuestionBody, { headers })
            .then((res) => {
                setIsLoading(false);
                let resData = res.data.questionList;
                console.log("Symptom List=======>", resData);
                let askQuestionListValue = [];
                for (var i = 0; i < resData.length; i++) {
                    let questionID = (resData[i].id == null) ? "" : resData[i].id;
                    let questionText = (resData[i].questionText === null || resData[i].questionText === null) ? "" : resData[i].questionText;
                    let questionAskedBy = (resData[i].askedBy === null || resData[i].askedBy === "") ? "" : resData[i].askedBy;
                    let answer = (resData[i].hasOwnProperty('answerText')) ? (resData[i].answerText === null || resData[i].answerText === "") ? "" : truncate(resData[i].answerText) : ""; 
                    let fullAnswer = (resData[i].hasOwnProperty('answerText')) ? (resData[i].answerText === null || resData[i].answerText === "") ? "" : resData[i].answerText : "";
                    let answerBy = (resData[i].answeredBy === null || resData[i].answeredBy === "") ? "" : resData[i].answeredBy;
                    let questionStatus = (resData[i].questionStatus === null || resData[i].questionStatus === "") ? "" : resData[i].questionStatus;
                    setPatientDetailsId(questionID);
                    askQuestionListValue.push([
                        questionText,
                        questionAskedBy,
                        answer,
                        answerBy,
                        questionStatus,
                        <Button icon={EditMinor} size="slim" onClick={() => answerById(questionID, questionText)}>Answer</Button>,
                        <Button icon={AccessibilityMajor} size="slim" onClick={() => viewAnswerById(questionID, questionText, fullAnswer)}>View Answer</Button>
                    ]);
                    console.log("Symptoms List ====> ", askQuestionListValue);
                }
                setAskedQuestionListValue(askQuestionListValue);
            })
            .catch((error) => {
                setIsLoading(false);
                // console.log("error record====" + error);
            })
    }

    // Answer Submit Body
    const answerSubmitBody = {
        "questionId": String(questionId),
        "caseId": caseid,
        "type": "patient",
        "answerText": answerquestion
    };

    // Submit Answer by Question API
    const answerSubmit = () => {
        console.log(answerSubmitBody);
        axios.post(baseUrl.baseUrl + '/questionandanswerapp/api/question/answer_Question',
            answerSubmitBody, { headers })
            .then(result => {
                setIsLoading(false);
                if (result) {
                    setAsnwerSubmitError("");
                    setAnswerSubmitSuccess(result.data.message);
                    getAskedQuestionList();
                }
            })
            .catch((error) => {
                setAnswerSubmitSuccess("");
                setAsnwerSubmitError(error.response.data.message);
                setIsLoading(false);
            })
    }

    // Open Join Meeting URL in New Page
    const joinMeeting = () => {
        window.open(joinUrl);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getAskedQuestionList();
        getMeetingDetails();
        getSecondOpinionList();
    }, []);

    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };


    const telehealthBannerMarkup = telehealthBannerBody
        ?
        <div>
            <Banner>
                <Heading>{telehealthBannerTitle}</Heading>
                <p>{telehealthBannerBody}</p>
                <Box my={2}>
                    <Button primary disabled={isEdit} onClick={joinMeeting}>Join Meeting</Button>
                </Box>
            </Banner>
            <br />
        </div>
        : '';

    // Prescription & Order Table
    const prescriptionTable = (
        <div>
            <Card >
                <Card.Header title="Orders & Prescriptions">
                    <Button primary={true} onClick={handlePrescriptionModalToggle}>Add Documents</Button>
                </Card.Header>
                <Card.Section>
                    {documentListValue && documentListValue.length ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'text',
                                'text'
                            ]}
                            headings={[
                                'Question',
                                'Doctor',
                                'Answer',
                                'Date'
                            ]}
                            rows={documentListValue}
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Orders & Prescriptions Details is available!',
                                },
                            ]}
                        />)}
                </Card.Section>
            </Card>
        </div >
    )

    // Prescription & Order Table
    const addprescriptionDetails = (
        <Modal
            large
            open={prescriptionModalActive}
            onClose={handlePrescriptionModalToggle}
            title={"Add Documents"}
            primaryAction={{
                content: "Submit",
                onAction: prescriptionSubmit,
            }}
        >
            <Modal.Section>
                <Page>
                    <FormLayout>
                        <FormLayout.Group condensed>
                            <div>
                                <TextField
                                    label="Prescription Description"
                                    type="text"
                                    value={prescriptionDescription}
                                    onChange={handlePrescriptionDescriptionChange}
                                    multiline={4}
                                    placeholder="Please enter prescription description"
                                    id="prescriptionDescriptionID"
                                />
                                <InlineError message={prescriptionDescriptionErrordiv} fieldID="prescriptionDescriptionID" />
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Upload supporting document</p>
                                <Box style={{ textAlign: "center", border: "1px #50B83C solid", borderRadius: "3px" }} p={1}>
                                    <Stack distribution="fill">
                                        <Stack>
                                            <Uploader
                                                action="https://aims2health-webserver.herokuapp.com/storageapp/api/upload-image/"
                                                draggable
                                                data={uploadPrescriptionbody}
                                                name="document"
                                                listType="text"
                                                onSuccess={(response: Object,) => {
                                                    let res = JSON.stringify(response);
                                                    setImageId(JSON.parse(res).image.id);
                                                    setImageInfo(JSON.parse(res).image.document);
                                                }}
                                                id='imageUpload'
                                            >
                                                <button style={styles} type="button">
                                                    {uploading && <Loader backdrop center />}
                                                    {imageInfo ? (
                                                        <img src={imageInfo} width="100%" height="100%" alt="" />
                                                    ) : (
                                                        <Thumbnail source={NoteMinor} size="large" alt="Small document" />

                                                    )}
                                                </button>
                                            </Uploader>
                                        </Stack>
                                    </Stack>
                                </Box>
                                <Caption>
                                    <p style={{ color: 'gray' }}>Please upload profile picture of 200px (Width) X 200px (Height)</p>
                                </Caption>
                            </div>
                        </FormLayout.Group>
                        <FormLayout.Group condensed>
                            <div>
                                <p>Start date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={prescriptionStartDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={prescriptionSDate => handlePrescriptionStartChange(prescriptionSDate)}
                                            size="small"
                                            focused
                                            id="startDate"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handlePrescriptionStartChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                            <div>
                                <p>End date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={prescriptionEndDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={prescriptionEDate => handlePrescriptionEndDateChange(prescriptionEDate)}
                                            size="small"
                                            focused
                                            id="endDate"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handlePrescriptionEndDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                        </FormLayout.Group>
                        <div>{prescriptionSuccessDiv}</div>
                        <div>{prescriptionErrorDiv}</div>
                    </FormLayout>
                </Page>
            </Modal.Section>
        </Modal>
    );

    // Prescription Document View
    const prescriptionDocViewerComponent = (
        <Modal
            open={docViwerActive}
            onClose={handleDocViewerChange}
        >
            <Modal.Section>
                <TextContainer>
                    <p>
                        <iframe src={imageInfo} title='Small document' width='100%' height='700' frameBorder='0'>This is an embedded <a target='_blank' href='http://office.com' rel="noopener noreferrer">Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps' rel="noopener noreferrer">Office Online</a>.</iframe>
                    </p>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );

    // Delete Prescription Modal section ....
    const deletePrescriptionBody = (
        <Modal
            open={deletePrescriptionModalActive}
            onClose={handleDeletePrescriptionModalChange}
            title={" Delete Second Opinion Details"}
            primaryAction={{
                content: "Yes",
                onAction: DeletePrescriptionSubmit,
            }}
            secondaryActions={[
                {
                    content: 'No',
                    onAction: handleDeletePrescriptionModalChange,
                },
            ]}
            footer={successDeleteProblemModalDiv}
        >
            <Modal.Section>
                <TextContainer>
                    <Subheading>
                        Are you sure, you want to delete this Second Opinion Details ?
                    </Subheading>
                </TextContainer>
            </Modal.Section>
            {deleteQuestionSuccessDiv}
        </Modal>
    );

    // Question Table
    const questionTableBody = (
        <div>
            <Card >
                <Card.Header title="Question & Answers">
                    <Button primary={true} onClick={handleQuestionModalToggle}>Ask Question</Button>
                </Card.Header>
                <Card.Section>
                    {askedQuestionListValue && askedQuestionListValue.length ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'text',
                                'text',
                                'text',
                                'text',
                                'text'
                            ]}
                            headings={[
                                'Description',
                                'Asked By',
                                'Answer',
                                'Answered By',
                                'Status',
                                'Submit Answer',
                                'View Full Answer'

                            ]}
                            rows={askedQuestionListValue}
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Question Details is available!',
                                },
                            ]}
                        />)}
                </Card.Section>
            </Card>
            <br />
        </div >
    )

    // Questionn Modal Body
    const questionModalBody = (
        <Modal
            large
            open={questionModalActive}
            onClose={handleQuestionModalToggle}
            title={"Add Question"}
            primaryAction={{
                content: "Submit",
                onAction: questionSubmit,
            }}
        >
            <Modal.Section>
                <Page>
                    <FormLayout>
                        <FormLayout.Group condensed>
                            <div>
                                <TextField
                                    label="Question"
                                    type="text"
                                    value={questionDescription}
                                    onChange={handleQuestionDescriptionChange}
                                    multiline={4}
                                    placeholder="Please enter your question"
                                    id="questionDescriptionID"
                                />
                                <InlineError message={questionDescriptionErrordiv} fieldID="questionDescriptionID" />
                            </div>
                        </FormLayout.Group>
                        <div>{questionSuccessDiv}</div>
                        <div>{questionErrorDiv}</div>
                    </FormLayout>
                </Page>
            </Modal.Section>
        </Modal>
    );

    const QuestionDocViewerComponent = (
        <Modal
            open={questionDocViwerActive}
            onClose={handleQuestionDocViewerChange}
        >
            <Modal.Section>
                <TextContainer>
                    <p>
                        <iframe src={questionImageInfo} title='Small document' width='100%' height='700' frameBorder='0'>This is an embedded <a target='_blank' href='http://office.com' rel="noopener noreferrer">Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps' rel="noopener noreferrer">Office Online</a>.</iframe>
                    </p>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );
    // Delete Question Modal section ....
    const deleteQuestionBody = (
        <Modal
            open={deleteQuestionModalActive}
            onClose={handleDeleteQuestionModalChange}
            title={" Delete Second Opinion Details"}
            primaryAction={{
                content: "Yes",
                onAction: DeleteQuestionSubmit,
            }}
            secondaryActions={[
                {
                    content: 'No',
                    onAction: handleDeleteQuestionModalChange,
                },
            ]}
            footer={successDeleteProblemModalDiv}
        >
            <Modal.Section>
                <TextContainer>
                    <Subheading>
                        Are you sure, you want to delete this Second Opinion Details ?
        </Subheading>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );

    // Add Answer Submit section
    const answerQuestionComponent = (
        <div>
            <Modal
                large
                open={answerModalActive}
                onClose={handleAnswerModalActive}
                title="Add Answer"
                primaryAction={{
                    content: 'Submit',
                    onAction: answerSubmit,
                }}
            >
                <Modal.Section>
                    <TextStyle variation="strong">Question: {question}</TextStyle>
                </Modal.Section>
                <Modal.Section>
                    <TextField
                        label="Answer"
                        value={answerquestion}
                        onChange={handleAnswerQuestionChange}
                        multiline={4}
                    />
                    <br />
                    <div>{errorAnswerSubmitDiv}</div>
                    <div>{successAnswerSubmitDiv}</div>
                </Modal.Section>
            </Modal>
        </div>
    )

    const viewAnswerComponent = (
        <div>
            <Modal
                large
                open={viewAnswerModalActive}
                onClose={handleViewAnswerModalActive}
                title="View Answer"
                primaryAction={{
                    content: 'Close',
                    onAction: handleViewAnswerModalActive,
                }}
            >
                <Modal.Section>
                    <TextStyle variation="strong">Question: {question}</TextStyle>
                </Modal.Section>
                <Modal.Section>
                    <TextField
                        label="Answer"
                        value={answer}
                        onChange={handleAnswerQuestionChange}
                        multiline={4}
                        readOnly
                    />
                    <br />
                    <div>{errorAnswerSubmitDiv}</div>
                    <div>{successAnswerSubmitDiv}</div>
                </Modal.Section>
            </Modal>
        </div>
    )

    return (
        <Page fullWidth>
            {toSignin ? <Redirect to="/signin" /> : null}
            {loadingMarkup}
            {telehealthBannerMarkup}
            {prescriptionTable}
            <br />
            {prescriptionDocViewerComponent}
            {addprescriptionDetails}
            {deletePrescriptionBody}
            {questionTableBody}
            {questionModalBody}
            {QuestionDocViewerComponent}
            {deleteQuestionBody}
            {toastSuccessMarkup}
            {toastErrorMarkup}
            {answerQuestionComponent}
            {viewAnswerComponent}
        </Page>
    );
}