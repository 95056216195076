import React, { useEffect } from 'react';
import {
    Button,
    Card,
    Page,
    ExceptionList,
    DescriptionList,
    Loading,
} from '@shopify/polaris';
import { NoteMinor } from '@shopify/polaris-icons';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../redux/store/Store';
import { TaskList } from '../../../redux/interfaces/Model';
import { getTaskList } from '../../../redux/effects/ApiCall';
import * as _ from 'lodash';
export default function TaskDetails() {
    const loadingMarkup = <Loading />;
    const dispatch = useDispatch();
    const { rowIndex } = useParams() as {
        rowIndex: string,
    };


    const history = useHistory();
    // Call Store
    const taskReduxValue = useSelector((state: AppState) => state.taskValue);
    const taskDetails: TaskList = _.filter(taskReduxValue.taskValue, ['id', Number(rowIndex)])[0];
    
    function gotoCaseDetails() {
        const paymentRedirect = Number(0);
        localStorage.setItem("paymentRedirect", `${paymentRedirect}`);
        history.push(`/filepreparation/${taskDetails.patientId}/${taskDetails.caseId}/${paymentRedirect}`);
    }
    function gotoPayment() {
        const paymentRedirect = Number(1);
        localStorage.setItem("paymentRedirect", `${paymentRedirect}`);
        history.push(`/filepreparation/${taskDetails.patientId}/${taskDetails.caseId}/${paymentRedirect}`);
        localStorage.setItem("paymentRedirect", `${paymentRedirect}`);
    }

    useEffect(() => {
        dispatch(getTaskList());
        localStorage.removeItem("paymentRedirect");
    }, [dispatch]);

    const taskDetailsView = (
        <Card>
            <Card.Header>
                <Button primary={false} onClick={gotoPayment}>Go for Payment</Button>
                <Button primary={true} onClick={gotoCaseDetails}>View Case Details</Button>
            </Card.Header>
            <Card.Section>
                {taskDetails ? (
                    <DescriptionList
                        items={[
                            {
                                term: 'Title',
                                description: `${taskDetails.title}`
                            },
                            {
                                term: 'Patient Name',
                                description: `${taskDetails.patientName}`
                            },
                            {
                                term: 'Problem Name',
                                description: `${taskDetails.whyWant2ndOpinion}`
                            },
                            {
                                term: 'Reason for 2ndOpinion',
                                description: `${taskDetails.secondOpinionReasons}`
                            },
                            {
                                term: 'Consultation Type',
                                description: `${taskDetails.consultationType}`
                            },
                            {
                                term: 'Note',
                                description: `${taskDetails.note}`
                            },
                            {
                                term: 'Create Date',
                                description: `${taskDetails.created_date}`
                            },
                            {
                                term: 'Due Date',
                                description: `${taskDetails.due_date}`
                            },
                            {
                                term: 'Completed',
                                description: `${taskDetails.completed}`
                            },
                        ]}
                    />
                ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Task Details is available!',
                                },
                            ]}
                        />)}


            </Card.Section>
        </Card >
    )

    return (
        <Page fullWidth title="Task Details">
            {taskDetailsView}
            {taskReduxValue.loading ? loadingMarkup : ''}
        </Page>
    );
}
