import React, { useState, useCallback } from 'react';
import logo from '../../image/logo/aims2health-with-caption.png';
import LoginScreen from '../../image/design-assets/featureimage-patient.svg';
import Loginwithotpcomponent from './Loginwithotpcomponent'
import Loginwithpasswordcomponent from './Loginwithpasswordcomponent'
import { Box, Flex, } from '@primer/components';
import {
  DisplayText,
  Tabs,
  Layout,
  Page,
  TextStyle,
  Caption,
  Stack,
  Link,
  Button
} from '@shopify/polaris';
import { Divider } from 'rsuite';

interface AccountProps {
  onAction(): void;
}

export default function Login() {

  const [selected, setSelected] = useState(0);
  const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), []);

  const tabs = [
    {
      id: 'emaillogin',
      content: 'Sign in with OTP',
      body: (
        <Loginwithotpcomponent />
      )
      ,
      panelID: 'emaillogin-content',
    },
    {
      id: 'passwordlogin',
      content: 'Sign in with Password',
      body: (
        <Loginwithpasswordcomponent />
      ),
      accessibilityLabel: 'All customers',
      panelID: 'passwordlogin-content',
    },

  ];

  const actualPageMarkup = (
    <Layout>
      <Layout.Section oneHalf>
        <Flex alignItems="center" justifyContent="center">
          <Box m={4}>
            <Link url="https://aims2health.com">
              <img src={logo} alt="Logo" height="60" />
            </Link>
            <Box my={3}>
              <DisplayText size="large">Welcome to AIMS2Health</DisplayText>
              <p>2<sup>nd</sup> opinion from Ex-AIIMS Specialist Doctors</p>
            </Box>
            <Divider></Divider>
            <TextStyle variation="strong">New User?{' '}&nbsp;&nbsp;&nbsp;
              <Button url='/signup'>Register</Button>
            </TextStyle>
            <Divider><b>Old User?</b></Divider>
            <Box my={1}>
              <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} fitted>
                <Box py={3}>{tabs[selected].body}</Box>
              </Tabs>
            </Box>
          </Box>
        </Flex>
      </Layout.Section>
      <Layout.Section oneHalf>
        <Box pt={5} mt={5}>
          <img
            src={LoginScreen}
            alt=""
            width="100%"
            height="100%"
            style={{
              objectFit: 'cover',
              objectPosition: 'center'
            }}
          />
        </Box>
      </Layout.Section>
      <Layout.Section>
        <Stack>
          <Stack.Item fill>
            <Caption>© Copyright AIMS2Health 2021. All rights reserved.</Caption>
          </Stack.Item>
          <Stack.Item>
            <Caption>
              <Link url="https://aims2health.com/termsofuse.html" external>Terms of Use</Link>&nbsp;&nbsp;
            <Link url="https://aims2health.com/privacy-statement.html" external>Privacy & Cookies</Link>
            </Caption>
          </Stack.Item>
        </Stack>
      </Layout.Section>
    </Layout>
  );

  return (
    <Page fullWidth>
      {actualPageMarkup}
    </Page>
  );
}