import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
    Banner,
    Layout,
    Loading,
    RadioButton,
    FormLayout,
    Page,
    ChoiceList,
    TextField,
    Card,
    List,
    Collapsible,
} from '@shopify/polaris';
import { Box } from '@primer/components';
import axios from 'axios';
import baseUrl from '../../Url';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getEncounterList } from '../../redux/effects/ApiCall';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    }
}));


export default function ConsultationOption() {
    const { patientid } = useParams() as {
        patientid: string;
    };
    const { caseid } = useParams() as {
        caseid: string;
    };

    const history = useHistory();

    const classes = useStyles();

    const dispatch = useDispatch();

    const [secondOpinionRes, setSecondOpinionRes] = useState("Why do you want 2nd opinion?");

    const [cardHeaderDefaultTitle, setCardHeaderDefaultTitle] = useState("");

    const [cardHeader2ndOpinionTitle, setCardHeader2ndOpinionTitle] = useState("");

    const [cardHeader1stOpinionTitle, setCardHeader1stOpinionTitle] = useState("");

    const [opinionListValue, setopinionListValue] = useState<any[]>([]);

    const [consultationListValue, setconsultationListValue] = useState<any[]>([]);

    const [responseTimeListValue, setresponseTimeListValue] = useState<any[]>([]);

    const [consultationTypeId, setConsultationTypeId] = useState(0);
    const [responseTimeTypeId, setResponseTimeTypeId] = useState(0);

    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;

    const userToken = localStorage.getItem("token");

    const [isOpinionValue, setIsOpinionValue] = useState(0);

    const [selectfirstopinion, setIsselectfirstopinion] = useState(false);

    const [responseTimeCollaps, setresponseTimeCollaps] = useState(true);

    const [isEdit, setIsEdit] = useState(true);
    const [isEnableSubmit, setEnableSubmit] = useState(true);

    const [isconsultationTypeEdit, setIsconsultationTypeEdit] = useState(true);
    const [isConsultationEnableSubmit, setConsultationEnableSubmit] = useState(true);

    const [isresponseTimeEdit, setIsresponseTimeEdit] = useState(true);
    const [isResponseEnableSubmit, setResponseEnableSubmit] = useState(true);

    const [isResponseAccordian, setResponseEnableAccordian] = useState(true);

    const [getConsultationTypeValue, setConsultationTypeValue] = useState('');
    const [getResponseTimeValue, setResponseValue] = useState('');

    const [choiceListOpen, setChoiceListOpen] = useState(false);//

    // Desription Text
    const [reasonModalText, setReasonModalText] = useState('');
    const handleReasonModalTextChange = useCallback((value) => setReasonModalText(value), []);

    const isSecondOpinionValueChange = useCallback((_checked, newValue) => {
        setIsOpinionValue(newValue);
        if (newValue === 2) {
            // First Opinion //
            setChoiceListOpen(false);
            setReasonModalText('');
            setSecondOpinionRes("What is your main complaint?");
            localStorage.setItem("opiniontype", newValue);
        }
        else if (newValue === 1) {
            // Second Opinion //
            setIsselectfirstopinion(false);
            setEnableSubmit(false)
            setChoiceListOpen(true);
            setSecondOpinionRes("Why do you want 2nd opinion?");
            localStorage.setItem("opiniontype", newValue);
        }
    }, []);

    // Checkbox selected value
    const [selected, setSelected] = useState<any[]>([]);
    const handleChange = useCallback((value) => {
        setSelected(value);
    }, []);

    const [addEncounterSuccess, setAddEncounterSuccess] = useState('');
    const closeSuccess = () => {
        setAddEncounterSuccess('');
        setadderror('');
    }

    const successDiv = addEncounterSuccess
        ? <Banner
            onDismiss={closeSuccess}
            status="success">
            <p>{addEncounterSuccess}</p>
        </Banner>
        : '';

    const [adderror, setadderror] = useState('');
    const errorDiv = adderror
        ? <Banner
            onDismiss={closeSuccess}
            status="critical">
            <p>{adderror}</p>
        </Banner>
        : '';

    const closeConsultationBanner = () => {
        setAddConsultationSuccess('');
        setAddConsultationError('');
    }

    const [addConsultationSuccess, setAddConsultationSuccess] = useState('');
    const successconsultationDiv = addConsultationSuccess
        ? <Banner
            onDismiss={closeConsultationBanner}
            status="success">
            <p>{addConsultationSuccess}</p>
        </Banner>
        : '';

    const [addConsultationrror, setAddConsultationError] = useState('');
    const errorconsultationDiv = addConsultationrror
        ? <Banner
            onDismiss={closeConsultationBanner}
            status="critical">
            <p>{addConsultationrror}</p>
        </Banner>
        : '';

    const closeResponseTimeBanner = () => {
        setAddResponseTimeSuccess('');
        setAddResponseTimeError('');
    }

    const [addResponseSuccess, setAddResponseTimeSuccess] = useState('');
    const successresponsetimeDiv = addResponseSuccess
        ? <Banner
            onDismiss={closeResponseTimeBanner}
            status="success">
            <p>{addResponseSuccess}</p>
        </Banner>
        : '';

    const [addResponseTimerror, setAddResponseTimeError] = useState('');
    const errorresponseTimeDiv = addResponseTimerror
        ? <Banner
            onDismiss={closeResponseTimeBanner}
            status="critical">
            <p>{addResponseTimerror}</p>
        </Banner>
        : '';

    // Consultation type radio button value
    const [value, setValue] = useState('Comprehensive ( Recommended )');
    const checkhandleChange = useCallback(
        (_checked, newValue) => {
            setValue(newValue);
            console.log("Consultation RADIOVALUE==>", newValue);
            if (newValue === 'Comprehensive ( Recommended )') {
                setConsultationTypeId(1);
                localStorage.setItem("consultationopt", newValue);
            }
            else if (newValue === 'Medical Report Based') {
                setConsultationTypeId(2);
                localStorage.setItem("consultationopt", newValue);
            }
            else {
                setConsultationTypeId(3);
                localStorage.setItem("consultationopt", newValue);
            }
        }, [],
    );

    // Response Time radio button value
    const [responseTimeValue, setResponseTimeValue] = useState('');
    const checkhandleResponseChange = useCallback(
        (_checked, newValue) => {
            setResponseTimeValue(newValue);
            if (newValue === 'Regular Consult (First response by a doctor in 72 Hours)') {
                setResponseTimeTypeId(1);
            }
            else if (newValue === 'Speedy Consult (First response by a doctor in 8 working hours)') {
                setResponseTimeTypeId(2);
                localStorage.setItem("responseTime", newValue);
            }
        }, [],
    );

    const body = JSON.stringify({
        caseId: caseid,
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        getOpinionTypeList();
        getSecondOpinionList();
        getConsultationList();
        getcaseDetails();
        setConsultationTypeId(1);
        setIsOpinionValue(1);
        setChoiceListOpen(true);
        if (caseid) {
            setresponseTimeCollaps(true);
            setResponseEnableAccordian(false)
        }
        else {
            setresponseTimeCollaps(true);
        }
    }, []);

    // GET Case details by ID
    const getcaseDetails = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patient/patient_caseDetails_by_id',
            body, { headers })
            .then(result => {
                setIsLoading(false);
                getResponseTimeList();
                setIsOpinionValue(result.data.patientCaseDetail.opinionType);
                let resonlabel = [] as any;
                let reasonvalue = [] as any;
                let secondopiniovalue = result.data.patientCaseDetail.secondOpinionReasons;
                for (var i = 0; i < secondopiniovalue.length; i++) {
                    resonlabel.push(secondopiniovalue[i].label);
                    reasonvalue.push(secondopiniovalue[i].value);
                }
                setSelected(reasonvalue);
                setReasonModalText(result.data.patientCaseDetail.whyWant2ndOpinion);
                setValue(result.data.patientCaseDetail.consultationType.label);
                setConsultationTypeValue(result.data.patientCaseDetail.consultationType.label);
                if (result.data.patientCaseDetail.responseTimeType.length > 0) {
                    setResponseTimeValue('');
                    setResponseTimeValue(result.data.patientCaseDetail.responseTimeType[0].label);
                    setResponseValue(result.data.patientCaseDetail.responseTimeType);
                }
            })
            .catch((error) => {
                setIsLoading(false);
            })
    }

    // Get opinion type list
    const getOpinionTypeList = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/secondopinionapp/api/secondopinion/opinion_type', {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Token ${userToken} `
            }
        })
            .then((res) => {
                setIsLoading(false);
                let opinionList: Array<{ value: number, label: string, question: string }> = [];
                if (res.data.opinionTypeList.length > 0) {
                    let values = res.data.opinionTypeList;
                    setIsLoading(false);
                    values.forEach((element: { value: any, label: any, question: any }) => {
                        opinionList.push({
                            value: element.value,
                            label: element.label,
                            question: element.question
                        });
                    });
                    setCardHeaderDefaultTitle(opinionList[0].question);
                    setCardHeader2ndOpinionTitle(opinionList[0].question);
                    setCardHeader1stOpinionTitle(opinionList[1].question);
                }
                setopinionListValue(opinionList);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            })
    }

    // Get 2nd opinion list
    const [secondOpinionrequested, setSecondOpinionRequested] = useState<any[]>([]);
    const getSecondOpinionList = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/secondopinionapp/api/secondopinion/second_opinion_option', {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Token ${userToken} `
            }
        })
            .then((res) => {
                setIsLoading(false);
                let response = res.data.secondOpinionList;
                setSecondOpinionRequested(response);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            })
    }

    // GET consultation type list
    const getConsultationList = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/secondopinionapp/api/consultationtype/consultation_type', {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Token ${userToken} `
            }
        })
            .then((res) => {
                setIsLoading(false);
                let consultationList: Array<{ id: number, title: string, navigateToStep: string, navigateToSection: string, description: string }> = [];
                if (res.data.consulatationTyeList.length > 0) {
                    let values = res.data.consulatationTyeList;
                    setIsLoading(false);
                    values.forEach((element: { id: any, title: string, navigateToStep: string, navigateToSection: string, description: string }) => {
                        consultationList.push({
                            id: element.id,
                            title: element.title,
                            navigateToStep: element.navigateToStep,
                            navigateToSection: element.navigateToSection,
                            description: element.description
                        });
                    });
                }
                setconsultationListValue(consultationList);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            })
    }

    // GET respose time list
    const getResponseTimeList = () => {
        let url;
        url = baseUrl.baseUrl + '/patientapp/api/patientMedicalData/responseTime_typeList';
        setIsLoading(true);
        axios.post(url, { "caseId": caseid }, { headers })
            .then((res) => {
                setIsLoading(false);
                let responseTimeList: Array<{ value: number, label: string }> = [];
                if (res.data.responseTimeTypeList.length > 0) {
                    let values = res.data.responseTimeTypeList;
                    values.forEach((element: { value: any, label: string }) => {
                        responseTimeList.push({
                            value: element.value,
                            label: element.label
                        });
                    });
                }
                setresponseTimeListValue(responseTimeList);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            })
    }

    const editResponseTimeApiCall = () => {
        getResponseTimeList();
        setIsresponseTimeEdit((isresponseTimeEdit) => !isresponseTimeEdit);
        setResponseEnableSubmit((isResponseEnableSubmit) => !isResponseEnableSubmit);
    }

    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    const newBody = {
        "patientId": patientid,
        "whyWant2ndOpinion": reasonModalText,
        "secondOpinionReasons": selected,
        "opinionTypeId": isOpinionValue
    };

    // Add first card value
    const secondOpinionDescriptionModalSubmit = () => {
        if (selected.length === 0 && isOpinionValue === 1) {
            setadderror('Please select 2nd Opinion request');
        }
        else if (reasonModalText === '') {
            setadderror('Please specify the reason(s)');
        }
        else {
            let url, newcaseBody;
            if (caseid) {
                url = baseUrl.baseUrl + '/patientapp/api/patient/edit_patient_case';
                newcaseBody = Object.assign(newBody, { caseId: caseid })
            }
            else {
                url = baseUrl.baseUrl + '/patientapp/api/patient/add_patient_case';
            }
            setIsLoading(true);
            axios.post(url, JSON.stringify(newBody), { headers })
                .then(result => {
                    setIsLoading(false);
                    if (result) {
                        setAddEncounterSuccess(result.data.message);
                        let patientcaseId = result.data.patientCaseId;
                        history.push(`/filepreparation/${patientid}/${patientcaseId}`);
                        dispatch(getEncounterList(patientid));
                        // window.location.reload();
                    }
                }).catch((error) => {
                    setadderror(error.response.data.message);
                    console.log(error.response);
                    setIsLoading(false);
                });
        }
    }

    // Add consultation type
    const addConsultation = () => {
        let consultationurl, newcaseBody;
        consultationurl = baseUrl.baseUrl + '/patientapp/api/patient/edit_patient_case';
        newcaseBody = Object.assign({ caseId: caseid, consultationTypeId: consultationTypeId })
        setIsLoading(true);
        axios.post(consultationurl, JSON.stringify(newcaseBody), { headers })
            .then(result => {
                setIsLoading(false);
                if (result) {
                    setAddConsultationSuccess(result.data.message);
                    getResponseTimeList();
                    setresponseTimeCollaps(true);
                    setIsconsultationTypeEdit(true);
                    setConsultationEnableSubmit(true);
                    setResponseEnableAccordian(false);
                    getcaseDetails();
                }
            })
            .catch((error) => {
                setAddConsultationError(error.response.data.message);
                console.log(error.response);
                setIsLoading(false);
            });
    }

    // Add response time
    const addResponseTime = () => {
        let responseTimeurl, newresponseTimeBody;
        responseTimeurl = baseUrl.baseUrl + '/patientapp/api/patient/edit_patient_case';
        newresponseTimeBody = Object.assign({ caseId: caseid, responseTimeTypeId: responseTimeTypeId })
        setIsLoading(true);
        axios.post(responseTimeurl, JSON.stringify(newresponseTimeBody), { headers })
            .then(result => {
                setIsLoading(false);
                if (result) {
                    setAddResponseTimeSuccess(result.data.message);
                    getcaseDetails();
                    setIsresponseTimeEdit(true);
                    setResponseEnableSubmit(true);
                }
            })
            .catch((error) => {
                setadderror(error.response.data.message);
                console.log(error.response);
                setIsLoading(false);
            });
        // }
    }

    // Select 1st opinion or 2nd opinion
    const symptomsAddInfo = (
        <Layout>
            <Layout.Section>
                {caseid ? (
                    <Card sectioned secondaryFooterActions={[{
                        content: isEdit ? 'Edit' : 'Cancel', onAction: () => {
                            setIsEdit((isEdit) => !isEdit);
                            setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
                        }
                    }]}
                        primaryFooterAction={{ content: "Update", onAction: secondOpinionDescriptionModalSubmit, disabled: isEnableSubmit }}
                    >
                        <Card.Section>
                            <FormLayout>
                                <FormLayout.Group >
                                    {opinionListValue.map((opinion) => (
                                        <RadioButton
                                            label={<Box><h5>{opinion.label}</h5></Box>}
                                            id={opinion.value}
                                            name="category"
                                            checked={isOpinionValue === opinion.value}
                                            onChange={isSecondOpinionValueChange}
                                            disabled={isEdit}
                                        />
                                    ))}
                                </FormLayout.Group>
                            </FormLayout>
                        </Card.Section>

                        {isOpinionValue === 1 ? (
                            <div>
                                <Collapsible
                                    open={choiceListOpen}
                                    id="basic-collapsible"
                                    transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                                    expandOnPrint
                                >
                                    <Card.Header title="What type of 2nd Opinion is requested?">
                                    </Card.Header>
                                    <Card.Section>
                                        <FormLayout>
                                            <ChoiceList
                                                allowMultiple
                                                title=""
                                                choices={secondOpinionrequested}
                                                selected={selected}
                                                onChange={handleChange}
                                                disabled={isEdit}
                                            />
                                        </FormLayout>
                                    </Card.Section>
                                </Collapsible>
                            </div>
                        ) : (
                            <div></div>
                        )}

                        {isOpinionValue === 1 ? (
                            <Card.Header title={cardHeader2ndOpinionTitle}>
                            </Card.Header>
                        ) : (
                            <Card.Header title={cardHeader1stOpinionTitle}>
                            </Card.Header>
                        )}

                        <Card.Section >
                            <FormLayout>
                                <FormLayout.Group >
                                    <div>
                                        <label>
                                            <i>
                                                <b>
                                                    What is the cause of your anxiety, concern, fear, worry, or stress.
                                                </b>
                                            </i>
                                        </label>
                                        <TextField
                                            value={reasonModalText}
                                            onChange={handleReasonModalTextChange}
                                            label=""
                                            type="text"
                                            placeholder="Please explain in your own words"
                                            id="description"
                                            readOnly={isEdit}
                                        />
                                    </div>
                                </FormLayout.Group>
                                <div>{successDiv}</div>
                                <div>{errorDiv}</div>
                            </FormLayout>
                        </Card.Section>
                    </Card>
                ) : (
                    <Card sectioned primaryFooterAction={{ content: "Add", onAction: secondOpinionDescriptionModalSubmit }}>
                        <Card.Section>
                            <FormLayout>
                                <FormLayout.Group >
                                    {opinionListValue.map((opinion) => (
                                        <RadioButton
                                            label={<Box><h5>{opinion.label}</h5></Box>}
                                            id={opinion.value}
                                            name="category"
                                            checked={isOpinionValue === opinion.value}
                                            onChange={isSecondOpinionValueChange}
                                        />
                                    ))}
                                </FormLayout.Group>
                            </FormLayout>
                        </Card.Section>

                        <Collapsible
                            open={choiceListOpen}
                            id="basic-collapsible"
                            transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                            expandOnPrint
                        >
                            <Card.Header title="What type of 2nd Opinion is requested?">
                            </Card.Header>
                            <Card.Section>
                                <FormLayout>
                                    <ChoiceList
                                        allowMultiple
                                        title=""
                                        choices={secondOpinionrequested}
                                        selected={selected}
                                        onChange={handleChange}
                                        disabled={selectfirstopinion}
                                    />
                                </FormLayout>
                            </Card.Section>
                        </Collapsible>

                        <Card.Header title={secondOpinionRes}>
                        </Card.Header>
                        <Card.Section>
                            <FormLayout>
                                <FormLayout.Group >
                                    <div>
                                        <label>
                                            <i>
                                                <b>
                                                    What is the cause of your anxiety, concern, fear, worry, or stress.
                                                </b>
                                            </i>
                                        </label>
                                        <TextField
                                            value={reasonModalText}
                                            onChange={handleReasonModalTextChange}
                                            label=""
                                            type="text"
                                            placeholder="Please explain in your own words"
                                            id="description"
                                            readOnly={selectfirstopinion}
                                        />
                                    </div>
                                </FormLayout.Group>
                                <div>{successDiv}</div>
                                <div>{errorDiv}</div>
                            </FormLayout>
                        </Card.Section>
                    </Card>
                )}
            </Layout.Section>
        </Layout>
    )

    // Consultation type card.............
    const consultationInfo = (
        <Layout>
            <Layout.Section>
                {getConsultationTypeValue ? (
                    <Box my={2}>
                        <Card sectioned secondaryFooterActions={[{
                            content: isconsultationTypeEdit ? 'Edit' : 'Cancel', onAction: () => {
                                setIsconsultationTypeEdit((isconsultationTypeEdit) => !isconsultationTypeEdit);
                                setConsultationEnableSubmit((isConsultationEnableSubmit) => !isConsultationEnableSubmit);
                            }
                        }]}
                            primaryFooterAction={{ content: "Update", onAction: addConsultation, disabled: isConsultationEnableSubmit }}
                        >
                            <Card.Section>
                                <FormLayout>
                                    <FormLayout.Group condensed>
                                        {consultationListValue.map((consultation) => (
                                            <RadioButton
                                                label={<Box><h5>{consultation.title}</h5></Box>}
                                                helpText={<Box mt={3}><Banner>
                                                    <List>{consultation.description}</List>
                                                </Banner></Box>}
                                                checked={value === consultation.title}
                                                id={consultation.title}
                                                name="consultation"
                                                onChange={checkhandleChange}
                                                disabled={isconsultationTypeEdit}
                                            />
                                        ))}
                                    </FormLayout.Group>
                                    <div>{successconsultationDiv}</div>
                                    <div>{errorconsultationDiv}</div>
                                </FormLayout>
                            </Card.Section>
                        </Card>
                    </Box>
                ) : (
                    <Box my={2}>
                        <Card sectioned primaryFooterAction={{ content: "Select", onAction: addConsultation }}>
                            <Card.Section>
                                <FormLayout>
                                    <FormLayout.Group condensed>
                                        {consultationListValue.map((consultation) => (
                                            <RadioButton
                                                label={<Box><h5>{consultation.title}</h5></Box>}
                                                helpText={<Box mt={3}><Banner>
                                                    <List>
                                                        <List.Item>{consultation.description}</List.Item>
                                                    </List>
                                                </Banner></Box>}
                                                checked={value === consultation.title}
                                                id={consultation.title}
                                                name="consultation"
                                                onChange={checkhandleChange}
                                                disabled={selectfirstopinion}
                                            />
                                        ))}
                                    </FormLayout.Group>
                                    <div>{successconsultationDiv}</div>
                                    <div>{errorconsultationDiv}</div>
                                </FormLayout>
                            </Card.Section>
                        </Card>
                    </Box>
                )
                }
            </Layout.Section>
        </Layout>
    )

    // Last card Response time card.........................
    const responseInfoBody = (
        <Collapsible
            open={responseTimeCollaps}
            id="basic-collapsible"
            transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
            expandOnPrint
        >
            <Layout>
                <Layout.Section>
                    {getResponseTimeValue ? (
                        <Box my={2}>
                            <Card sectioned secondaryFooterActions={[{
                                content: isresponseTimeEdit ? 'Edit' : 'Cancel', onAction: editResponseTimeApiCall
                            }]}
                                primaryFooterAction={{ content: "Update", onAction: addResponseTime, disabled: isResponseEnableSubmit }}
                            >
                                <Card.Section>
                                    <FormLayout>
                                        <FormLayout.Group condensed>
                                            {responseTimeListValue.map((responsetime) => (
                                                <RadioButton
                                                    label={<Box><h5>{responsetime.label}</h5></Box>}
                                                    checked={btoa(responseTimeValue) === btoa(responsetime.label)}
                                                    id={responsetime.label}
                                                    name="responsetime"
                                                    onChange={checkhandleResponseChange}
                                                    disabled={isresponseTimeEdit}
                                                />
                                            ))}
                                        </FormLayout.Group>
                                        <div>{successresponsetimeDiv}</div>
                                        <div>{errorresponseTimeDiv}</div>
                                    </FormLayout>
                                </Card.Section>
                            </Card>
                        </Box>
                    ) : (
                        <Box my={2}>
                            <Card sectioned primaryFooterAction={{ content: "Select", onAction: addResponseTime }}>
                                <Card.Section>
                                    <FormLayout>
                                        <FormLayout.Group condensed>
                                            {responseTimeListValue.map((responsetime) => (
                                                <RadioButton
                                                    label={<Box><h5>{responsetime.label}</h5></Box>}
                                                    checked={btoa(responseTimeValue) === btoa(responsetime.label)}
                                                    id={responsetime.label}
                                                    name="responsetime"
                                                    onChange={checkhandleResponseChange}
                                                    disabled={selectfirstopinion}
                                                />
                                            ))}
                                        </FormLayout.Group>
                                        <div>{successresponsetimeDiv}</div>
                                        <div>{errorresponseTimeDiv}</div>
                                    </FormLayout>
                                </Card.Section>
                            </Card>
                        </Box>
                    )
                    }
                </Layout.Section>
            </Layout>
        </Collapsible>
    )

    const accordian = (
        <div className={classes.root}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="symptoms-content"
                    id="symptoms-header"
                >
                    <Typography variant="h6">What brings you to AIMS2Health?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        {symptomsAddInfo}
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="consult-content"
                    id="consult-header"
                >
                    <Typography variant="h6">What type of Consultation do you want?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        {consultationInfo}
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion disabled={isResponseAccordian}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="res-timing-content"
                    id="res-timing-header"
                >
                    <Typography variant="h6">Response Timing</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        {responseInfoBody}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );


    return (
        <Page fullWidth>
            {loadingMarkup}
            {accordian}
        </Page>
    );
}