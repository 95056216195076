import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import baseUrl from '../../../Url';

import {
    Banner,
    Button,
    FormLayout,
    InlineError,
    Loading,
    TextField,
    TextStyle,
    Modal,
    Spinner,
    Form,
    Stack,
} from '@shopify/polaris';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles, Theme, createStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { InputLabel } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        margin: {
            margin: theme.spacing(2),
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
    }),
);

const useOutlinedInputStyles = makeStyles(theme => ({
    root: {
        "& $notchedOutline": {
            borderColor: "#50B83C",
            outlineWidth: 0,
        },
        "&:hover $notchedOutline": {
            borderColor: "#50B83C",
            outlineWidth: 0,
        },
        "&$focused $notchedOutline": {
            borderColor: "#50B83C",
            outlineWidth: 0,
        },
        height: "38px",
        background: "white"
    },
    input: {
        "&::placeholder": {
            color: "#50B83C"
        },
        color: "#00000", // if you also want to change the color of the input, this is the prop you'd use   
    },
    focused: {},
    notchedOutline: {}
}));

export default function Loginwithpasswordcomponent() {
    const theme = createMuiTheme({
        palette: {
            primary: {
                main: "#50B83C",
            },
            secondary: {
                main: '#330101',
            },
            text: {
                primary: "#000000",
                secondary: "#50B83C"
            }
        },
        typography: {
            fontSize: 22,
        },
        overrides: {
            MuiInputBase: {
                input: {
                    '&:-webkit-autofill': {
                        transitionDelay: '9999s',
                        transitionProperty: 'background-color, color',
                    },
                },
            },
        },

    });
    const classes = useStyles();
    const outlinedInputClasses = useOutlinedInputStyles();
    //const [password, setPass] = useState('');
    const [email, setEmail] = useState('');
    const [errormail, setErrorMail] = useState('');
    const [errorpassword, setErrorPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isPopupLoading, setisPopupLoading] = useState(false);
    const [error, setError] = useState('');
    const history = useHistory();
    const [modalActive, setModalActive] = useState(false);
    const [supportEmail, setSupportEmail] = useState('');

    const [forgotpasswordRes, setForgotpasswordRes] = useState('');
    const ForgotpasswordRes = forgotpasswordRes
        ? <Banner>{forgotpasswordRes}</Banner>
        : '';

    const [errorsupportemail, setErrorsupportemail] = useState('');
    const errorSupportemail = errorsupportemail
        ? <TextStyle variation="negative">{errorsupportemail}</TextStyle>
        : '';

    const handleEmailChange = useCallback((value) => setEmail(value), []);
    //const handlePasswordChange = useCallback((value) => setPass(value), []);
    // new code 
    interface State {
        password: string;
        showPassword: boolean;
    }

    const [password, setPass] = React.useState<State>({
        password: '',
        showPassword: false,
    });
    console.log("Password", password);

    const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setPass({ ...password, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setPass({ ...password, showPassword: !password.showPassword });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };



    const handleMessageChange = useCallback(
        (value) => setSupportEmail(value),
        [],
    );

    const errorEmail = errormail
        ? <TextStyle variation="negative">{errormail}</TextStyle>
        : '';

    const errorPassword = errorpassword
        ? <TextStyle variation="negative">{errorpassword}</TextStyle>
        : '';

    const closeBanner = () => {
        setError('');
    }

    const errorDiv = error
        ? <Banner status="critical" onDismiss={closeBanner}><p>{error}</p></Banner>
        : '';

    const loadingMarkup = isLoading ? <Loading /> : null;

    const sectionForm = isPopupLoading ? (
        <Spinner
            accessibilityLabel="Loading form field"
            hasFocusableParent={false}
            size="small"
        />
    ) : (
            null
        );

    const body = JSON.stringify({
        email_or_username: email.toLowerCase(),
        password: password.password
    });

    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json'
    }

    //handle button click of login form Tab-1
    const handleLogin = () => {
        if (email === '' && password.password === '') {
            setErrorMail("Please enter email or phone number");
            setErrorPassword("Please enter password");
            return;
        }
        else if (email === '') {
            setErrorMail("Please enter email or phone number");
            setErrorPassword('');
            return;
        }
        else if (password.password === '') {
            setErrorMail('');
            setErrorPassword("Please enter password");
            return;
        }
        else if ((new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email.toLowerCase())) === false && (new RegExp(/^[0]?[6789]\d{9}$/).test(email.toLowerCase())) === false) {
            setErrorMail('Please enter valid email id or phone number');
        }
        else {
            setIsLoading(true);
            setErrorPassword('');
            setErrorMail('');
            axios.post(baseUrl.baseUrl + '/patientapp/api-auth-token',
                body, { headers })
                .then(result => {
                    setIsLoading(false);
                    if (result) {
                        localStorage.setItem("token", result.data.token);
                        localStorage.setItem("email", result.data.user.email);
                        localStorage.setItem("firstname", result.data.user.first_name);
                        localStorage.setItem("lastname", result.data.user.last_name);
                        localStorage.setItem("username", result.data.user.username);
                        history.push("/dashboard");
                        window.location.reload();
                    }
                    else {
                        history.push("/signin");
                    }
                })
                .catch((error) => {
                    setError(error.response.data.message || error.response.data.non_field_errors[0]);
                    setIsLoading(false);
                    console.log("ERROR===>", error.response.data.message || error.response.data.non_field_errors[0]);
                })
        }
    }
    // ******* forgot password section modal **********

    const forgetPwd = useCallback(
        () => setModalActive((modalActive) => !modalActive),
        [],
    );

    const forgetPasswordApicall = () => {
        setisPopupLoading(true);
        if (supportEmail === "") {
            setErrorsupportemail("Email field should not be empty");
            setisPopupLoading(false);
        }
        else if ((new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(supportEmail)) === false) {
            setErrorsupportemail("Invalid Email");
            setisPopupLoading(false);
        }
        else {
            setErrorsupportemail('');
            axios.get(baseUrl.baseUrl + '/profileapp/forgot-password/' + supportEmail + '/', {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                }
            })
                .then((res) => {
                    setForgotpasswordRes(res.data.message);
                    setisPopupLoading(false);
                })
                .catch((error) => {
                    setForgotpasswordRes(error.response.data.message);
                    setisPopupLoading(false);
                })
        }
    }
    const forgetPwdPopup = () => {
        setSupportEmail('');
        setForgotpasswordRes('');
        setModalActive((modalActive) => !modalActive)
    }
    const modalMarkup = (
        <Modal
            open={modalActive}
            onClose={forgetPwdPopup}
            title="Forgot Password"
            footer={ForgotpasswordRes}
            primaryAction={{
                content: 'Send',
                onAction: forgetPasswordApicall,
            }}
        >
            <Modal.Section>
                <FormLayout>
                    <TextField
                        label="Email"
                        value={supportEmail}
                        onChange={handleMessageChange}
                        id="supportemail"
                    />
                    <InlineError message={errorSupportemail} fieldID="supportemail" />
                </FormLayout>
                <div style={{ textAlign: 'center' }}>{sectionForm}</div>
            </Modal.Section>
        </Modal>
    );

    const actualPageMarkup = (
        <Form onSubmit={handleLogin}>
            <FormLayout>
                {loadingMarkup}
                <TextField
                    value={email}
                    label="Email or Phone Number"
                    placeholder="Enter your registered email or phone number"
                    id="emailFieldID"
                    onChange={handleEmailChange}
                />
                <InlineError message={errorEmail} fieldID="emailFieldID" />
                <Stack>
                    <Stack.Item fill>
                        <label>Password</label>
                    </Stack.Item>
                    <Stack.Item>
                        <Button plain onClick={forgetPwd}>Forgot Password?</Button>
                    </Stack.Item>
                </Stack>
                {/* <TextField
                    value={password}
                    label="Password"
                    type="password"
                    placeholder="Enter password"
                    id="passwordFieldID"
                    onChange={handlePasswordChange}
                    labelAction={{
                        content: 'Forgot Password?',
                        onAction: forgetPwd,
                    }}
                /> */}
                <ThemeProvider theme={theme}>
                    <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        placeholder="Enter your password"
                        type={password.showPassword ? 'text' : 'password'}
                        value={password.password}
                        onChange={handleChange('password')}
                        fullWidth
                        classes={outlinedInputClasses}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {password.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        labelWidth={0}
                    />
                </ThemeProvider>
                <InlineError message={errorPassword} fieldID="passwordFieldID" />
                <div>
                    <Button fullWidth primary onClick={handleLogin}>Log In</Button>
                </div>
                <div>{errorDiv}</div>
            </FormLayout>
        </Form>
    );
    return (
        <div>
            {actualPageMarkup}
            {modalMarkup}
        </div>
    );
}