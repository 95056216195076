import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  TextStyle,
  Card,
  ResourceItem,
  ResourceList,
  Page,
  Stack,
  Badge,
  Icon,
  Layout,
  DisplayText,
  Button,
  Loading,
} from '@shopify/polaris';
import { useParams } from 'react-router-dom';
import { Box, Flex } from '@primer/components';
import { ChevronRightMinor, AccessibilityMajor } from '@shopify/polaris-icons';
import PatientFlow from '../image/design-assets/patient-flow-chart.png';
import { useDispatch, useSelector } from 'react-redux';
import { getEncounterList } from '../../redux/effects/ApiCall';
import { AppState } from '../../redux/store/Store';

export default function CaseHistory() {
  const history = useHistory();
  const { patientid } = useParams() as {
    patientid: string;
  };
  const { patientName } = useParams() as {
    patientName: string;
  };
  const loadingMarkup = <Loading />;

  const handleAddEncounterToggle = () => {
    history.push(`/filepreparation/${patientid}`);
  }

  const handleDemographicToggle = () => {
    history.push(`/demographicdetails/${patientid}`);
  }

  const handleInsuranceToggle = () => {
    history.push(`/insurancedetails/${patientid}`);
  }

  const dispatch = useDispatch();

  // encounter list api call ...
  useEffect(() => {
    dispatch(getEncounterList(patientid));
    localStorage.removeItem("paymentRedirect");
  }, [dispatch]);

  // const gotoDetails = () => {
  //   history.push(`/filepreparation/${patientid}`);
  // }

  const emptyPageMarkup = (
    <Layout>
      <Layout.Section oneHalf>
        <Flex style={{ height: "50vh" }} alignItems="center" justifyContent="center">
          <Box pt={5} mt={5}>
            <DisplayText size="medium">Seek 2nd opinion from AIIMS trained doctors</DisplayText>
            <p><br />One-stop solution for 2nd opinion and other e-healthcare services from top-notch specialists trained at AIIMS, New Delhi..<br /><br /></p>
            <div>
              <Button primary onClick={handleAddEncounterToggle}>Add Case</Button>
            </div>
          </Box>
        </Flex>
      </Layout.Section>
      <Layout.Section oneHalf>
        <Box pt={5} mt={5}>
          <div><img
            width="100%"
            height="100%"
            style={{
              objectFit: 'cover',
              objectPosition: 'center',

            }} src={PatientFlow} alt="" /></div>
        </Box>
      </Layout.Section>
    </Layout>
  );

  const casehistory = useSelector((state: AppState) => state.caseValue);
  console.log("CASELISTREDUX==>", casehistory);

  const casehistoryname = useSelector((state: AppState) => state.caseValue.patientname.patientName);
  console.log("CASELISTREDUXNAME==>", casehistoryname);

  const pagebody = (
    <Layout >
      {casehistory && casehistory.caseValue.length > 0 ? (
        <Layout.Section>
          <Card>
            <ResourceList
              items={casehistory.caseValue}
              renderItem={(item) => {
                const { patientId, caseId, secondOpinionReasons, whyWant2ndOpinion, consultationType, doctorTeamType } = item;
                return (
                  <ResourceItem
                    id={`${patientId}-${caseId}`}
                    url={`/filepreparation/${patientId}/${caseId}`}
                    accessibilityLabel={`View details for ${whyWant2ndOpinion ? (whyWant2ndOpinion) : ''}`}
                    persistActions
                  >
                    <Stack alignment="center">
                      <Stack.Item>
                        <Icon source={AccessibilityMajor} />
                      </Stack.Item>
                      <Stack.Item fill>
                        <Box>
                          <h5>
                            <TextStyle variation="strong">{whyWant2ndOpinion ? (whyWant2ndOpinion) : ''}</TextStyle>
                          </h5>
                          <Box maxWidth="40vw"><em>{secondOpinionReasons}</em></Box>
                          {consultationType ? (<div><Badge progress="complete">{consultationType}</Badge></div>) : ''}
                        </Box>
                      </Stack.Item>
                      <Stack.Item>
                        <Icon source={ChevronRightMinor} />
                      </Stack.Item>
                    </Stack>
                  </ResourceItem>
                );
              }}
            />
          </Card>
        </Layout.Section>
      ) : (
          <Layout.Section>
            {emptyPageMarkup}
          </Layout.Section>
        )}
    </Layout>
  );

  return (
    <Page fullWidth title={`Case History of ${casehistoryname ? casehistoryname : ''}`} // replace 'patientName' with 'casehistory.caseValue.patientName'
      secondaryActions={[
        { content: 'Insurance Details', onAction: handleInsuranceToggle },
        { content: 'Demographic Details', onAction: handleDemographicToggle },
      ]}
      primaryAction={{ content: 'Add Case', onAction: handleAddEncounterToggle }}>
      {casehistory.loading ? loadingMarkup : ''}
      {pagebody}
    </Page>
  );
}