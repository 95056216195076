import React, { useState, useCallback, useEffect } from 'react';
import {
    Loading,
    Banner,
    TextField,
    Card,
    ResourceList,
    ResourceItem,
    TextStyle,
    Stack,
    Button,
    Avatar,
    Scrollable,
    ExceptionList,
    FormLayout
} from '@shopify/polaris';
import { NoteMinor } from '@shopify/polaris-icons';
import { Box } from '@primer/components';
import { initializeIcons } from '@uifabric/icons';
import { Typography } from '@material-ui/core';
import axios from 'axios';
import baseUrl from '../../Url';
import { useParams } from 'react-router-dom';

export default function AddPatientTeam(props: any) {

    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;
    const [bannerbody, setbannerbody] = useState('');
    const [successbannerbody, setSuccessbannerbody] = useState('');
    const [patientteamemail, setPatientTeamEmail] = useState('');
    const handleOtherValueChange = useCallback((value) => setPatientTeamEmail(value), []);

    const [patientTeamOwnerName, setPatientTeamOwnerName] = useState('');
    const [patientTeamOwnerEmail, setPatientTeamOwnerEmail] = useState('');
    const [patientTeamOwnerPhone, setpatientTeamOwnerPhone] = useState('');
    const [patientCasePhone, setpatientCasePhone] = useState('');

    const [selectedPatientTeamList, setSelectedPatientTeamList] = useState<any[]>([]);

    const closebanner = () => {
        setbannerbody('');
        setSuccessbannerbody('');
    }

    const { caseid } = useParams() as {
        caseid: string;
    };

    const errorbannermarkup = bannerbody
        ? <Banner status="critical" onDismiss={closebanner}>
            <p>{bannerbody}</p>
        </Banner>
        : '';

    const successbannermarkup = successbannerbody
        ? <Banner status="success" onDismiss={closebanner}>
            <p>{successbannerbody}</p>
        </Banner>
        : '';


    useEffect(() => {
        window.scrollTo(0, 0);
        getSelectedPatientTeam();
    }, []);

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    const body = {
        "caseId": caseid,
        "email": patientteamemail
    };

    const getSelectedPatientTeam = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientTeam/get_patientTeam_request', {
            "caseId": caseid
        }, { headers }).then((res) => {
            if (res.data.status == 1) {
                setPatientTeamOwnerName(res.data.ownerDetails.name);
                setPatientTeamOwnerEmail(res.data.ownerDetails.email);
                setpatientTeamOwnerPhone(res.data.ownerDetails.phone);
                setpatientCasePhone(res.data.patientCasePhone);
                setSelectedPatientTeamList(res.data.patientTeam);
                console.log("PATIENTTEAMLIST==>", res.data.patientTeam);
                setIsLoading(false);
            }
            else {
                setSelectedPatientTeamList([]);
                setIsLoading(false);
            }

        }).catch((error) => {
            console.log(error);
            setIsLoading(false);
        })
    };

    const handleAddPatientTeam = () => {
        if (patientteamemail === '') {
            setbannerbody('Please enter the email id of whom you want to add.');
        }
        else {
            setIsLoading(true);
            axios.post(baseUrl.baseUrl + '/patientapp/api/patientTeam/add_patientTeam_request',
                body, { headers })
                .then((res) => {
                    setIsLoading(false);
                    setSuccessbannerbody(res.data.message);
                    getSelectedPatientTeam();
                })
                .catch((error) => {
                    console.log(error.response);
                    setIsLoading(false);
                })
        }
    }

    const symptomsAddInfo = (
        <Stack vertical={true}>
            <Card title="Patient Team Owner Details:">
                <Card.Section>
                    <Stack distribution="fillEvenly">
                        <Stack.Item>
                            <div>
                                <label><b>Name: </b></label>
                                <label>{patientTeamOwnerName}</label>
                            </div>
                        </Stack.Item>
                        <Stack.Item>
                            <div>
                                <label><b>Email: </b></label>
                                <label>{patientTeamOwnerEmail}</label>
                            </div>
                        </Stack.Item>
                        <Stack.Item>
                            <div>
                                <label><b>Contact Number: </b></label>
                                <label>{patientTeamOwnerPhone}</label>
                            </div>
                        </Stack.Item>
                    </Stack>
                </ Card.Section>
            </Card>
            <FormLayout.Group>
                <Card title="Selected Patient Team List">
                    <Card.Section>
                        {selectedPatientTeamList && selectedPatientTeamList.length ? (
                            <Scrollable shadow style={{ height: '200px' }} focusable horizontal={false}>
                                <ResourceList
                                    resourceName={{ singular: 'customer', plural: 'customers' }}
                                    items={selectedPatientTeamList}
                                    renderItem={(item) => {
                                        const { id, email } = item;
                                        const media = <Avatar customer size="medium" name={email} />;
                                        return (
                                            <ResourceItem
                                                id={String(id)}
                                                url=''
                                                media={media}
                                                accessibilityLabel={`View details for ${email}`}
                                            >
                                                <h6>
                                                    <TextStyle variation="strong">{email}</TextStyle>
                                                </h6>
                                            </ResourceItem>
                                        );
                                    }}
                                />
                            </Scrollable>
                        ) : (
                            <ExceptionList
                                items={[
                                    {
                                        icon: NoteMinor,
                                        description: 'No Patient Team is available!',
                                    },
                                ]}
                            />)}
                    </Card.Section>
                </Card>
                <Stack vertical={true}>
                    <div>
                        <label><b>Patient Case's Contact Number: </b></label>
                        <label>{patientCasePhone}</label>
                    </div>
                    <div>
                        <Typography style={{ marginBottom: "5px" }}>Email of patient team member</Typography>
                        <Stack>
                            <Stack.Item fill>
                                <TextField
                                    multiline={1}
                                    value={patientteamemail}
                                    type="email"
                                    label=""
                                    placeholder="e.g. abc@xyz.com"
                                    id="patientteamEmail"
                                    onChange={handleOtherValueChange}
                                />
                                <Box pt={2}>
                                    {errorbannermarkup}
                                    {successbannermarkup}
                                </Box>
                            </Stack.Item>
                            <Stack.Item><Button monochrome outline primary onClick={handleAddPatientTeam}>Add</Button></Stack.Item>
                        </Stack>
                    </div>
                </Stack>
            </ FormLayout.Group>
        </Stack >
    )
    return (
        <Typography>
            {loadingMarkup}
            {symptomsAddInfo}
        </Typography>
    );
}