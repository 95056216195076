import React, { useCallback, useState } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Login from './components/pages/PatientOnBoarding/Login';
import Signup from './components/pages/PatientOnBoarding/Signup';
import NotFound from './components/pages/NotFound';
import Dashboard from './components/pages/Dashboard';
import AddHealthRecord from './components/pages/FilePreparation/AddHealthRecord';
import Userprofile from './components/pages/Userprofile';
import Appointment from './components/pages/Appointment';
import Doctor from './components/pages/Doctor';
import Payment from './components/pages/Payment';
import MasterImportedData from './components/pages/MasterImportedData/MasterImportedData';
import NeedHelp from './components/pages/NeedHelp';
import { PrivateRoute } from './routes/PrivateRoute';
import HelpCenter from './components/pages/HelpCenter';
import InformConsent from './components/pages/FilePreparation/InformConsent';
import CodeOfConduct from './components/pages/CodeOfConduct';
import CaseHistory from './components/pages/CaseHistory';
import FilePreparation from './components/pages/FilePreparation';
import DemographicDetails from './components/pages/FilePreparation/DemographicDetails';
import InsuranceDetails from './components/pages/InsuranceDetails';
import Task from './components/pages/Task/Task';
import TaskDetails from './components/pages/Task/TaskDetails';
import AppointmentDetails from './components/pages/AppoinmentDetails';
import DoctorDetails from './components/pages/DoctorDetails';
import baseUrl from './Url';
import {
  Frame,
  Loading,
  Navigation,
  TopBar,
  Banner,
  VisuallyHidden,
  Icon,
  Scrollable,
  Button,
  Heading,
  Sheet,
  Page
} from '@shopify/polaris';

import {
  CustomerPlusMajor,
  ExistingInventoryMajor,
  LogOutMinor,
  CustomersMajor,
  HintMajor,
  PaymentsMajor,
  BlogMajor,
  QuestionMarkMajor,
  MobileCancelMajor,
  TransferWithinShopifyMajor,
  PhoneOutMajor,
  NoteMajor
} from '@shopify/polaris-icons';
import { } from 'react-router-dom';
import axios from 'axios';
import { PublicRoute } from './routes/PublicRoute';
import 'rsuite/dist/styles/rsuite-default.css';

export default function App() {
  const [userlogouterror, setUserlogoutError] = useState('');
  const [userMenuActive, setUserMenuActive] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [sheetActive, setSheetActive] = useState(false);

  const toggleSheetActive = useCallback(
    () => setSheetActive((sheetActive) => !sheetActive),
    [],
  );

  const [islineLoading, setislineLoading] = useState(false);

  const loadingMarkup = islineLoading ? <Loading /> : null;

  const helpCentermarkup = (
    <Page narrowWidth>
      <Sheet open={sheetActive} onClose={toggleSheetActive}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              borderBottom: '1px solid #DFE3E8',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '1.6rem',
              width: '100%',
            }}
          >
            <Heading>AIMS2Health Help Center</Heading>
            <Button
              accessibilityLabel="Cancel"
              icon={MobileCancelMajor}
              onClick={toggleSheetActive}
              plain
            />
          </div>
          <Scrollable style={{ padding: '1.6rem', height: '100%' }}>
            <HelpCenter />
          </Scrollable>
          <div
            style={{
              alignItems: 'center',
              borderTop: '1px solid #DFE3E8',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '1.6rem',
              width: '100%',
            }}
          >
            <Button onClick={toggleSheetActive}>Cancel</Button>
            <Button primary onClick={toggleSheetActive}>
              Done
          </Button>
          </div>
        </div>
      </Sheet>
    </Page>
  );
  const BannerClose = () => {
    setUserlogoutError('');
  }
  const errorlogoutDiv = userlogouterror ? (
    <Banner status="critical" onDismiss={BannerClose}>{userlogouterror}</Banner>
  ) : '';

  let usernameCheck: string;

  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    [],
  );
  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive,
      ),
    [],
  );

  const userMenuActions = [
    {
      items: [{ content: 'User Profile', url: "/userprofile", }, { content: 'Code of conduct', url: "/codeofconduct", }],
    },
  ];

  const userFirstname = function () {
    return localStorage.getItem("firstname");
  }
  let Firstname: string = userFirstname()!

  const userLastname = function () {
    return localStorage.getItem("lastname");
  }
  let Lastname: string = userLastname()!
  if (Firstname === '') {
    usernameCheck = "Unknown"
  }
  else {
    usernameCheck = Firstname + " " + Lastname
  }

  const userEmail = function () {
    return localStorage.getItem("email");
  }
  let useremail: string = userEmail()!
  const token = function () {
    return localStorage.getItem("token");
  }

  let userToken: string = token()!
  if (userToken == null) {
  }

  const body = JSON.stringify({
    token: userToken,
  });

  const headers = {
    'Accept': 'application/json',
    'Content-type': 'application/json',
    'Authorization': `Token ${userToken}`
  }

  const handleLogout = () => {
    setislineLoading(true);
    axios.post(baseUrl.baseUrl + '/profileapp/logout/',
      body, { headers })
      .then(_result => {
        setislineLoading(false);
        //let status = _result.data.status;
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        localStorage.removeItem("firstname");
        localStorage.removeItem("lastname");
        localStorage.removeItem("username");
        window.location.reload();
      })
      .catch((error) => {
        setislineLoading(false);
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        localStorage.removeItem("firstname");
        localStorage.removeItem("lastname");
        localStorage.removeItem("username");
        setUserlogoutError(error.response.data.message);
      })
  }

  const userMenuMarkup = userToken ? (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={usernameCheck}
      detail={useremail}
      initials=""
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  ) : null;

  const helpTopBarHandler = useCallback(() => { }, []);

  const secondaryMenuMarkup = (
    <TopBar.Menu
      activatorContent={
        <span onClick={toggleSheetActive}>
          <Icon source={QuestionMarkMajor} />
          <VisuallyHidden>Secondary menu</VisuallyHidden>
        </span>
      }
      open={false}
      onOpen={helpTopBarHandler}
      onClose={helpTopBarHandler}
      actions={[]}

    />
  );

  const topBarMarkup = userToken ? (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      secondaryMenu={secondaryMenuMarkup}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  ) : null;

  const navigationMarkup = userToken ? (
    <Navigation location="/">
      <div style={{ display: 'flex', height: 'calc(100vh - 10rem)', flexDirection: 'column', alignItems: 'flex-start' }}>
        <div style={{ marginBottom: 'auto', width: '100%', }}>
          <Navigation.Section
            items={[
              {
                label: 'Add Patient',
                icon: BlogMajor,
                url: "/demographicdetails",
              },
            ]}
          />
          <Navigation.Section
            separator
            items={[
              {
                label: ' My Patient List',
                icon: CustomersMajor,
                url: "/dashboard",
              },
              {
                label: 'Appointments',
                icon: ExistingInventoryMajor,
                url: "/appoinmentDetails",
              },
              {
                label: 'Find Doctors',
                icon: CustomerPlusMajor,
                url: "/doctor",
              },
              {
                label: 'Payment',
                icon: PaymentsMajor,
                url: "/payment",
              },
              {
                label: 'Imported Data',
                icon: TransferWithinShopifyMajor,
                url: "/masterimporteddata",
              },
              {
                label: 'Task',
                icon: NoteMajor,
                url: "/task",
              },
            ]}

          />
        </div>
        <div style={{ width: '100%' }}>
          <Navigation.Section
            items={[
              {
                label: 'Call Us',
                icon: PhoneOutMajor,
                onClick: () => window.open('tel:+91-8047165657')
              },
              {
                label: 'Need Help',
                icon: HintMajor,
                url: "/needhelp",        
                selected: true,
              },
              {
                label: 'User Profile',
                icon: CustomersMajor,
                url: "/userprofile",
              },
              {
                label: 'Log Out',
                icon: LogOutMinor,
                onClick: handleLogout,
              },
            ]}
            separator
          />
        </div>
      </div>
    </Navigation>
  ) : null;

  return (
    <div>
      <Frame
        topBar={topBarMarkup}
        navigation={navigationMarkup}
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
      >
        {loadingMarkup}
        {islineLoading}
        {errorlogoutDiv}
        {helpCentermarkup}
        <Router>
          <Switch>
            <PublicRoute exact path="/" component={Login} />
            <PublicRoute exact path="/signin" component={Login} />
            <PublicRoute exact path="/signup" component={Signup} />
            <PrivateRoute path="/dashboard" component={Dashboard} />
            <PrivateRoute path="/addhealthrecord/:patientid?" component={AddHealthRecord} />
            <PrivateRoute path="/casehistory/:patientid?" component={CaseHistory} />
            <PrivateRoute path="/filepreparation/:patientid?/:caseid?/:paymentRedirect?" component={FilePreparation} />
            <PrivateRoute path="/userprofile" component={Userprofile} />
            <PrivateRoute path="/appointment/:doctorid?" component={Appointment} />
            <PrivateRoute path="/informconsent" component={InformConsent} />
            <PrivateRoute path="/doctor" component={Doctor} />
            <PrivateRoute path="/payment" component={Payment} />
            <PrivateRoute path="/masterimporteddata" component={MasterImportedData} />
            <PrivateRoute path="/task" component={Task} />
            <PrivateRoute path="/taskdetails/:rowIndex?" component={TaskDetails} />
            <PrivateRoute path="/needhelp" component={NeedHelp} />
            <PrivateRoute path="/codeofconduct" component={CodeOfConduct} />
            <PrivateRoute path="/demographicdetails/:patientid?" component={DemographicDetails} />
            <PrivateRoute path="/insurancedetails/:patientid?" component={InsuranceDetails} />
            <PrivateRoute path="/appoinmentDetails" component={AppointmentDetails} />
            <PrivateRoute path="/doctordetails/:id?" component={DoctorDetails} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </Frame>
    </div>
  );
}
