import React, { useState, useCallback, useEffect } from 'react';
import {
    Card,
    FormLayout,
    TextField,
    InlineError,
    Banner,
    TextStyle,
    Thumbnail,
    Loading,
    Caption,
    Page,
    DataTable,
    Modal,
    Button,
    TextContainer,
    Subheading,
    ExceptionList
} from '@shopify/polaris';
import { initializeIcons } from '@uifabric/icons';

import { createMuiTheme, } from "@material-ui/core";
import { NoteMinor } from '@shopify/polaris-icons';
import axios from 'axios';
import { Redirect, useParams } from 'react-router-dom';
import baseUrl from '../../Url';


initializeIcons();

export default function FinalOpinion(props: any) {

    const { patientid } = useParams() as {
        patientid: string;
    };
    const { caseid } = useParams() as {
        caseid: string;
    };
    const [patientDetailsId, setPatientDetailsId] = useState('');

    const [toSignin, setToSignin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;

    const [imageId, setImageId] = useState('');
    const [uploading] = React.useState(false);
    const [imageInfo, setImageInfo] = useState('');

    const [secondOpinionDescription, setSecondOpinionDescription] = useState('');
    const handleSecondOpinionDescriptionChange = useCallback((newValue) => {
        setSecondOpinionDescription(newValue);
    }, []);

    const [selectedDate, setSelectedDate] = React.useState<Date | null>(
        new Date(),
    );
    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
    };

    const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(
        new Date(),
    );
    const handleEndDateChange = (date: Date | null) => {
        setSelectedEndDate(date);
    };

    const closeerror = () => {
        setSecondOpinionError('')
    }
    const [secondOpinionError, setSecondOpinionError] = useState('');
    const medicalSummaryErrorDiv = secondOpinionError
        ? <Banner
            onDismiss={closeerror}
            status="critical">
            <p>{secondOpinionError}</p>
        </Banner>
        : '';

    const closeSecondOpinionSuccess = () => {
        setSecondOpinionSuccess('');
    }
    const [secondOpinionSuccess, setSecondOpinionSuccess] = useState('');
    const successDiv = secondOpinionSuccess
        ? <Banner
            onDismiss={closeSecondOpinionSuccess}
            status="success">
            <p>{secondOpinionSuccess}</p>
        </Banner>
        : '';

    const [secondOpinionDescriptionError, setSecondOpinionDescriptionError] = useState('');
    const secondOpinionDescriptionErrordiv = secondOpinionDescriptionError
        ? <TextStyle variation="negative">{secondOpinionDescriptionError}</TextStyle>
        : '';

    const uploadbody = {
        'typeName': 'patient-image'
    };

    const [descriptionModalActive, setDescriptionModalActive] = useState(false);
    const handleDescriptionToggle = () => {
        setDescriptionModalActive((descriptionModalActive) => !descriptionModalActive);
        setSelectedDate(new Date());
        setSelectedEndDate(null);
        setImageInfo('');
        setSecondOpinionDescriptionError('');
        setSecondOpinionDescription('');
        setSecondOpinionSuccess('');
        setSecondOpinionError('');
    }

    const [docViwerActive, setDocViwerActive] = useState(false);
    const handleDocViewerChange = useCallback(() => setDocViwerActive(!docViwerActive), [docViwerActive]);

    const [deleteModalActive, setDeleteModalActive] = useState(false);
    const handleDeleteSecondOpinionModalChange = useCallback(() => setDeleteModalActive(!deleteModalActive), [deleteModalActive]);



    const closeProblemDeleteModalSucessError = () => {
        setSecondOpinionSuccess('');
    }

    const [deleteMedicalSummarySuccess, setDeleteMedicalSummarySuccess] = useState('');
    const successDeleteProblemModalDiv = deleteMedicalSummarySuccess
        ? <Banner
            onDismiss={closeProblemDeleteModalSucessError}
            status="success">
            <p>{deleteMedicalSummarySuccess}</p>
        </Banner>
        : '';

    //child to parent value send section.....
    const secondOpinionBody = {
        "patientId": patientid,
        "caseId": caseid,
        "secondOpinionUiSection": "finalOpinion",
        "description": secondOpinionDescription,
        "patientDocumentId": imageId,
        "startDate": selectedDate,
        "endDate": selectedEndDate
    };

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    const [chiefComplaintListValue, setChiefComplaintListValue] = useState<any[]>([]);
    const truncate = (str: string,) => {
        return str.length > 0 ? str.substring(0, 20) + "..." : str;
    }

    const getSecondOpinionBody = {
        "caseId": caseid,
        "secondOpinionUiSection": "finalOpinion"
    }
    const getSecondOpinionList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/get_patientDetailsList',
            getSecondOpinionBody, { headers })
            .then((res) => {
                setIsLoading(false);
                let resData = res.data.patientDetailList;
                console.log("Symptom List=======>", resData);
                let symptomConfig = [];
                for (var i = 0; i < resData.length; i++) {
                    let selectedResponse = resData[i];
                    let patientDetailsID = resData[i].id;
                    let description = (resData[i].description == null) ? "" : truncate(resData[i].description);
                    console.log("Award Name " + description);
                    let dateStart = (resData[i].startDate == null) ? "" : (new Date(resData[i].startDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let dateEnd = (resData[i].endDate == null) ? "" : (new Date(resData[i].endDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let supportingDocuments = (resData[i].patientDocument == null) ? '' : resData[i].patientDocument.document;
                    console.log("supportingDocuments==> ", supportingDocuments);
                    setPatientDetailsId(patientDetailsID);
                    symptomConfig.push([
                        <Button icon={NoteMinor} size="large" onClick={() => {
                            setDocViwerActive(true);
                            setImageInfo(supportingDocuments);
                        }}></Button>,
                        description,
                        dateStart,
                        dateEnd
                    ]);
                    console.log("Symptoms List ====> ", symptomConfig);
                }
                setChiefComplaintListValue(symptomConfig);
            })
            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
                console.log(toSignin);
            })
    }


    // Delete body....
    const deleteSecondOpinionRecordBody = {
        "caseId": caseid,
        "patientDetailId": patientDetailsId
    }
    // delete api call here ....
    const DeleteSecondOpinionSubmit = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/delete_patientDetails',
            deleteSecondOpinionRecordBody, { headers })
            .then((res) => {
                setIsLoading(false);
                setDeleteMedicalSummarySuccess(res.data.message);
                getSecondOpinionList();
                handleDeleteSecondOpinionModalChange();
            })

            .catch((error) => {
                setIsLoading(false);
                // console.log("error record====" + error);
            })
    }

    function getOpiniontList() {
        getSecondOpinionList();
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getSecondOpinionList();
    }, []);

    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const secondOpinionTableBody = (
        <div>
            <Card >
                <Card.Header title="Documents">
                    <Button primary={false} onClick={getOpiniontList}>Reload</Button>
                </Card.Header>
                <Card.Section>
                    {chiefComplaintListValue && chiefComplaintListValue.length ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'text',
                                'text'
                            ]}
                            headings={[
                                'Document',
                                'Description',
                                'Start Date',
                                'End Date'
                            ]}
                            rows={chiefComplaintListValue}
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Final Opinion Details is available!',
                                },
                            ]}
                        />)}
                </Card.Section>
            </Card>
        </div >
    )

    const docViewerComponent = (
        <Modal
            open={docViwerActive}
            onClose={handleDocViewerChange}
        >
            <Modal.Section>
                <TextContainer>
                    <p>
                        <iframe src={imageInfo} title='Small document' width='100%' height='700' frameBorder='0'>This is an embedded <a target='_blank' href='http://office.com' rel="noopener noreferrer">Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps' rel="noopener noreferrer">Office Online</a>.</iframe>
                    </p>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );


    return (
        <Page fullWidth title="Final Opinion">
            {toSignin ? <Redirect to="/signin" /> : null}
            {loadingMarkup}
            {secondOpinionTableBody}
            {docViewerComponent}
        </Page>
    );
}