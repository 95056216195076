import React, { useState, useCallback } from 'react';
import {
    Page,
    Tabs
} from '@shopify/polaris';
import { Box } from '@primer/components';
import { initializeIcons } from '@uifabric/icons';
import MasterImportedDataList from './MasterImportedDataList'
import MasterImportedConfigFile from './MasterImportedConfig'

initializeIcons();

interface AccountProps {
    onAction(): void;
}

export default function MasterImportedData() {
    const [selected, setSelected] = useState(0);
    const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), []);

    const tabs = [
        {
            id: 'importeddata',
            content: ' Master Imported Data',
            body: (
                <MasterImportedDataList />
            )
            ,
            panelID: 'master-importeddatalist-content',
        },
        {
            id: 'importedconfig',
            content: 'Master Import Config',
            body: (
                <MasterImportedConfigFile />
            ),
            accessibilityLabel: 'All customers',
            panelID: 'importedconfig-content',
        },

    ];
    const masterConfigInfo = (
        <div>
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} fitted>
                <Box py={3}>{tabs[selected].body}</Box>
            </Tabs>
        </div>
    )
    return (
        <Page title="Import Data" fullWidth>
            {masterConfigInfo}
        </Page>
    );
}