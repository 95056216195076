import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    FormLayout,
    TextField,
    Form,
    Page,
    Loading,
    Banner,
    TextStyle,
    Card,
    Caption,
    Stack,
    Layout,
    Scrollable,
    Collapsible
} from '@shopify/polaris';
import axios from 'axios';
import baseUrl from '../../../Url';
import { Box } from '@primer/components';
import { CheckPicker, Icon, Loader, SelectPicker, Uploader } from 'rsuite';
import DateFnsUtils from '@date-io/date-fns';
import { useParams } from 'react-router-dom';
import {
    MuiPickersUtilsProvider, KeyboardDatePicker
} from '@material-ui/pickers';
import { createMuiTheme, IconButton } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import InformConsent from './InformConsent';

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#BBE5B3',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#50B83C',
        }
    },
    typography: {
        fontSize: 20,
    },
});
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

export default function DemographicDetails() {
    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;

    const [firstName, setFirstName] = useState('');
    const handleFirstNameChange = useCallback((value) => setFirstName(value), []);

    const [lastName, setLastName] = useState('');
    const handleLastNameChange = useCallback((value) => setLastName(value), []);

    const [sOwOdO, setsOwOdO] = useState('');
    const handleSOwOdOChange = useCallback((value) => setsOwOdO(value), []);

    const [relationValues, setRelationValues] = useState([]);
    const [selectedRel, setSelectedRel] = useState(null);
    const handleRelationshipSelectChange = useCallback((value) => setSelectedRel(value), []);

    const [selectedDate, setSelectedDate] = React.useState<Date | null>(
        new Date(),
    );
    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
    };

    const [genderValues, setGenderValues] = useState([]);
    const [selectedGen, setSelectedGen] = useState(null);
    const handleGenSelectChange = useCallback((value) => setSelectedGen(value), []);

    const [bloodGroupValues, setBloodGroupValues] = useState([]);
    const [selectedBloodGroup, setSelectedBloodGroup] = useState(null);

    const [language, setLanguage] = useState([]);
    const [languageSelected, setLanguageSelected] = useState([]);
    const handlelanguageChange = useCallback((value) => setLanguageSelected(value), []);

    const [maritalStatusValues, setMaritalStatusValues] = useState([]);
    const [selectedMaritalStatus, setSelectedMaritalStatus] = useState(null);
    const handleMaritalStatusSelectChange = useCallback((value) => setSelectedMaritalStatus(value), []);

    const [sexualOrientationValues, setSexualOrientationValues] = useState([]);
    const [selectedSexualOrientation, setSelectedSexualOrientation] = useState(null);
    const handleSelectedSexualOrientationChange = useCallback((value) => setSelectedSexualOrientation(value), []);

    const [city, setCity] = useState('');
    const handleCityChange = useCallback((newValue) => setCity(newValue), []);

    const [textValue, setTextValue] = useState('');
    const handleChange = useCallback((newValue) => setTextValue(newValue), []);

    const [imageId, setImageId] = useState('');

    const [imageInfo, setImageInfo] = useState('');
    const [addPatientMessage, setAddPatientMessage] = useState('');
    const [editPatientMessage, setEditPatientMessage] = useState('');
    const [error, setError] = useState('');

    const [addpatienterror, setAddpatientError] = useState('');
    const [editpatienterror, setEditpatientError] = useState('');
    const [patientrelatnlisterror, setPatientrelatnlistError] = useState('');
    const [uploading] = React.useState(false);
    const { patientid } = useParams() as { patientid: string; };
    const [isEdit, setIsEdit] = useState(true);
    const [isEnableSubmit, setEnableSubmit] = useState(true);

    const handleBloodGroupChange = useCallback((value) => setSelectedBloodGroup(value), []);

    const history = useHistory();

    const styles = {
        width: 70,
        height: 70,
        borderRadius: 35
    };

    const handleBannerDismiss = () => {
        setAddPatientMessage('');
        setEditPatientMessage('');
    }

    const successMessage = addPatientMessage ?
        <Banner onDismiss={handleBannerDismiss} status="success" title={addPatientMessage}></Banner>
        : '';

    const editSuccessMessage = editPatientMessage ?
        <Banner onDismiss={handleBannerDismiss} status="success" title={editPatientMessage}></Banner>
        : '';

    const BannerClose = () => {
        setAddpatientError('');
        setPatientrelatnlistError('');
        setEditpatientError('');
    }

    const errorpatientDiv = addpatienterror ? (
        <Banner status="critical" onDismiss={BannerClose}>{addpatienterror}</Banner>
    ) : '';

    const errorpatientrelatnDiv = patientrelatnlisterror ? (
        <Banner status="critical" onDismiss={BannerClose}>{patientrelatnlisterror}</Banner>
    ) : '';

    const erroreditpatientDiv = editpatienterror ? (
        <Banner status="critical" onDismiss={BannerClose}>{editpatienterror}</Banner>
    ) : '';

    const [getlanguagelist, setgetlanguagelist] = useState(["Please select language"]);
    const onLanguageClear = () => {
        setLanguageSelected([]);  // this will reset the selected value
    };

    const body = {
        "gender": selectedGen,
        "relationship": selectedRel,
        "firstname": firstName,
        "lastname": lastName,
        "dob": selectedDate,
        "pincode": textValue,
        "bloodGroup": selectedBloodGroup,
        "patientImageId": String(imageId),
        "city": city,
        "guardianName": sOwOdO,
        "maritialStatusId": selectedMaritalStatus,
        "sexualorientationId": selectedSexualOrientation,
        "languageId": languageSelected
    }

    const updatebody = {
        "healthRecordId": patientid,
        "gender": selectedGen,
        "relationship": selectedRel,
        "firstname": firstName,
        "lastname": lastName,
        "dob": selectedDate,
        "pincode": textValue,
        "bloodGroup": selectedBloodGroup,
        "patientImageId": String(imageId),
        "city": city,
        "guardianName": sOwOdO,
        "maritialStatusId": selectedMaritalStatus,
        "sexualorientationId": selectedSexualOrientation,
        "languageId": languageSelected
    }
    const userToken = localStorage.getItem("token");
    const isFirstName = firstName;
    const isLastName = lastName;

    const handleBannerErrorDismiss = () => {
        setError('');
    }

    const errorDiv = error ?
        <Banner onDismiss={handleBannerErrorDismiss} status="critical">
            <TextStyle variation="negative">{error}</TextStyle>
        </Banner>
        : '';

    // Add or edit API call
    const addHealthRecord = () => {
        if (patientid) {
            if (!isFirstName.trim()) {
                setError("First name should not be empty");
                return;
            } else if (!isLastName.trim()) {
                setError("");
                setError("Last name should not be empty.");
                return;
            }
            else if (selectedGen === null) {
                setError("");
                setError("Gender should not be empty");
                return;
            }
            else if (selectedDate === null) {
                setError("");
                setError("Date of Birth should not be empty");
                return;
            }
            else if ((new RegExp("^[1-9][0-9]{5}$").test(textValue.toString()) === false)) {
                setError("");
                setError('Please enter valid Pincode');
            }
            else {
                if (userToken) {
                    console.log(updatebody)
                    setIsLoading(true);
                    axios.post(baseUrl.baseUrl + '/patientapp/api/patient/edit_patient_demographics_details',
                        updatebody, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-type': 'application/json',
                            'Authorization': `Token ${userToken} `
                        }
                    }
                    )
                        .then(result => {
                            setEditPatientMessage(result.data.message);
                            setFirstName('');
                            setLastName('');
                            setTextValue('');
                            setIsLoading(false);
                            getPatientDetailsById();
                        })
                        .catch((error) => {
                            setIsLoading(false);
                            setEditpatientError(error.response.data.message);
                        })
                }
            }
        }
        else {
            if (!isFirstName.trim()) {
                setError("First name should not be empty");
                return;
            } else if (!isLastName.trim()) {
                setError("");
                setError("Last name should not be empty.");
                return;
            }
            else if (selectedGen === null) {
                setError("");
                setError("Gender should not be empty");
                return;
            }
            else if (selectedDate === null) {
                setError("");
                setError("Date of Birth should not be empty");
                return;
            }
            else if ((new RegExp("^[1-9][0-9]{5}$").test(textValue.toString()) === false)) {
                setError("");
                setError('Please enter valid Pincode');
            }
            else {
                if (userToken) {
                    console.log(body);
                    setIsLoading(true);
                    axios.post(baseUrl.baseUrl + '/patientapp/api/patient/add_patient',
                        body, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-type': 'application/json',
                            'Authorization': `Token ${userToken} `
                        }
                    }
                    )
                        .then(result => {
                            setAddpatientError('');
                            setAddPatientMessage(result.data.message);
                            setIsLoading(false);
                            history.push("/Dashboard");
                        })
                        .catch((error) => {
                            setIsLoading(false);
                            setAddPatientMessage('');
                            setAddpatientError(error.response.data.message);
                        })
                }
            }
        }
    }

    // Get health record
    const gethealthrecordbody = {
        "healthRecordId": patientid
    };

    const getPatientDetailsById = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patient/get_patient_demographics_details',
            gethealthrecordbody, {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Token ${userToken} `
            }
        })
            .then((result) => {
                setIsLoading(false);
                setFirstName((result.data.personalDetails.firstname === null || result.data.personalDetails.firstname === "") ? "" : result.data.personalDetails.firstname);
                setLastName((result.data.personalDetails.lastname === null || result.data.personalDetails.lastname === "") ? "" : result.data.personalDetails.lastname);
                setsOwOdO((result.data.personalDetails.guardianName === null || result.data.personalDetails.guardianName === "") ? "" : result.data.personalDetails.guardianName);
                setSelectedRel((result.data.personalDetails.patientRelationshipWithOwner === null) ? null : result.data.personalDetails.patientRelationshipWithOwner.id);
                setSelectedDate((result.data.personalDetails.dob === null || result.data.personalDetails.dob === "") ? "" : result.data.personalDetails.dob);
                setTextValue((result.data.personalDetails.pincode === null || result.data.personalDetails.pincode === "") ? "" : result.data.personalDetails.pincode);
                setSelectedMaritalStatus((result.data.personalDetails.maritialStatus === null) ? null : result.data.personalDetails.maritialStatus.value);
                setSelectedSexualOrientation((result.data.personalDetails.sexualOrientation === null) ? null : result.data.personalDetails.sexualOrientation.value);
                setImageInfo((result.data.personalDetails.profilePicture === null || result.data.personalDetails.profilePicture === "") ? "" : result.data.personalDetails.profilePicture.document);
                setSelectedGen((result.data.personalDetails.gender === null) ? null : result.data.personalDetails.gender.value);
                setSelectedBloodGroup((result.data.personalDetails.bloodGroup === null) ? null : result.data.personalDetails.bloodGroup.value);
                setCity((result.data.personalDetails.city === null || result.data.personalDetails.city === "") ? "" : result.data.personalDetails.city);
                setgetlanguagelist([]);
                let getlanguageList = [] as any;
                let getlanguageValue = [] as any;
                let languagevalue = result.data.personalDetails.languagePreferred;
                for (var i = 0; i < languagevalue.length; i++) {
                    getlanguageList.push(languagevalue[i].label);
                    getlanguageValue.push(languagevalue[i].value);
                }
                setgetlanguagelist(getlanguageList.join(', '));
                console.log(getlanguagelist);
                setLanguageSelected(getlanguageValue);
            })
            .catch(() => {
                setIsLoading(false);
            })
    }

    // Get relationship list
    const getRelaList = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/patientapp/api/patient/patient_relationship_list', {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Token ${userToken} `
            }
        })
            .then((res) => {
                setIsLoading(false);
                let response = res.data.patientRelationshipList;
                setRelationValues(response);
                if (patientid) {
                    getPatientDetailsById();
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setPatientrelatnlistError(error.response.data.message);
            })
    }

    // Get gender list
    const getGenList = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/indivoapp/api/gender/dropdown_list', {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Token ${userToken} `
            }
        })
            .then((res) => {
                setIsLoading(false);
                let response = res.data.genderList;
                setGenderValues(response);
            })
            .catch((error) => {
                setIsLoading(false);
                setPatientrelatnlistError(error.response.data.message);
            })
    }

    // Get Boold grp list
    const getBooldGrpList = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/patientapp/api/patient/patient_bloodgroup_list', {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Token ${userToken} `
            }
        })
            .then((res) => {
                setIsLoading(false);
                let response = res.data.bloodGroupList;
                setBloodGroupValues(response);
            })
            .catch((error) => {
                setIsLoading(false);
                setPatientrelatnlistError(error.response.data.message);
            })
    }

    // Get Language List
    const getlanguageAPI = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/doctorapp/api/onboarding/language_list_dropdown',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Token ${userToken}`
                }
            })
            .then((res) => {
                setIsLoading(false);
                setLanguage(res.data.languageList);

            })
            .catch((error) => {
                setIsLoading(false);
            })
    }
    // Get Marital Status List
    const getMaritalStatusAPI = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/patientapp/api/patient/maritial_list_dropdown',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Token ${userToken}`
                }
            })
            .then((res) => {
                setIsLoading(false);
                setMaritalStatusValues(res.data.maritialStatusList);

            })
            .catch((error) => {
                setIsLoading(false);
            })
    }
    // Get Sexual Orientation Status List
    const getSexualOrientationListAPI = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/patientapp/api/patient/sexualOrientation_dropdown',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Token ${userToken}`
                }
            })
            .then((res) => {
                setIsLoading(false);
                setSexualOrientationValues(res.data.sexualOrientationList);

            })
            .catch((error) => {
                setIsLoading(false);
            })
    }

    const [openconsent, setOpenConsent] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        getRelaList();
        getBooldGrpList();
        getGenList();
        getlanguageAPI();
        getMaritalStatusAPI();
        getSexualOrientationListAPI();
        if (patientid) {
            setOpenConsent(false);
        }
        else {
            setOpenConsent(true);
        }
        setSelectedDate(null);
    }, []);

    const uploadbody = {
        'typeName': 'patient-image'
    };



    const patientAddDesc = patientid ? (
        <div></div>
    ) : (
        <div>
            <Banner>
                <Scrollable shadow style={{ height: '200px' }}>
                    <InformConsent />
                </Scrollable>
            </Banner>
            <Box py="5">
                <TextStyle>By clicking “Submit” you have agreed to this informed consent</TextStyle>
            </Box>
        </div>
    );
    const formBody = (
        <Form onSubmit={addHealthRecord} >
            <FormLayout>
                <FormLayout.Group condensed>
                    <div>
                        <TextField
                            value={firstName}
                            label="First Name (Mandatory Field)"
                            placeholder="Enter Patient First Name "
                            id="FirstNameFieldID"
                            onChange={handleFirstNameChange}
                            readOnly={isEdit}
                        />
                        <br />
                        <TextField
                            value={lastName}
                            label="Last Name (Mandatory Field)"
                            placeholder="Enter Patient Last Name "
                            id="LastNameFieldID"
                            onChange={handleLastNameChange}
                            readOnly={isEdit}
                        />
                    </div>
                    <div>
                        <p style={{ marginBottom: "5px" }}>Patient Image Upload</p>
                        <Box style={{ textAlign: "center", border: "1px #50B83C solid", borderRadius: "3px" }} p={1}>
                            <Uploader
                                action="https://aims2health-webserver.herokuapp.com/storageapp/api/upload-image/"
                                draggable
                                data={uploadbody}
                                name="document"
                                listType="text"
                                onSuccess={(response: Object,) => {
                                    let res = JSON.stringify(response);
                                    setImageId(JSON.parse(res).image.id);
                                    setImageInfo(JSON.parse(res).image.document);
                                }}
                                disabled={isEdit}
                            >
                                <button style={styles} type="button">
                                    {uploading && <Loader backdrop center />}
                                    {imageInfo ? (
                                        <img src={imageInfo} width="100%" height="100%" style={{
                                            objectFit: 'cover',
                                            objectPosition: 'center',
                                            borderRadius: '50%'
                                        }} alt="" />
                                    ) : (
                                        <Icon icon="avatar" size="3x" />
                                    )}
                                </button>
                            </Uploader>
                        </Box>
                        <Caption>
                            <p style={{ color: 'gray' }}>Please upload profile picture of 350px (Width) X 500px (Height)</p>
                        </Caption>
                    </div>
                </FormLayout.Group>
                <FormLayout.Group condensed>
                    <div>
                        <p style={{ marginBottom: "5px" }}>Gender (Mandatory Field)</p>
                        <SelectPicker
                            block
                            onChange={handleGenSelectChange}
                            data={genderValues}
                            value={selectedGen}
                            disabled={isEdit}
                            menuStyle={{ zIndex: 200, color: "rgb(80, 184, 60) !important" }}
                        />
                    </div>
                    <div>
                        <p style={{ marginBottom: "5px" }}>Relationship</p>
                        <SelectPicker
                            block
                            onChange={handleRelationshipSelectChange}
                            data={relationValues}
                            value={selectedRel}
                            disabled={isEdit}
                            menuStyle={{ zIndex: 200, color: "rgb(80, 184, 60) !important" }}
                        />
                    </div>
                </FormLayout.Group>
                <FormLayout.Group condensed>
                    <div>
                        <p style={{ marginBottom: "5px" }}>Date of Birth (Mandatory Field)</p>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    autoOk
                                    inputVariant="outlined"
                                    fullWidth
                                    format="dd/MM/yyyy"
                                    value={selectedDate}
                                    InputAdornmentProps={{ position: "start" }}
                                    onChange={date => handleDateChange(date)}
                                    size="small"
                                    focused
                                    readOnly={isEdit}
                                />
                            </MuiPickersUtilsProvider>
                        </ThemeProvider>
                    </div>
                    <div>
                        <TextField
                            value={sOwOdO}
                            label="S/O. D/O. W/O."
                            placeholder="Enter S/O. D/O. W/O."
                            id="sOdOwO"
                            onChange={handleSOwOdOChange}
                            readOnly={isEdit}
                        />
                    </div>
                </FormLayout.Group>
                <FormLayout.Group condensed>
                    <div>
                        <p style={{ marginBottom: "5px" }}>Blood Group</p>
                        <SelectPicker
                            block
                            onChange={handleBloodGroupChange}
                            data={bloodGroupValues}
                            value={selectedBloodGroup}
                            disabled={isEdit}
                            menuStyle={{ zIndex: 200, color: "rgb(80, 184, 60) !important" }}
                        />
                    </div>
                    <div>
                        <p style={{ marginBottom: "5px" }}>Preferred Language</p>
                        <CheckPicker
                            sticky
                            placeholder="Please select language"
                            data={language}
                            value={languageSelected}
                            onSelect={handlelanguageChange}
                            onClean={onLanguageClear}
                            style={{ width: 1000 }}
                            menuStyle={{ zIndex: 200, color: "rgb(80, 184, 60) !important" }}
                            disabled={isEdit}
                        />
                    </div>
                </FormLayout.Group>
                <FormLayout.Group condensed>
                    <div>
                        <p style={{ marginBottom: "5px" }}>Marital Status</p>
                        <SelectPicker
                            block
                            onChange={handleMaritalStatusSelectChange}
                            data={maritalStatusValues}
                            value={selectedMaritalStatus}
                            disabled={isEdit}
                            menuStyle={{ zIndex: 200, color: "rgb(80, 184, 60) !important" }}
                        />
                    </div>
                    <div>
                        <p style={{ marginBottom: "5px" }}>Sexual Orientation</p>
                        <SelectPicker
                            block
                            onChange={handleSelectedSexualOrientationChange}
                            data={sexualOrientationValues}
                            value={selectedSexualOrientation}
                            disabled={isEdit}
                            menuStyle={{ zIndex: 200, color: "rgb(80, 184, 60) !important" }}
                        />
                    </div>
                </FormLayout.Group>
                <FormLayout.Group condensed>
                    <div>
                        <TextField
                            label="City"
                            value={city}
                            onChange={handleCityChange}
                            placeholder="Enter City"
                            readOnly={isEdit}
                        />
                    </div>
                    <div>
                        <TextField
                            label="Pin code"
                            value={textValue}
                            onChange={handleChange}
                            placeholder="Enter Pin code"
                            readOnly={isEdit}
                            maxLength={6}
                            minLength={6}
                        />
                    </div>
                </FormLayout.Group>
            </FormLayout>
            <Box my={3}>{successMessage}</Box>
            <Box my={3}>{editSuccessMessage}</Box>
            <Box my={3}>{errorpatientDiv}</Box>
            <Box my={3}>{errorpatientrelatnDiv}</Box>
            <Box>{errorDiv}</Box>
        </Form>
    )
    const ActualPageMarkup = (
        //edit health record part.........
        <Layout>
            {patientid ? (
                <Layout.Section>
                    <ThemeProvider theme={defaultMaterialTheme}>
                        <Card sectioned secondaryFooterActions={[{
                            content: isEdit ? 'Edit' : 'Cancel', onAction: () => {
                                setIsEdit((isEdit) => !isEdit);
                                setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
                            }
                        }]}
                            primaryFooterAction={{ content: "Update", onAction: addHealthRecord, disabled: isEnableSubmit }}
                        >
                            {formBody}
                        </Card>
                    </ThemeProvider>
                </Layout.Section>
            ) : ( //add health record part.........
                <Layout.AnnotatedSection
                    title={patientid ? "" : "Informed consent for patient"}
                    description={patientAddDesc}
                >
                    <Card sectioned primaryFooterAction={{ content: "Submit", onAction: addHealthRecord }}>
                        <Form onSubmit={addHealthRecord} >
                            <FormLayout>
                                <FormLayout.Group condensed>
                                    <div>
                                        <TextField
                                            value={firstName}
                                            label="First Name (Mandatory Field)"
                                            placeholder="Enter Patient First Name "
                                            id="FirstNameFieldID"
                                            onChange={handleFirstNameChange}
                                        />
                                        <br />
                                        <TextField
                                            value={lastName}
                                            label="Last Name (Mandatory Field)"
                                            placeholder="Enter Patient Last Name "
                                            id="LastNameFieldID"
                                            onChange={handleLastNameChange}
                                        />
                                    </div>
                                    <div>
                                        <p style={{ marginBottom: "5px" }}>Patient Image Upload</p>
                                        <Box style={{ textAlign: "center", border: "1px #50B83C solid", borderRadius: "3px" }} p={1}>
                                            <Uploader
                                                action="https://aims2health-webserver.herokuapp.com/storageapp/api/upload-image/"
                                                draggable
                                                data={uploadbody}
                                                name="document"
                                                listType="text"
                                                onSuccess={(response: Object,) => {
                                                    let res = JSON.stringify(response);
                                                    setImageId(JSON.parse(res).image.id);
                                                    setImageInfo(JSON.parse(res).image.document);
                                                }}
                                            >
                                                <button style={styles} type="button">
                                                    {uploading && <Loader backdrop center />}
                                                    {imageInfo ? (
                                                        <img src={imageInfo} width="100%" height="100%" style={{
                                                            objectFit: 'cover',
                                                            objectPosition: 'center',
                                                            borderRadius: '50%'
                                                        }} alt="" />
                                                    ) : (
                                                        <Icon icon="avatar" size="3x" />
                                                    )}
                                                </button>
                                            </Uploader>
                                        </Box>
                                        <Caption>
                                            <p style={{ color: 'gray' }}>Please upload profile picture of 350px (Width) X 500px (Height)</p>
                                        </Caption>
                                    </div>
                                </FormLayout.Group>
                                <FormLayout.Group condensed>
                                    <div>
                                        <p style={{ marginBottom: "5px" }}>Gender (Mandatory Field)</p>
                                        <SelectPicker
                                            block
                                            onChange={handleGenSelectChange}
                                            data={genderValues}
                                            value={selectedGen}
                                            menuStyle={{ zIndex: 200, color: "rgb(80, 184, 60) !important" }}
                                        />
                                    </div>

                                    <div>
                                        <p style={{ marginBottom: "5px" }}>Relationship</p>
                                        <SelectPicker
                                            block
                                            onChange={handleRelationshipSelectChange}
                                            data={relationValues}
                                            value={selectedRel}
                                            menuStyle={{ zIndex: 200, color: "rgb(80, 184, 60) !important" }}
                                        />
                                    </div>
                                </FormLayout.Group>
                                <FormLayout.Group condensed>
                                    <div>
                                        <p style={{ marginBottom: "5px" }}>Date of Birth (Mandatory Field)</p>
                                        <ThemeProvider theme={defaultMaterialTheme}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    autoOk
                                                    inputVariant="outlined"
                                                    fullWidth
                                                    format="dd/MM/yyyy"
                                                    value={selectedDate}
                                                    InputAdornmentProps={{ position: "start" }}
                                                    onChange={date => handleDateChange(date)}
                                                    size="small"
                                                    focused
                                                />
                                            </MuiPickersUtilsProvider>
                                        </ThemeProvider>
                                    </div>
                                    <div>
                                        <TextField
                                            value={sOwOdO}
                                            label="S/O. D/O. W/O."
                                            placeholder="Enter S/O. D/O. W/O."
                                            id="sOdOwO"
                                            onChange={handleSOwOdOChange}
                                        />
                                    </div>
                                </FormLayout.Group>
                                <FormLayout.Group condensed>
                                    <div>
                                        <p style={{ marginBottom: "5px" }}>Blood Group</p>
                                        <SelectPicker
                                            block
                                            onChange={handleBloodGroupChange}
                                            data={bloodGroupValues}
                                            value={selectedBloodGroup}
                                            menuStyle={{ zIndex: 200, color: "rgb(80, 184, 60) !important" }}
                                        />
                                    </div>
                                    <div>
                                        <p style={{ marginBottom: "5px" }}>Preferred Language</p>
                                        <CheckPicker
                                            sticky
                                            placeholder="Please select language"
                                            data={language}
                                            value={languageSelected}
                                            onSelect={handlelanguageChange}
                                            onClean={onLanguageClear}
                                            style={{ width: 1000 }}
                                            menuStyle={{ zIndex: 200, color: "rgb(80, 184, 60) !important" }}
                                        />
                                    </div>
                                </FormLayout.Group>
                                <FormLayout.Group condensed>
                                    <div>
                                        <p style={{ marginBottom: "5px" }}>Marital Status</p>
                                        <SelectPicker
                                            block
                                            onChange={handleMaritalStatusSelectChange}
                                            data={maritalStatusValues}
                                            value={selectedMaritalStatus}
                                            menuStyle={{ zIndex: 200, color: "rgb(80, 184, 60) !important" }}
                                        />
                                    </div>
                                    <div>
                                        <p style={{ marginBottom: "5px" }}>Sexual Orientation</p>
                                        <SelectPicker
                                            block
                                            onChange={handleSelectedSexualOrientationChange}
                                            data={sexualOrientationValues}
                                            value={selectedSexualOrientation}
                                            menuStyle={{ zIndex: 200, color: "rgb(80, 184, 60) !important" }}
                                        />
                                    </div>
                                </FormLayout.Group>
                                <FormLayout.Group condensed>
                                    <div>
                                        <TextField
                                            label="City"
                                            value={city}
                                            onChange={handleCityChange}
                                            placeholder="Enter City"
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            label="Pin code"
                                            
                                            value={textValue}
                                            onChange={handleChange}
                                            placeholder="Enter Pin code"
                                            maxLength={6}
                                            minLength={6}
                                        />
                                    </div>
                                </FormLayout.Group>
                            </FormLayout>
                            <Box my={3}>{successMessage}</Box>
                            <Box my={3}>{editSuccessMessage}</Box>
                            <Box my={3}>{errorpatientDiv}</Box>
                            <Box my={3}>{errorpatientrelatnDiv}</Box>
                            <Box>{errorDiv}</Box>
                        </Form>
                    </Card>
                </Layout.AnnotatedSection>
            )
            }
        </Layout>
    );

    return (
        <Page fullWidth title={patientid ? "Demographic Details " + "(" + firstName + " " + lastName + ")" : "Add Patient"}>
            {loadingMarkup}
            {ActualPageMarkup}
        </Page>
    );
}