import React, { useState, useCallback, useEffect } from 'react';
import {
    Loading, TextStyle, Modal, FormLayout, TextField, Button, Page, Link, Caption, Layout, DisplayText, TextContainer, Subheading, Banner
} from '@shopify/polaris';
import { makeStyles, createStyles, Theme, createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PatientFlow from '../image/design-assets/patient-flow-chart.png';
import { Box, Flex, } from '@primer/components';
import { Redirect, useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import ShareIcon from '@material-ui/icons/Share';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Divider } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { deletePatient, getPatientListValue } from '../../redux/effects/ApiCall';
import { AppState } from '../../redux/store/Store';
import { PatientList } from '../../redux/interfaces/Model';
import axios from 'axios';
import baseUrl from '../../Url';

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#BBE5B3',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        }
    },
    typography: {
        fontSize: 18,
    }
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            height: "300px"
        },
        details: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
        },
        content: {
            flex: '1 0 auto',
        },
        paper: {
            padding: theme.spacing(1),
            textAlign: "center",
            color: theme.palette.text.secondary
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        avatar: {
            backgroundColor: red[500],
        },
        statLabel: {
            fontSize: 12,
            fontWeight: 500,
            margin: 0,
        },
        statValue: {
            fontSize: 20,
            fontWeight: 'bold',
            marginBottom: 4,
            letterSpacing: '1px',
        },
    })
);

export default function PersonalHealthRecord() {
    /*  create a piece of state (toSignin)
    with a method to update that state (setToHome)
    and give it a default value of false */
    const [toSignin, setToSignin] = useState(false);
    const [modalActive, setModalActive] = useState(false);
    const [shareHealtRecord, setShareHealtRecord] = useState('');
    const loadingMarkup = <Loading />;
    const classes = useStyles();

    const history = useHistory();

    const truncate = (str: string,) => {
        return str.length > 10 ? str.substring(0, 20) + "..." : str;
    }

    const DATE_OPTIONS = { year: 'numeric', month: 'short', day: 'numeric' };
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPatientListValue());
    }, [dispatch]);

    const shareRecord = () => {
        setModalActive((modalActive) => !modalActive);
        setShareHealtRecord("");
    }

    const [deleteRecordModalMarkup, setDeleteModalActive] = useState(false);
    const handleDeleteRecordModalChange = useCallback(() => setDeleteModalActive(!deleteRecordModalMarkup), [deleteRecordModalMarkup]);

    let [patientId, setPatientId] = useState();

    function deleteRecord(patientId: any) {
        setDeleteModalActive((deleteRecordModalMarkup) => !deleteRecordModalMarkup);
        setPatientId(patientId)
    }

    const resetgetPasswordApicall = () => {

    }

    const handleMessageChangeHr = useCallback(
        (value) => setShareHealtRecord(value),
        [],
    );

    const closeSuccess = () => {
        setDeleteRecordSuccess('');
        setDeleteRecordError('');
    }

    const [deleteRecordSuccess, setDeleteRecordSuccess] = useState('');
    const successDelRecordDiv = deleteRecordSuccess
        ? <Banner
            onDismiss={closeSuccess}
            status="success">
            <p>{deleteRecordSuccess}</p>
        </Banner>
        : '';

    const [recordDeleteError, setDeleteRecordError] = useState('');
    const errorDelRecordDiv = recordDeleteError
        ? <Banner
            onDismiss={closeSuccess}
            status="critical">
            <p>{recordDeleteError}</p>
        </Banner>
        : '';

    const deleteBody = {
        "patientId": patientId,
        "isActive": false
    };

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }

    const DeleteRecordSubmit = () => {
        setDeleteModalActive((deleteRecordModalMarkup) => !deleteRecordModalMarkup);
        dispatch(deletePatient(deleteBody));
    }

    function BookAppointment(patientId: any) {
        axios.post(baseUrl.baseUrl + '/appointmentapp/api/appointment/request_appointment_by_patient',
            { "patientId": patientId }, { headers })
            .then((res) => {
                let resData = res.data;
                if(resData.caseId)
                {
                    history.push(`/filepreparation/${resData.patientId}/${resData.caseId}`);
                }
                else
                {
                    history.push(`/filepreparation/${resData.patientId}`);
                }
            })
            .catch((error) => {
                // setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            })
    }

    const DELETEPATIENTAPI = useSelector((state: AppState) => state.deletePatientValues);
    console.log("SUCCESSSTATUS==>", DELETEPATIENTAPI.response.status);
    console.log("SUCCESSMSG==>", DELETEPATIENTAPI.response.message);

    const emptyPageMarkup = (
        <Layout>
            <Layout.Section oneHalf>
                <Flex style={{ height: "50vh" }} alignItems="center" justifyContent="center">
                    <Box pt={5} mt={5}>
                        <DisplayText size="medium">Seek 2nd opinions from AIIMS trained doctors</DisplayText>
                        <p><br />One-stop solution for 2nd opinion and other e-healthcare services from top-notch specialists trained at AIIMS, New Delhi..<br /><br /></p>
                        <div>
                            <Button primary url="/demographicdetails">Add patient</Button>
                        </div>
                    </Box>
                </Flex>
            </Layout.Section>
            <Layout.Section oneHalf>
                <Box pt={5} mt={5}>
                    <div><img
                        width="100%"
                        height="100%"
                        style={{
                            objectFit: 'cover',
                            objectPosition: 'center',
                        }} src={PatientFlow} alt="" /></div>
                </Box>
            </Layout.Section>
        </Layout>
    );

    const PatientListFromAPI = useSelector((state: AppState) => state.patientValue);
    const actualPageMarkup = (
        <Box m={3}>
            <div>
                <ThemeProvider theme={defaultMaterialTheme}>
                    <Grid container spacing={1}>
                        <Grid container item sm={12} spacing={3}>
                            <React.Fragment>
                                {PatientListFromAPI.patientValue.map((patientItem: PatientList) => (
                                    <Grid item md={4}>
                                        {patientItem.id && (
                                            <Card className={classes.root}>
                                                <div className={classes.details}>
                                                    <CardHeader
                                                        avatar={
                                                            <img
                                                                id={`${patientItem.id}`}
                                                                src={patientItem.profilePicture === null ? 'medical.png' : patientItem.profilePicture.document}
                                                                alt=""
                                                                width="64"
                                                                height="64"
                                                                style={{
                                                                    objectFit: 'cover',
                                                                    objectPosition: 'center',
                                                                    borderRadius: "32px"
                                                                }}
                                                            />
                                                        }
                                                        action={[
                                                            <Link onClick={shareRecord}>
                                                                <IconButton aria-label="settings">
                                                                    <ShareIcon />
                                                                </IconButton>
                                                            </Link>,
                                                            <Link onClick={() => deleteRecord(patientItem.id)}>
                                                                <IconButton aria-label="settings">
                                                                    <MoreVertIcon />
                                                                </IconButton>
                                                            </Link>
                                                        ]}
                                                        title={<h5>{patientItem.firstname} {patientItem.lastname}</h5>}
                                                        subheader={<h6>{patientItem.patientRelationshipWithOwner?.displayValue}</h6>}
                                                    />
                                                    <CardContent>
                                                        <TextStyle>
                                                            {patientItem.description ? truncate(patientItem.description) : "Not Set"}
                                                        </TextStyle>
                                                    </CardContent>
                                                    <Divider light />
                                                    <Box alignItems="center" my={1} display={'flex'} className={classes.content}>
                                                        <Box justifyContent="center" p={2} flex={'auto'}>
                                                            <Caption>Gender</Caption>
                                                            <TextStyle variation="strong">{patientItem.gender?.label}</TextStyle>
                                                        </Box>
                                                        <Divider orientation="vertical" flexItem />
                                                        <Box justifyContent="center" p={2} flex={'auto'}>
                                                            <Caption>Blood Group</Caption>
                                                            <TextStyle variation="strong">{patientItem.bloodGroup === null ? "Not Known" : patientItem.bloodGroup.label}</TextStyle>
                                                        </Box>
                                                        <Divider orientation="vertical" flexItem />
                                                        <Box justifyContent="center" p={2} flex={'auto'}>
                                                            <Caption>Date of Birth</Caption>
                                                            <TextStyle variation="strong">{(new Date(patientItem.dob)).toLocaleDateString('en-US', DATE_OPTIONS)}</TextStyle>
                                                        </Box>
                                                    </Box>
                                                    <Divider light />
                                                    <CardActions>
                                                        <Link url={`/casehistory/${patientItem.id}`}>
                                                            <Button size="slim" primary>
                                                                Case Details
                                                            </Button>
                                                        </Link>
                                                        <Link>
                                                            <Button size="slim" onClick={() => BookAppointment(patientItem.id)}>
                                                                Book Appointment
                                                            </Button>
                                                        </Link>
                                                    </CardActions>
                                                </div>
                                            </Card>
                                        )}
                                    </Grid>
                                ))}
                            </React.Fragment>
                        </Grid>
                    </Grid>
                </ThemeProvider>
            </div>
        </Box >
    );

    const modalMarkup = (
        <Modal
            open={modalActive}
            onClose={shareRecord}
            title="Share health record"
            primaryAction={{
                content: 'Share',
                onAction: resetgetPasswordApicall,
            }}
        >
            <Modal.Section>
                <FormLayout>
                    <TextField
                        label="Share with people and groups"
                        placeholder="Add people and groups"
                        value={shareHealtRecord}
                        onChange={handleMessageChangeHr}
                        id="sharehealthrecord"
                    />
                </FormLayout>
            </Modal.Section>
        </Modal>
    );

    const deleteRecordModal = (
        <Modal
            open={deleteRecordModalMarkup}
            onClose={handleDeleteRecordModalChange}
            title={"Delete Patient"}
            primaryAction={{
                content: "Yes",
                onAction: DeleteRecordSubmit,
            }}
        >
            <Modal.Section>
                <TextContainer>
                    <Subheading>
                        Are you sure, you want to delete this Patient?
                    </Subheading>
                    <div>{successDelRecordDiv}</div>
                    <div>{errorDelRecordDiv}</div>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );
    return (
        <div>
            { toSignin ? <Redirect to="/signin" /> : null}
            <Page fullWidth>
                {PatientListFromAPI.loading ? loadingMarkup : ''}
                {DELETEPATIENTAPI.loading ? loadingMarkup : ''}
                {PatientListFromAPI.patientValue.length == 0 ? emptyPageMarkup : actualPageMarkup}
                {modalMarkup}
                {deleteRecordModal}
            </Page>
        </div>
    );
}