import React, { useEffect, useState } from 'react';
import {
    Card,
    Page,
} from '@shopify/polaris';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import PaymentIcon from '@material-ui/icons/Payment';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { StepIconProps } from '@material-ui/core/StepIcon';
import { Box, Flex } from '@primer/components';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import AppointmentSelectPatient from './AppointmentSelectPatient';
import AppointmentSelectDoctor from './AppointmentSelectDoctor';
import AppointmentBooking from './AppointmentBooking';
import AppointmentPayment from './AppointmentPayment';


const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(16, 128, 67) 0%,rgb(80, 184, 60) 50%,rgb(187, 229, 179) 100%)',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(16, 128, 67) 0%,rgb(80, 184, 60) 50%,rgb(187, 229, 179) 100%)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            'linear-gradient( 136deg,rgb(16, 128, 67) 0%,rgb(80, 184, 60) 50%,rgb(187, 229, 179) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
            'linear-gradient( 136deg,rgb(16, 128, 67) 60%,rgb(187, 229, 179) 100%)',
    },
});

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#BBE5B3',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#50B83C',
        }
    },
    typography: {
        fontSize: 20,
    }
});

function ColorlibStepIcon(props: StepIconProps) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: <SettingsIcon />,
        2: <GroupAddIcon />,
        3: <VideoLabelIcon />,
        4: <PaymentIcon />,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        button: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }),
);

function getSteps() {
    return ['Select Patient', 'Select Doctor', 'Appointment booking', 'Payment'];
}

interface AccountProps {
    onAction(): void;
}


export default function Appointment() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const [patientId, setpatientId] = useState(0);
    console.log("VALUE IN PARENT From Patient=>>", patientId);

    const [doctorId, setdoctorId] = useState(0);
    console.log("VALUE IN PARENT From Doctor=>>", doctorId);

    const [dateandtime, setdateandtime] = useState(0);
    console.log("VALUE IN PARENT From Appointment=>>", dateandtime);

    const [paymentDetails, setpaymentDetails] = useState(0);
    console.log("VALUE IN PARENT From Payment=>>", paymentDetails);

    useEffect(() => {
    }, []);

    const getStepContent = (stepper: number) => {
        switch (stepper) {
            case 0:
                return (
                    <AppointmentSelectPatient patientIdsend={setpatientId} />
                );
            case 1:
                return (
                    <AppointmentSelectDoctor doctorIdsend={setdoctorId} />
                );
            case 2:
                return (
                    <AppointmentBooking DateAndTime={setdateandtime} />
                );
            case 3:
                return (
                    <AppointmentPayment paymentDetails={setpaymentDetails}/>
                );
            default:
                return 'Unknown step';
        }
    };

    const pagebody = (
        <Card>
            <div className={classes.root}>
                <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box mx={5} p={5}>
                    {activeStep === steps.length ? (
                        <div>
                            <Typography className={classes.instructions}>
                                All steps completed - you&apos;re done
                            </Typography>
                            <Button onClick={handleReset} className={classes.button}>
                                Reset
                            </Button>
                        </div>
                    ) : (
                            <div>
                                <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                                <Flex justifyContent='end'>
                                    <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                        Back
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={classes.button}
                                    >
                                        {activeStep === steps.length - 1 ? 'Book Appointment' : 'Next'}
                                    </Button>
                                </Flex>
                            </div>
                        )}
                </Box>
            </div>
        </Card>
    );

    return (
            <Page
                fullWidth
                title="Appointment"
            >
                <ThemeProvider theme={defaultMaterialTheme}>
                    {pagebody}
                </ThemeProvider>
            </Page>
    );
}