import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import LoginScreen from '../../image/design-assets/featureimage-patient.svg';
import logo from '../../image/logo/aims2health-with-caption.png';
import { Box, Flex } from '@primer/components';
import baseUrl from '../../../Url';
import {
  Button,
  FormLayout,
  TextField,
  Layout,
  TextStyle,
  Banner,
  Loading,
  InlineError,
  Page,
  DisplayText,
  Link,
  Stack,
  Caption,
  Toast,
  Form
} from '@shopify/polaris';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles, Theme, createStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { InputLabel } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
  }),
);

const useOutlinedInputStyles = makeStyles(theme => ({
  root: {
    "& $notchedOutline": {
      borderColor: "#50B83C",
      outlineWidth: 0,
    },
    "&:hover $notchedOutline": {
      borderColor: "#50B83C",
      outlineWidth: 0,
    },
    "&$focused $notchedOutline": {
      borderColor: "#50B83C",
      outlineWidth: 0,
    },
    height: "38px",
    background: "white"
  },
  input: {
    "&::placeholder": {
      color: "#50B83C"
    },
    color: "#00000", // if you also want to change the color of the input, this is the prop you'd use   
  },
  focused: {},
  notchedOutline: {}
}));

export default function Signup() {

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#50B83C",
      },
      secondary: {
        main: '#50B83C',
      },
      text: {
        primary: "#000000",
        secondary: "#50B83C"
      }
    },
    typography: {
      fontSize: 22,
    },
    overrides: {
      MuiInputBase: {
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
        },
      },
    },
  });
  const outlinedInputClasses = useOutlinedInputStyles();

  const history = useHistory();
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  // const [password, setPass] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');

  const [error, setError] = useState('');
  const [errormail, setErrorMail] = useState('');
  const [errorotp, setErrorOtp] = useState('');
  const [errorFstName, setErrorFstName] = useState('');
  const [errorLstName, setErrorLstName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const errorEmail = errormail
    ? <TextStyle variation="negative">{errormail}</TextStyle>
    : '';

  const errorOtp = errorotp
    ? <TextStyle variation="negative">{errorotp}</TextStyle>
    : '';
  const errorFirstName = errorFstName
    ? <TextStyle variation="negative">{errorFstName}</TextStyle>
    : '';
  const errorLastName = errorLstName
    ? <TextStyle variation="negative">{errorLstName}</TextStyle>
    : '';

  const BannerClose = () => {
    setError('');
  }
  const errorDiv = error ? (
    <Banner status="critical" onDismiss={BannerClose}>{error}</Banner>
  ) : '';

  const sectionForm = isLoading ? <Loading /> : null;

  const handleEmailChange = useCallback((value) => setEmail(value), []);
  const handleGenerateOtp = useCallback((value) => {
    setOtp(value);
  }, []);
  const handlePasswordChange = useCallback((value) => setPass(value), []);
  const handleFirstnameChange = useCallback((value) => setFirstname(value), []);
  const handleLastnameChange = useCallback((value) => setLastname(value), []);
  const [isDisableOTPField, setIsDisableOTPField] = useState(true);
  const [isDisableFastnameField, setIsDisableFastnameField] = useState(true);
  const [isDisableLastnameField, setIsDisableLastnameField] = useState(true);
  const [isDisablePassword, setIsDisablePassword] = useState(true);
  const [buttonText, setButtonText] = useState('Generate OTP');
  const [signsuccessMessage, setsignsuccessMessage] = useState('');
  const [generateOtpSuccessMessage, setGenerateOtpSuccessMessage] = useState('');
  const [generateOtpErrorMessage, setGenerateOtpErrorMessage] = useState('');
  const [errorSignupMsg, setErrorSignupMsg] = useState('');
  const closeSuccess = () => {
    setsignsuccessMessage('');
    history.push("/signin");
  }

  interface State {
    password: string;
    showPassword: boolean;
  }

  const [password, setPass] = React.useState<State>({
    password: '',
    showPassword: false,
  });

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setPass({ ...password, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setPass({ ...password, showPassword: !password.showPassword });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const closeSuccessBanner = () => {
    setGenerateOtpSuccessMessage('');
  }

  const successDiv = signsuccessMessage ? (
    <Toast content={signsuccessMessage} onDismiss={closeSuccess} />
  ) : null;

  const otpsuccessDiv = generateOtpSuccessMessage
    ? <Banner onDismiss={closeSuccessBanner} status="success"><p>{generateOtpSuccessMessage}</p></Banner>
    : '';
  const closeOtpErrorBanner = () => {
    setGenerateOtpErrorMessage('');
  }

  const otpErrorDiv = generateOtpErrorMessage
    ? <Banner onDismiss={closeOtpErrorBanner} status="critical"><p>{generateOtpErrorMessage}</p></Banner>
    : '';

  // ******* generate otp section modal **********

  const otpText = buttonText;
  const generateOtpBody = JSON.stringify({
    email: email.toLowerCase()
  });
  const generateOtpApicall = () => {
    if (email === '') {
      setErrorMail('Email id or phone number should not be empty');
    }
    else if ((new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email.toLowerCase())) === false && (new RegExp(/^[0]?[6789]\d{9}$/).test(email.toLowerCase())) === false) {
      setErrorMail('Please enter valid email id or phone number');
    }
    else {
      setErrorMail('');
      setIsLoading(true);
      axios.post(baseUrl.baseUrl + '/patientapp/email-otp-generation/',
        generateOtpBody, { headers })
        .then((res) => {
          setIsLoading(false);
          setGenerateOtpErrorMessage("");
          setGenerateOtpSuccessMessage(res.data.message);
          setIsDisableOTPField(false);
          setIsDisableFastnameField(false);
          setIsDisableLastnameField(false);
          setIsDisablePassword(false);
          setButtonText("Resend OTP");
        })
        .catch((err) => {
          setIsLoading(false);
          setGenerateOtpSuccessMessage("");
          setGenerateOtpErrorMessage(err.response.data.message);
          setButtonText("Send OTP");
        })
    }
  }
  const body = JSON.stringify({
    email: email.toLowerCase(),
    password: String(password.password),
    first_name: firstname,
    last_name: lastname,
    otp: otp
  });

  const headers = {
    'Accept': 'application/json',
    'Content-type': 'application/json'
  }


  const handleSignup = () => {
    if (email === '') {
      setErrorMail('Email id or phone number should not be empty');
      setErrorOtp("");
      setErrorFstName("");
      setErrorLstName("");
    }
    else if (otp === '') {
      setErrorOtp(" Enter Valid OTP");
      setErrorFstName(" Enter First Name");
      setErrorLstName("Enter Last Name");
    }
    else if (firstname === '') {
      setErrorOtp("");
      setErrorFstName(" Enter First Name");
      setErrorLstName(" Enter Last Name");
    }
    else if (lastname === "") {
      setErrorOtp("");
      setErrorFstName("");
      setErrorLstName("Enter Last Name");
    }
    else {
      setErrorSignupMsg('');
      setIsLoading(true);
      axios.post(baseUrl.baseUrl + '/patientapp/email-otp-signup/',
        body, { headers })
        .then(result => {
          setIsLoading(false);
          if (result) {
            setGenerateOtpErrorMessage("");
            setsignsuccessMessage(result.data.message)
          }
        })
        .catch((err) => {
          console.log(err.response.data.message);
          setGenerateOtpSuccessMessage("");
          setGenerateOtpErrorMessage("")
          setsignsuccessMessage("");
          setError(err.response.data.message);
          setIsLoading(false);
        })
    }
  }

  const actualPageMarkup = (
    <Layout>
      <Layout.Section oneHalf>
        <Flex alignItems="center" justifyContent="center">
          <Box m={4}>
            <Link url="https://aims2health.com"><img src={logo} alt="Logo" height="60" /></Link>
            <Box my={3}>
              <DisplayText size="large">Register to AIMS2Health</DisplayText>
              <p>2<sup>nd</sup> opinion from Ex-AIIMS Specialist Doctors</p>
            </Box>
            <br />
            <Box my={3}>
              <Form onSubmit={handleSignup}>
                <FormLayout>
                  <TextField
                    value={email}
                    label="Email or Phone Number"
                    placeholder="We would like to verify your email or phone number first"
                    id="emailFieldID"
                    onChange={handleEmailChange}
                  />
                  <InlineError message={errorEmail} fieldID="emailFieldID" />
                  <div>{otpsuccessDiv}</div>
                  <div>{otpErrorDiv}</div>
                  <div>
                    <Button fullWidth primary onClick={generateOtpApicall}>{otpText}</Button>
                  </div>
                  <TextField
                    value={otp}
                    label="OTP "
                    placeholder="Enter OTP"
                    id="otpId"
                    onChange={handleGenerateOtp}
                    disabled={isDisableOTPField}
                  />
                  <InlineError message={errorOtp} fieldID="otpId" />
                  <FormLayout.Group condensed>
                    <div>
                      <TextField
                        value={firstname}
                        label="First Name"
                        placeholder="Enter First Name"
                        onChange={handleFirstnameChange}
                        disabled={isDisableFastnameField}
                        id="firstnameID"
                      />
                      <InlineError message={errorFirstName} fieldID="firstnameID" />
                    </div>
                    <div>
                      <TextField
                        value={lastname}
                        label="Last Name"
                        placeholder="Enter Last Name"
                        onChange={handleLastnameChange}
                        disabled={isDisableLastnameField}
                        id="lastnameID"
                      />
                      <InlineError message={errorLastName} fieldID="lastnameID" />
                    </div>
                  </FormLayout.Group>
                  {/* <TextField
                    value={password}
                    label="Password (Optional)"
                    type="password"
                    placeholder="If you want to login with password please enter"
                    id="passwordFieldID"
                    onChange={handlePasswordChange}
                    disabled={isDisablePassword}
                  /> */}
                  <label>Password (Optional)</label>
                  <ThemeProvider theme={theme}>
                    <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      placeholder="Enter your password"
                      type={password.showPassword ? 'text' : 'password'}
                      value={password.password}
                      onChange={handleChange('password')}
                      disabled={isDisablePassword}
                      fullWidth
                      classes={outlinedInputClasses}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {password.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={0}
                    />
                  </ThemeProvider>
                  <div>
                    <Caption>&nbsp;&nbsp;&nbsp;&nbsp;By clicking Register, you agree to our &nbsp;&nbsp;
                      <Link url="https://aims2health.com/termsofuse.html" external>Terms of Use</Link>&nbsp;&nbsp;and &nbsp;&nbsp;
                      <Link url="https://aims2health.com/privacy-statement.html" external>Privacy Policy</Link>
                    </Caption>
                  </div>
                  <div>
                    <Button fullWidth primary onClick={handleSignup} disabled={isDisableLastnameField}>Register</Button>
                  </div>
                  <hr />
                  <TextStyle variation="strong">Old User?{' '}&nbsp;&nbsp;
                    <Button url='/signin'>Log In</Button>
                  </TextStyle>
                  <div>{errorDiv}</div>
                </FormLayout>
              </Form>
            </Box>
          </Box>
        </Flex>
      </Layout.Section>
      <Layout.Section oneHalf>
        <Box pt={5} mt={5}>
          <div><img
            width="100%"
            height="100%"
            style={{
              objectFit: 'cover',
              objectPosition: 'center',
            }} src={LoginScreen} alt="Logo" /></div>
        </Box>
      </Layout.Section>
      <Layout.Section>
        <Stack>
          <Stack.Item fill>
            <Caption>© Copyright AIMS2Health 2021. All rights reserved.</Caption>
          </Stack.Item>
        </Stack>
      </Layout.Section>
    </Layout>
  );
  return (
    <Page fullWidth>
      {actualPageMarkup}
      {sectionForm}
      {successDiv}
    </Page>
  );
}