import React, { useState, useCallback, useEffect } from 'react';
import baseUrl from '../../Url';
import {
    Card,
    Page,
    Button,
    Modal,
    FormLayout,
    TextField,
    InlineError,
    Banner,
    TextStyle,
    Spinner,
    DescriptionList,
    Loading,
} from '@shopify/polaris';
import axios from 'axios';

import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles, Theme, createStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { InputLabel } from '@material-ui/core';
import { Box } from '@primer/components';

const useOutlinedInputStyles = makeStyles(theme => ({
    root: {
        "& $notchedOutline": {
            borderColor: "#50B83C",
            outlineWidth: 0,
        },
        "&:hover $notchedOutline": {
            borderColor: "#50B83C",
            outlineWidth: 0,
        },
        "&$focused $notchedOutline": {
            borderColor: "#50B83C",
            outlineWidth: 0,
        },
        height: "38px",
        background: "white"
    },
    input: {
        "&::placeholder": {
            color: "#50B83C"
        },
        color: "#00000", // if you also want to change the color of the input, this is the prop you'd use   
    },
    focused: {},
    notchedOutline: {}
}));

export default function UserProfile() {
    const theme = createMuiTheme({
        palette: {
            primary: {
                main: "#50B83C",
            },
            secondary: {
                main: '#330101',
            },
            text: {
                primary: "#000000",
                secondary: "#50B83C"
            }
        },
        typography: {
            fontSize: 22,
        },
        overrides: {
            MuiInputBase: {
                input: {
                    '&:-webkit-autofill': {
                        transitionDelay: '9999s',
                        transitionProperty: 'background-color, color',
                    },
                },
            },
        },

    });

    const outlinedInputClasses = useOutlinedInputStyles();

    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userName, setUsername] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const [optFieldDisable, setOTPFieldDisable] = useState(true);

    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;

    // Close Email OTP Generation Success Banner
    const closeOTPGenerationSuccess = () => {
        setOtpGenerationSuccess('');
    }
    // Email OTP Generation Success Banner
    const [otpGenerationSuccess, setOtpGenerationSuccess] = useState('');
    const OTPGenerationSuccessDiv = otpGenerationSuccess
        ? <Banner status="success" onDismiss={closeOTPGenerationSuccess}><p>{otpGenerationSuccess}</p></Banner>
        : '';
    // Close Email OTP Generation Error Banner
    const closeOTPGenerationError = () => {
        setOtpGenerationError('');
    }
    // Email OTP Generation Error Banner
    const [otpGenerationError, setOtpGenerationError] = useState('');
    const OTPGenerationErrorDiv = otpGenerationError
        ? <Banner status="critical" onDismiss={closeOTPGenerationError}><p>{otpGenerationError}</p></Banner>
        : '';

    interface oldPasswordState {
        oldPassword: string;
        showPassword: boolean;
    }

    const [oldPassword, setOldPassword] = React.useState<oldPasswordState>({
        oldPassword: '',
        showPassword: false,
    });

    const handleOldPasswordChange = (prop: keyof oldPasswordState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setOldPassword({ ...oldPassword, [prop]: event.target.value });
    };

    const handleOldPasswordClickShowPassword = () => {
        setOldPassword({ ...oldPassword, showPassword: !oldPassword.showPassword });
    };
    const handleOldMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const [errorsupportold, setErrorOldpassword] = useState('');
    const errorSupportold = errorsupportold
        ? <TextStyle variation="negative">{errorsupportold}</TextStyle>
        : '';

    // New Password Field
    interface NewPasswordState {
        newPassword: string;
        showNewPassword: boolean;
    }

    const [newPassword, setNewPassword] = React.useState<NewPasswordState>({
        newPassword: '',
        showNewPassword: false,
    });

    const handleNewPasswordChange = (prop: keyof NewPasswordState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword({ ...newPassword, [prop]: event.target.value });
    };

    const handleNewPasswordClickShowPassword = () => {
        setNewPassword({ ...newPassword, showNewPassword: !newPassword.showNewPassword });
    };

    const handleNewPasswordMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const [errorsupportnew, setErrorNewpassword] = useState('');
    const errorSupportnew = errorsupportnew
        ? <TextStyle variation="negative">{errorsupportnew}</TextStyle>
        : '';

    // Add Phone Number field
    const [addPhone, setAddPhone] = useState('');
    const handleAddPhoneChange = useCallback((newValue) => {
        setAddPhone(newValue);
    }, []);

    const [otp, setOTP] = useState('');
    const handleOTPChange = useCallback((newValue) => {
        setOTP(newValue);
    }, []);

    const [otpFieldError, setOTPFieldError] = useState('');
    const otpFieldErrorDiv = otpFieldError
        ? <TextStyle variation="negative">{otpFieldError}</TextStyle>
        : '';

    // Reset Password Modal
    const [resetPasswordModalActive, setResetPasswordModalActive] = useState(false);
    const resetPassword = () => {
        setResetPasswordModalActive((resetPasswordModalActive) => !resetPasswordModalActive)
        setOldPassword({
            oldPassword: '',
            showPassword: false,
        });
        setErrorOldpassword("");
        setNewPassword({
            newPassword: '',
            showNewPassword: false,
        });
        setErrorNewpassword("");
        setForgotPasswordResetSuccess('');
        setForgotPasswordResetError('');
    }

    const [forgotPasswordResetSuccess, setForgotPasswordResetSuccess] = useState('');
    const forgotPasswordResetSuccessDiv = forgotPasswordResetSuccess
        ? <Banner>{forgotPasswordResetSuccess}</Banner>
        : '';
    const [forgotPasswordResetError, setForgotPasswordResetError] = useState('');
    const forgotPasswordResetErrorDiv = forgotPasswordResetError
        ? <Banner status="critical">{forgotPasswordResetError}</Banner>
        : '';

    const [isPopupLoading, setisPopupLoading] = useState(false);
    const sectionForm = isPopupLoading ? (
        <Spinner
            accessibilityLabel="Loading form field"
            hasFocusableParent={false}
            size="small"
        />
    ) : (
        null
    );

    const [addEmail, setAddEmail] = useState('');
    const handleAddEmailChange = useCallback((newValue) => {
        setAddEmail(newValue);
    }, []);
    const [addEmailError, setAddEmailError] = useState('');
    const addEmailErrordiv = addEmailError
        ? <TextStyle variation="negative">{addEmailError}</TextStyle>
        : '';

    // Add Phone Number Modal
    const [addPhoneModalactive, setAddPhoneModalactive] = useState(false);
    const handleAddPhoneModalChange = () => {
        setAddPhoneModalactive((addPhoneModalactive) => !addPhoneModalactive);
        setAddPhoneError('')
        setAddPhone('');
        setOTP('');
        setOtpGenerationSuccess('');
        setOtpGenerationError('');
        setAddPhoneAPISuccess('');
        setAddPhoneAPIError('');
    };

    const [addPhoneError, setAddPhoneError] = useState('');
    const addPhoneErrordiv = addPhoneError
        ? <TextStyle variation="negative">{addPhoneError}</TextStyle>
        : '';


    const emailotpbody = JSON.stringify({
        email: addEmail.toLowerCase()
    });

    const photpbody = JSON.stringify({
        email: addPhone.toLowerCase()
    });

    // Add Email Modal
    const [addEmailModalactive, setAddEmailModalactive] = useState(false);
    const handleAddEmailModalChange = () => {
        setAddEmailModalactive((addEmailModalactive) => !addEmailModalactive);
        setAddEmailError('');
        setOTP('');
        setOtpGenerationSuccess('');
        setOtpGenerationError('');
        setAddEmailAPISuccess('');
        setAddEmailAPIError('');
    };

    const [addEmailAPISuccess, setAddEmailAPISuccess] = useState('');
    const addEmailAPISuccessDiv = addEmailAPISuccess
        ? <Banner status="success"><p>{addEmailAPISuccess}</p></Banner>
        : '';
    const [addEmailAPIError, setAddEmailAPIError] = useState('');
    const addEmailAPIErrorDiv = addEmailAPIError
        ? <Banner status="critical"><p>{addEmailAPIError}</p></Banner>
        : '';

    const [addPhoneAPISuccess, setAddPhoneAPISuccess] = useState('');
    const addPhoneAPISuccessDiv = addPhoneAPISuccess
        ? <Banner status="success"><p>{addPhoneAPISuccess}</p></Banner>
        : '';
    const [addPhoneAPIError, setAddPhoneAPIError] = useState('');
    const addPhoneAPIErrorDiv = addPhoneAPIError
        ? <Banner status="critical"><p>{addPhoneAPIError}</p></Banner>
        : '';

    const token = function () {
        return localStorage.getItem("token");
    }
    let userToken: string = token()!
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }

    // Email OTP Generation API
    const emailOtpGeneration = () => {
        if (addEmail === '') {
            setAddEmailError("Please enter email id");
            return;
        }
        else if ((new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(addEmail.toLowerCase())) === false && (new RegExp(/^[0]?[6789]\d{9}$/).test(addEmail.toLowerCase())) === false) {
            setAddEmailError('Please enter valid email id');
        }
        else {
            setIsLoading(true);
            setAddEmailError('');
            axios.post(baseUrl.baseUrl + '/patientapp/email-otp-generation/',
                emailotpbody, { headers })
                .then(result => {
                    setIsLoading(false);
                    if (result) {
                        setOtpGenerationSuccess(result.data.message);
                        setOTPFieldDisable(false);
                    }
                    else {
                        // history.push("/signin");
                    }
                })
                .catch((error) => {
                    setOtpGenerationError(error.response.data.message);
                    setIsLoading(false);
                })
        }
    };

    // Email Verification Body
    const emailVerificationBody = JSON.stringify({
        email: addEmail.toLowerCase(),
        otp: otp
    });

    // Email OTP Verification API
    const emailOtpVerification = () => {
        if (addEmail === '' && otp === '') {
            setAddPhoneError("Please enter phone number");
            setOTPFieldError("Please otp");
        }
        else if (addEmail === '' && otp != '') {
            setAddPhoneError("Please enter phone number");
            setOTPFieldError("");
        }
        else if (addEmail != '' && otp === '') {
            setAddPhoneError("Please enter phone number");
            setOTPFieldError("Please otp");
        }
        else {
            setIsLoading(true);
            setAddEmailError('');
            setOtpGenerationSuccess('');
            setOtpGenerationError('');
            axios.post(baseUrl.baseUrl + '/profileapp/api/userDetails/set_email_or_phone',
                emailVerificationBody, { headers })
                .then(result => {
                    setIsLoading(false);
                    setAddEmailAPISuccess(result.data.message);
                    getUserProfile();
                    handleAddEmailModalChange();
                })
                .catch((error) => {
                    setAddEmailAPIError(error.response.data.message);
                    setIsLoading(false);
                })
        }
    };

    // Phone OTP Generation API
    const phoneOtpGeneration = () => {
        if (addPhone === '') {
            setAddPhoneError("Please enter phone number");
        }
        else {
            setIsLoading(true);
            setAddPhoneError('');
            axios.post(baseUrl.baseUrl + '/patientapp/email-otp-generation/',
                photpbody, { headers })
                .then(result => {
                    setIsLoading(false);
                    if (result) {
                        setOtpGenerationSuccess(result.data.message);
                        setOTPFieldDisable(false);
                    }
                    else {
                        // history.push("/signin");
                    }
                })
                .catch((error) => {
                    setOtpGenerationError(error.response.data.message);
                    setIsLoading(false);
                })
        }
    };

    // Phone Verification Body
    const phoneverificationBody = JSON.stringify({
        email: addPhone,
        otp: otp
    });

    // Phone OTP Verification API
    const phoneOtpVerification = () => {
        if (addPhone === '' && otp === '') {
            setAddPhoneError("Please enter email id");
            setOTPFieldError("Please otp");
        }
        else if (addPhone === '' && otp != '') {
            setAddPhoneError("Please enter email id");
            setOTPFieldError("");
        }
        else if (addPhone != '' && otp === '') {
            setAddPhoneError("");
            setOTPFieldError("Please otp");
        }
        else {
            setIsLoading(true);
            setAddEmailError('');
            setOtpGenerationSuccess('');
            setOtpGenerationError('');
            axios.post(baseUrl.baseUrl + '/profileapp/api/userDetails/set_email_or_phone',
                phoneverificationBody, { headers })
                .then(result => {
                    setIsLoading(false);
                    setAddPhoneAPISuccess(result.data.message);
                    getUserProfile();
                    handleAddPhoneModalChange();
                })
                .catch((error) => {
                    setAddPhoneAPIError(error.response.data.message);
                    setIsLoading(false);
                })
        }
    };

    // Reset Password Body
    const resetbody = JSON.stringify({
        "old_password": oldPassword.oldPassword,
        "new_password": newPassword.newPassword
    });

    // Reset Password API
    const resetgetPasswordApicall = () => {
        if (oldPassword.oldPassword === '') {
            setErrorOldpassword('Please enter old password');
        }
        else if (newPassword.newPassword === '') {
            setErrorNewpassword('Please enter new password');
        }
        else {
            setErrorOldpassword('');
            setErrorNewpassword('');
            setisPopupLoading(true);
            axios.put(baseUrl.baseUrl + '/profileapp/reset-password/',
                resetbody, { headers })
                .then(result => {
                    setisPopupLoading(false);
                    setForgotPasswordResetSuccess(result.data.message);
                })
                .catch((error) => {
                    setisPopupLoading(false);
                    setForgotPasswordResetError(error.response.data.message);
                })
        }
    }

    // Get userprofile API
    const getUserProfile = () => {
        axios.get(baseUrl.baseUrl + '/profileapp/api/userDetails/get_details', { headers })
            .then(result => {
                //setIsLoading(false);
                let userDetails = result.data.userDetails;
                setUsername(userDetails.username);
                setFirstName(userDetails.firstName);
                setLastName(userDetails.lastName);
                setEmail(userDetails.email);
                setPhoneNumber(userDetails.phone);
            })
            .catch((error) => {

                //setIsLoading(false);
            })
    }

    useEffect(() => {
        getUserProfile();
    }, []);

    // Reset Password Modal
    const modalMarkup = (
        <Modal
            open={resetPasswordModalActive}
            onClose={resetPassword}
            title="Reset Password"
            primaryAction={{
                content: 'Reset',
                onAction: resetgetPasswordApicall,
            }}
            secondaryActions={[{
                content: 'Cancel',
                onAction: resetPassword,
            }]}
        >
            <Modal.Section>
                <FormLayout>
                    <div>
                        <label>Old Password</label>
                        <Box pt={2}>
                            <ThemeProvider theme={theme}>
                                <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
                                <OutlinedInput
                                    id="oldpassword"
                                    placeholder="Enter your old password"
                                    type={oldPassword.showPassword ? 'text' : 'password'}
                                    value={oldPassword.oldPassword}
                                    onChange={handleOldPasswordChange('oldPassword')}
                                    fullWidth
                                    classes={outlinedInputClasses}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleOldPasswordClickShowPassword}
                                                onMouseDown={handleOldMouseDownPassword}
                                                edge="end"
                                            >
                                                {oldPassword.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    labelWidth={0}
                                />
                            </ThemeProvider>
                        </Box>
                        <InlineError message={errorSupportold} fieldID="oldpassword" />
                    </div>
                    <div>
                        <label>New Password</label>
                        <Box pt={2}>
                            <ThemeProvider theme={theme}>
                                <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
                                <OutlinedInput
                                    id="newpassword"
                                    placeholder="Enter your password"
                                    type={newPassword.showNewPassword ? 'text' : 'password'}
                                    value={newPassword.newPassword}
                                    onChange={handleNewPasswordChange('newPassword')}
                                    fullWidth
                                    classes={outlinedInputClasses}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleNewPasswordClickShowPassword}
                                                onMouseDown={handleNewPasswordMouseDownPassword}
                                                edge="end"
                                            >
                                                {newPassword.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    labelWidth={0}
                                />
                            </ThemeProvider>
                        </Box>
                        <InlineError message={errorSupportnew} fieldID="newpassword" />
                    </div>
                    <div>{forgotPasswordResetSuccessDiv}</div>
                    <div>{forgotPasswordResetErrorDiv}</div>
                </FormLayout>
                <div style={{ textAlign: 'center' }}>{sectionForm}</div>
            </Modal.Section>
        </Modal>
    );

    // Add Email Modal
    const addEmailModalMarkup = (
        <Modal
            open={addEmailModalactive}
            onClose={handleAddEmailModalChange}
            title="Add Email"
            primaryAction={{
                content: 'Add',
                onAction: emailOtpVerification,
            }}
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: handleAddEmailModalChange,
                },
            ]}
        >
            <Modal.Section>
                <FormLayout.Group>
                    <div>
                        <TextField
                            label="Email"
                            value={addEmail}
                            onChange={handleAddEmailChange}
                            placeholder="Enter email"
                            id="emailID"
                        />
                        <InlineError message={addEmailErrordiv} fieldID="emailID" />
                    </div>
                    <div>
                        <br />
                        <Button onClick={emailOtpGeneration}>Generate OTP</Button>
                    </div>
                </FormLayout.Group>
                <FormLayout.Group>{OTPGenerationSuccessDiv}</FormLayout.Group>
                <FormLayout.Group>{OTPGenerationErrorDiv}</FormLayout.Group>
                <FormLayout.Group>
                    <div>
                        <TextField
                            label="OTP"
                            value={otp}
                            onChange={handleOTPChange}
                            placeholder="Enter OTP"
                            id="otpID"
                            disabled={optFieldDisable}
                        />
                        <InlineError message={otpFieldErrorDiv} fieldID="otpID" />
                    </div>
                </FormLayout.Group>
                <FormLayout.Group>{addEmailAPISuccessDiv}</FormLayout.Group>
                <FormLayout.Group>{addEmailAPIErrorDiv}</FormLayout.Group>
            </Modal.Section>
        </Modal>
    )

    // Add Phone Modal
    const addPhoneModalMarkup = (
        <Modal
            open={addPhoneModalactive}
            onClose={handleAddPhoneModalChange}
            title="Add Phone Number"
            primaryAction={{
                content: 'Add',
                onAction: phoneOtpVerification,
            }}
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: handleAddPhoneModalChange,
                },
            ]}
        >
            <Modal.Section>
                <FormLayout.Group>
                    <div>
                        <TextField
                            label="Phone Number"
                            value={addPhone}
                            onChange={handleAddPhoneChange}
                            placeholder="Enter Phone Number"
                            id="phoneID"
                        />
                        <InlineError message={addPhoneErrordiv} fieldID="phoneID" />
                    </div>
                    <div>
                        <br />
                        <Button onClick={phoneOtpGeneration}>Generate OTP</Button>
                    </div>
                </FormLayout.Group>
                <FormLayout.Group>{OTPGenerationSuccessDiv}</FormLayout.Group>
                <FormLayout.Group>{OTPGenerationErrorDiv}</FormLayout.Group>
                <FormLayout.Group>
                    <div>
                        <TextField
                            label="OTP"
                            value={otp}
                            onChange={handleOTPChange}
                            placeholder="Enter OTP"
                            id="otpID"
                            disabled={optFieldDisable}
                        />
                        <InlineError message={otpFieldErrorDiv} fieldID="otpID" />
                    </div>
                </FormLayout.Group>
                <FormLayout.Group>{addPhoneAPISuccessDiv}</FormLayout.Group>
                <FormLayout.Group>{addPhoneAPIErrorDiv}</FormLayout.Group>
            </Modal.Section>
        </Modal>
    )

    // Table Markup
    const userprofilePageMarkup = (
        <Card>
            <Card.Header>
                <Button onClick={getUserProfile}>Reload</Button>
                <Button onClick={resetPassword}>Reset Password</Button>
            </Card.Header>
            <Card.Section>
                <DescriptionList
                    items={[
                        {
                            term: 'Username',
                            description: userName
                        },
                        {
                            term: 'First Name',
                            description: firstName
                        },
                        {
                            term: 'Last Name',
                            description: lastName
                        },
                        {
                            term: 'Email',
                            description: email === "" ? (<Button onClick={handleAddEmailModalChange}>Add Email</Button>) : email 
                        },
                        {
                            term: 'Phone Number',
                            description: phoneNumber === "" ? (<Button onClick={handleAddPhoneModalChange}>Add Phone Number</Button>) : phoneNumber
                        },
                    ]}
                />
            </Card.Section>
        </Card>
    )
    return (
        <Page fullWidth title="User Details">
            {loadingMarkup}
            {userprofilePageMarkup}
            {modalMarkup}
            {addEmailModalMarkup}
            {addPhoneModalMarkup}
        </Page>
    );
}
