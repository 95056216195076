import React from 'react';
import { } from 'react-router-dom';
import {
    Card,
    DisplayText,
    List,
    Page
} from '@shopify/polaris';

interface AccountProps {
    onAction(): void;
}

export default function CodeOfConduct() {

    const pagebody = (
        <div>
            <Card title="Code of Conduct for Patients using AIMS2Health Platform" sectioned>
                <p>The future of remote video consultations with your doctor appears to be promising, as holding virtual sessions continue to achieve robust, almost real-life experiences among participants. Quality of communication and of care is central to success of telemedicine and therefore to the success of AIMS2Health.</p>
                <Card.Section title="Basic Do’s and Don’ts">
                    <List>
                        <List.Item>Do uploading personal health information and documents, including images.</List.Item>
                        <List.Item>Don’t delay start time. Be on-time for the video call. If you are new to video calling, test your audio beforehand and do by video calling a friend or a relative. Do take online guidance in the use of the AIMS2Health Platform, if needed.</List.Item>
                        <List.Item>Do make sure, before joining your video call, that your hardware works (smartphone, microphone, speakers, and headphones) and that your internet speed is fast enough to support a decent level of quality for a video call.</List.Item>
                        <List.Item>Don’t be in a noisy place. Find a quiet, private space or use a white noise machine so that you can hear your meeting clearly. Keep small children and pets out of your room. Shut the doors and windows of your room. Microphones pick up faint sounds like typing, coughing, chewing, tapping your pen, noise/conversations in nearby spaces. Don’t carry on side conversations/chats while the video call is going on.</List.Item>
                        <List.Item>Don’t ignore the camera positioning and lighting. Make sure the camera is in a stable position and focused at your eye level. Don’t sit too far from (or too close to) the camera. Make sure you have good lighting; face your lighting. Natural side lighting is usually the best. Without proper lighting, you may either be ghosted out (too much light from the wrong angle), or too dark to see if there is not enough light in the room.</List.Item>
                        <List.Item>Don't forget that appearances (your and of your surroundings) matter in video meetings. Dress appropriately. Don’t look at your phone and don’t eat! To other participants, an untidy area around you can be a major distraction.</List.Item>
                        <List.Item>Do identify yourself at the start of the meeting and all others in the patient team.</List.Item>
                        <List.Item>Don’t let yourself get distracted during the meeting. Don’t multitask during video meetings. Be attentive and engaged during the call. As tempting as it is, try not to do any other work or read articles or send emails.</List.Item>
                        <List.Item>Do minimize distractions. Mute other technology. If you are sharing your screen with the other participants, try to disable pop-up notifications, close or minimize running applications and mute your cell phones, streaming radio, etc.</List.Item>
                        <List.Item>Do let the doctor know if you do want any recording of telephone and video consultation to be kept long term.</List.Item>
                        <List.Item>Don’t waste time during the videocall by being unprepared. To be organized be prepared with your contributions or ask questions regarding your doubts. Come prepared with all relevant facts. Preferably, keep a written note. Be prepared to disclose fully and accurately all relevant medical information.</List.Item>
                        <List.Item>Do mute yourself when not talking. Your microphone can pick up a lot of background noise, so muting allows others on the call to easily hear who’s speaking.</List.Item>
                        <List.Item>Speak clearly and watch how fast you speak (and don’t forget to unmute yourself!). If there’s a pause in the conversation because, for instance, you need to pull up an email or reference a document, make sure to communicate that.</List.Item>
                        <List.Item>If you do need to share your screen during a video call, take a few seconds to prepare before you hit that share button. Clear your desktop of any extra tabs or programs you may have open and make sure any private or sensitive information is hidden.</List.Item>
                        <List.Item>Do share ALL your concerns, fears, needs, anxieties, questions, expectations and wishes about your illness and its treatment or management.</List.Item>
                        <List.Item>Do not continue the video consultation if during it you find it in appropriate for your needs. Seek an in person appointment.</List.Item>
                    </List>
                </Card.Section>
            </Card>

            <Card title="Quality of communication and of care is central to success of telemedicine and therefore to the success of AIMS2Health." sectioned>
                <p>One of the great challenges for all patient-physician interactions is communication. It can be difficult for patients to clearly communicate their concerns, leaving gaps in physicians’ information and understanding. It can also be even more difficult for patients to understand what physicians tell them. Patients may lack the technical sophistication to understand complicated medical information. Or anxiety about their condition gets in the way of their understanding. Or both. The electronic medium might make the process even more challenging. Will the newness of the technology further impede comprehensive communication? What will patients expect in terms of their ability to communicate with and understand their physicians? Will a new “language” be developed that can be better used to leverage the nature of telemedicine? If an electronic doctor visit is followed by a written transcript or summary, could overall communication be improved by telemedicine?</p>
                <Card.Section title="Guidance in the use of the AIMS2Health Platform">
                    <p>Patients and their team members who are new to virtual visits may need extra guidance about what to expect and how to prepare. If you are not sure about what to do to make effective use of the AIMS2Health platform, use the helpline service or pose your questions to the telemedicine coordinator of AIMS2Health. Amongst other things, be sure about the connectivity and proper use of the video-conferencing facilities of AIMS2Health. A representative of AIMS2Health is available to assist you if you face any problem. AIMS2Health would have someone simulate a brief practice appointment so you can walk through each step from beginning to end.</p>
                    <br />
                    <DisplayText size="small">Will your patient:</DisplayText>
                    <br />
                    <List>
                        <List.Item>Have a computer, tablet, or mobile device with camera and microphone that will work with your telehealth platform?</List.Item>
                        <List.Item>Have an internet connection with enough bandwidth for video (if required for the appointment)?</List.Item>
                        <List.Item>Be able to do the appointment from somewhere with secure internet (home, work, another location)? Will the patient be using public wifi and therefore need to understand how your telehealth platform handles security?</List.Item>
                        <List.Item>Need help setting up an email account (if your telehealth platform requires it)?</List.Item>
                    </List>
                </Card.Section>
            </Card>

            <Card title="Uploading of personal health information and documents, including images" sectioned>
                <p>The secure exchange and storage of telemedicine data between devices, systems AIMS2Health and specialists is required to protect patient privacy, but also ensures data availability for appropriate patient care.</p>
                <Card.Section title="Guidance in the use of the AIMS2Health Platform">
                    <List>
                        <List.Item>Ensure uploading of all relevant information before the tele-consultation so that the Specialist concerned with your case can be provided an excellent and timely summary of your medical problems and associated concerns, anxieties, fears, expectations and wishes to study it and prepare for a telephone or video-consultation.</List.Item>
                        <List.Item>Make sure that all the images of uploads are clear and the text legible.</List.Item>
                        <List.Item>Digital pathology images is becoming increasingly in demand among different settings but sometimes you may have to mail in the original slides to ensure a proper examination of the images/slides for arriving at a correct diagnosis/interpretation.</List.Item>
                        <List.Item>In radiology some images are analog while others are digital. Many images are inherently digital: computed tomography, magnetic resonance, ultrasound, nuclear medicine, computed radiography, digital radiography, and digital fluoroscopy. All can be directly linked to a teleradiology system if they are in a standard format. The DICOM 3 standard is important to teleradiology because a direct digital connection can be made from the image source to the teleradiology server and then from the teleradiology-receiving computer to a diagnostic workstation of the Specialist.</List.Item>
                        <List.Item>File sizes for typical digitized medical images are large. To reduce the amount of digital data to be transmitted, the digital data can be compressed prior to transmission. Make sure you can do it or get it done. Compression can be “lossless” (reversible), with compression ratios typically in the range of 3:1, and the original dataset can be fully regenerated, or “lossy” (irreversible), where much higher compression ratios are possible. Compression ratios of at least 10:1 are generally required before data compression can have a significant economic effect. Although lossy compression requires some loss from the original dataset, several studies have shown that compression ratios of 15:1 provide diagnostically acceptable lossy image compression for example for mammography. Check with AIMS2Health what is acceptable for a particular type of medical image. Bandwidth issues in parts of India may hinder transmission of an uncompressed or lossless compressed image. In these cases, make judicious use of appropriate levels of lossy image compression to speed the initial arrival of the images, to keep your interests foremost. If this approach is required, make sure the original images are available for subsequent download as required. Poor bandwidth should not lead to the provision of a suboptimal image data set by a patient or a referring imaging centre/doctor, where the clinical context suggests that any data loss might put you (the patient) at risk.</List.Item>
                    </List>
                </Card.Section>
            </Card>

            <Card title="Timeliness and punctuality" sectioned>
                <Card.Section title="Guidance in the use of the AIMS2Health Platform">
                    <List>
                        <List.Item>Ensure timeliness and punctuality (at least five minutes early) in online appointments with a doctor or a member of his/her team. If a number of others are part of the patient team, they are expected to be punctual too.</List.Item>
                        <List.Item>Maintain a reminder diary for a telemedicine phone or video call appointment</List.Item>
                        <List.Item>Pre-test video conference connectivity with the doctor’s team</List.Item>
                    </List>
                </Card.Section>
            </Card>

            <Card title="Physical appearance" sectioned>
                <p>Be appropriately dressed for video consultations. Wear loose fitting clothes or with buttons or zippers so that are easy to remove, lift or open. This would help in avoiding loss of time when a visual inspection of your body needs to be done by the doctor or to take a photograph.</p>
            </Card>

            <Card title="Identification" sectioned>
                <List>
                    <List.Item>Ensure that you (patient, guardian or other representative of the patient) identify yourself appropriately to the doctor and/or members/representatives of the doctor’s team. Positive patient identification is essential in telemedicine. The absence of a positive patient identification system results in patients not being matched accurately against his or her assigned electronic health record (EHR) of AIMS2Health.</List.Item>
                    <List.Item>If the video consultation appointment is for a minor (child), make sure that the minor is present and if the parent/guardian should or should not also be present along with the minor (child).</List.Item>
                </List>
            </Card>

            <Card title="Telemedicine video consultation etiquette" sectioned>
                <Card.Section title="Civilized conduct">
                    <List>
                        <List.Item>In audio, written or  visual interactions with staff and doctors of AIMS2Health, a patient or guardian or authorized representative of a patient should always display courteous behaviour, respectful demeanour and civilized conduct.</List.Item>
                    </List>
                </Card.Section>
                <Card.Section title="Set up or obtain access to consultation space that:">
                    <List>
                        <List.Item>is quiet and fit for purpose</List.Item>
                        <List.Item>has visual and audio privacy of the room(s) of the patient and of the rooms of patient team’s members</List.Item>
                        <List.Item>has arrangements to protect the privacy and dignity of patients who may be required to remove clothing for a physical examination (e.g., a screen in the room or a separate private area where patients can remove clothing and be suitably covered with a gown or drape ahead of the video consultation)</List.Item>
                        <List.Item>has plain decor that will not distract from visual images on the screen</List.Item>
                        <List.Item>has good lighting, avoiding high-intensity light (e.g., a window) behind the patient who is being viewed</List.Item>
                        <List.Item>has ready access to peripheral medical devices that may be required during a video consultation </List.Item>
                        <List.Item>Keep your pets out of the room used for the tele-consultation</List.Item>
                        <List.Item>have access to a phone as a back-up if the video call fails.</List.Item>
                        <List.Item>develop and maintain documented contingency plans for managing technical problems during a video consultation (e.g., completing interrupted consultations by telephone)</List.Item>
                        <List.Item>ensure all participants in the patient team are aware of the contingency plan (e.g., who will call whom)</List.Item>
                    </List>
                </Card.Section>
                <Card.Section title="">
                    <List>
                        <List.Item>Be aware that the microphones attached to the telemedicine camera picks up all sound in the room equally to the extent that even when whispering everything can be heard.  Use the “mute” button when necessary to hold a discussion not to be heard from the other telemedicine endpoint.</List.Item>
                    </List>
                </Card.Section>
            </Card>

            <Card title="Communicate truthfully" sectioned>
                <Card.Section>
                    <List>
                        <List.Item>Come prepared with all relevant facts. Preferably, keep a written note. Be prepared to disclose fully and accurately all relevant medical information. You shouldn’t hesitate to ask you questions, and share all your concerns, anxieties, fears, expectations and wishes. Only then the doctor would be able to understand your context and priorities.</List.Item>
                        <List.Item>Provide truthful answers to all questions of AIMS2Health doctors.</List.Item>
                        <List.Item>If the patient has an interpreter or caregiver present, please let your doctor know whether you feel comfortable permitting the other person to hear any personal information or not.</List.Item>
                    </List>
                </Card.Section>
                <Card.Section title="Recording of telephone and video consultation">
                    <List>
                        <List.Item>You should not record the phone or video consultation on your own. Take approval of the doctor before doing so. </List.Item>
                        <List.Item>Ask the doctor whether and for how long the orally shred information shared information would be recorded and preserved as such or summarized in text and stored as notes. All Medical Record entries should be made as soon as possible after the care is provided, or an event or observation is made.</List.Item>
                    </List>
                </Card.Section>
                <Card.Section title="Release of Confidential personal health/medical information">
                    <p>Your health information (Medical Record) with the AIMS2Health Portal and associated specialist doctors is confidential and is protected from unauthorized disclosure by law. AIMS2Health and associated specialist doctors are not authorized to release it to anyone unless its use or disclosure is required or otherwise permitted by the applicable law. However, if you wish it to be release confidentially then you should fill out a release of information consent form and make sure that person or entity requesting your personal health information has the legal standing to receive the information requested. Evidence of legal authority may require a witness signature or notary public seal on the request form, evidence of the relationship between the requestor and the patient, documentation from a court of competent jurisdiction, or other means.</p>
                    <br />
                    <DisplayText size="small">Consent form must contain the following: </DisplayText>
                    <List>
                        <List.Item>Patient's name</List.Item>
                        <List.Item>Requester’s name (if other than the patient</List.Item>
                        <List.Item>Name of facility releasing the information (AIMS2Health Pvt Ltd)</List.Item>
                        <List.Item>Name of individual or individuals, agency or agencies to whom information being released</List.Item>
                        <List.Item>Information to be released</List.Item>
                        <List.Item>Purpose for the release</List.Item>
                        <List.Item>Length of time consent is valid (365 days or less)</List.Item>
                    </List>
                </Card.Section>
            </Card>

            <Card title="Appropriateness of telemedicine and video consultations" sectioned>
                <p>Keep in mind your access to medical care, urgency of need, suitability of telemedicine, risk posed by delay and other relevant factors as there are inherent limitations of the digital environment in providing hands on care, direct inspection and physical examination. Whether telemedicine is appropriate for a particular patient depends on what access the individual otherwise has to health care. For some patients, in some situations, it simply may not be feasible to receive care in person. When the options for a patient are to receive care that may be less than ideal via telemedicine or not to receive care at all, telemedicine services can be appropriate even though the physician, patient, or their surrogate, would prefer that care be provided in person. For example, for a villager in a remote rural area, telemedicine—whatever its limitations—may be the only way to provide medical services. For such a person in an isolated rural setting a 24-h drive from a specialist, telemedicine may be preferable even when an in-person encounter would be superior.</p>
            </Card>
        </div>
    )

    return (
        <div>
            <Page fullWidth>
                {pagebody}
            </Page>
        </div>
    );
}