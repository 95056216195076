import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import {
    ActionList,
    Card,
    Layout,
    Page,
    Popover,
    Stack,
    TextStyle,
    Toast,
} from '@shopify/polaris';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import GroupIcon from '@material-ui/icons/Group';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PaymentIcon from '@material-ui/icons/Payment';
import PostAddIcon from '@material-ui/icons/PostAdd';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import CallIcon from '@material-ui/icons/Call';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import { StepIconProps } from '@material-ui/core/StepIcon';
import { Box, Flex } from '@primer/components';
import { createMuiTheme, StepButton, ThemeProvider } from '@material-ui/core';
import AppointmentSelectPatient from './AppointmentSelectPatient';
import AppointmentSelectDoctor from './AppointmentSelectDoctor';
import AppointmentBooking from './AppointmentBooking';
import AppointmentPayment from './AppointmentPayment';
import AddhealthRecord from './FilePreparation/AddHealthRecord';
import ConsultationOption from './ConsultationOption';
import AddPatientTeam from './AddPatientTeam';
import DoctorPatientInteraction from './DoctorPatientInteraction';
import FinalOpinion from './FinalOpinion';
import axios from 'axios';
import baseUrl from '../../Url';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../redux/store/Store';
import { CaseList } from '../../redux/interfaces/Model';
import { getEncounterList } from '../../redux/effects/ApiCall';
import * as _ from 'lodash';

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(16, 128, 67) 0%,rgb(80, 184, 60) 50%,rgb(187, 229, 179) 100%)',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(16, 128, 67) 0%,rgb(80, 184, 60) 50%,rgb(187, 229, 179) 100%)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            'linear-gradient( 136deg,rgb(236, 28, 36) 0%,rgb(255, 194, 196) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
            'linear-gradient( 136deg,rgb(16, 128, 67) 60%,rgb(16, 128, 67) 100%)',
    },
});

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#BBE5B3',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#50B83C',
        }
    },
    typography: {
        fontSize: 20,
    }
});

function ColorlibStepIcon(props: StepIconProps) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: <SettingsIcon />,
        2: <GroupIcon />,
        3: <ContactPhoneIcon />,
        4: <FileCopyIcon />,
        5: <GroupAddIcon />,
        6: <PostAddIcon />,
        7: <PaymentIcon />,
        8: <EmojiPeopleIcon />,
        9: <CallIcon />
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        button: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }),
);

export default function FilePreparation() {
    const [isLoading, setIsLoading] = useState(false);
    const userToken = localStorage.getItem("token");
    const [toSignin, setToSignin] = useState(false)
    const { patientid } = useParams() as {
        patientid: string;
    };
    console.log("PARAMITER_PATIENT ID===>", patientid);
    const { caseid } = useParams() as {
        caseid: string;
    };
    const { patientName } = useParams() as {
        patientName: string;
    };
    const { whyWant2ndOpinion } = useParams() as {
        whyWant2ndOpinion: string;
    };
    const { rowIndex } = useParams() as {
        rowIndex: string,
    };
    const { paymentRedirect } = useParams() as {
        paymentRedirect: string;
    };
    console.log("ParamId===", paymentRedirect);
    const paymentTabConfirm = localStorage.getItem("paymentRedirect");
    console.log("LocalstorageID===", paymentTabConfirm);
    const [caseName, setCaseName] = useState("");

    const taskPaymentId = localStorage.getItem("taskId");
    console.log("PatientTaskId", taskPaymentId);

    const [deleteCaseErrorToastActive, setDeleteCaseErrorToastActive] = useState(false);
    const [deleteCaseToastMessage, setDeleteCaseToastMessage] = useState("");
    const handleDeleteCaseToastActive = useCallback(() => setDeleteCaseErrorToastActive((deleteCaseErrorToastActive) => !deleteCaseErrorToastActive), []);
    const toastMarkup = deleteCaseErrorToastActive ? (
        <Toast content={deleteCaseToastMessage} error onDismiss={handleDeleteCaseToastActive} />
    ) : null;

    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [steps, setSteps] = useState([]);
    const [stepDetails, setStepDetails] = useState<any[]>([]);

    const truncate = (str: string,) => {
        return str.length > 0 ? str.substring(0, 55) + "..." : str;
    }

    const [popoverActive, setPopoverActive] = useState(false);
    const togglePopoverActive = useCallback(
        () => setPopoverActive((popoverActive) => !popoverActive),
        [],
    );
    const activator = (
        <Button 
            startIcon={<SettingsIcon />}
            onClick={() => togglePopoverActive()}
            >
        </Button>
    );

    const handleNext = () => {

        // if (localStorage.getItem("consultationopt") === 'Quick Video Consultation') {
        //     setActiveStep(3);
        //     localStorage.setItem("consultationopt", '');
        //     localStorage.removeItem("paymentRedirect");
        // }
        // if(localStorage.getItem("opiniontype") === String(2) && localStorage.getItem("responseTime") === 'Speedy Consult (First response by a doctor in 8 working hours)')
        // {
        //    setActiveStep(2);
        //    localStorage.removeItem("paymentRedirect");
        //    console.log("NEXTNOTWORKING");
        //    setActiveStep((prevActiveStep) => prevActiveStep + 1);
        // }

        if (activeStep === steps.length - 1) {
            //window.location.href = (`/casehistory/${patientid}`);
            history.goBack();
            localStorage.removeItem("paymentRedirect");
        }
        else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };

    const [completed, setCompleted] = React.useState(new Set<number>());
    function isStepComplete(step: number) {
        // const newCompleted = new Set(completed);
        // newCompleted.add(activeStep);
        // setCompleted(newCompleted);
        // setCompleted(step);
        return completed.has(step);
    }

    const getStepsAPI = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/secondopinionapp/api/patientcaseui/steps_and_sections',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Token ${userToken}`
                }
            })
            .then((res) => {
                setIsLoading(false);
                console.log(isLoading);
                setStepDetails(res.data.SecondOpinionUi);
                setSteps(res.data.SecondOpinionUi.map(function (el: { displayValue: any; }) { return el.displayValue; }));
                if ((paymentRedirect === "1") && (paymentTabConfirm === "1")) {
                    console.log("Get Value Condition True");
                    setActiveStep(5);
                }
                else if ((paymentRedirect === "2") && (paymentTabConfirm === "2")) {
                    console.log("Get Value Condition True");
                    setActiveStep(3);
                }
                else if (paymentTabConfirm === "0" && paymentTabConfirm === "0") {
                    console.log("Condition True");
                    setActiveStep(1);
                }
                else if (patientid) {
                    setActiveStep(1);
                }
                else if (localStorage.getItem("opiniontype") === String(2) && localStorage.getItem("responseTime") === 'Speedy Consult (First response by a doctor in 8 working hours)') {
                    setActiveStep(2);
                    localStorage.removeItem("paymentRedirect");
                    console.log("NEXTNOTWORKING");
                    localStorage.setItem("opiniontype", '');
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }
    const getStepContent = (stepper: number) => {
        switch (stepDetails[stepper].name) {
            case "selectPatient":
                return (
                    <AppointmentSelectPatient />
                );
            case "consultationType":
                return (
                    <ConsultationOption />
                );
            case "filePreparation":
                return (
                    <AddhealthRecord />
                );
            case "selectDoctor":
                return (
                    <AppointmentSelectDoctor />
                );
            case "bookAppointments":
                return (
                    <AppointmentBooking />
                );
            case "payment":
                return (
                    <AppointmentPayment />
                );
            case "patientTeam":
                return (
                    <AddPatientTeam />
                );
            case "doctorpatientinteraction":
                return (
                    <DoctorPatientInteraction />
                );
            case "secondopinion":
                return (
                    <FinalOpinion />
                );
            default:
                return 'Unknown step';
        }
    };
    const dispatch = useDispatch();
    // Call tassk Store

    const PatientListFromAPI = useSelector((state: AppState) => state.caseValue);
    const caseDetails: CaseList = _.filter(PatientListFromAPI.caseValue, ['caseId', Number(caseid)])[0];

    const casehistoryname = useSelector((state: AppState) => state.caseValue.patientname.patientName);

    const history = useHistory();
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };
    // Delete body....
    const deletePastHistoryBody = {
        "patientCaseId": Number(caseid),
        "isActive": false
    }
    const deleteCase = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/deactivate_patient_case',
            deletePastHistoryBody, { headers })
            .then((res) => {
                setIsLoading(false);
                history.push(`/casehistory/${patientid}`);
            })
            .catch((error) => {
                setIsLoading(false);
                setDeleteCaseToastMessage(error.response.data.message);
                setDeleteCaseErrorToastActive(true);
            })
    }

    const pagebody = (
        <Card>
            <div className={classes.root}>
                <Stepper alternativeLabel nonLinear activeStep={activeStep} connector={<ColorlibConnector />}>
                    {steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepButton onClick={handleStep(index)} completed={isStepComplete(index)}>
                                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                </StepButton>
                            </Step>
                        );
                        // <Step key={label}>
                        //     {/* <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel> */}
                        //     <StepButton key={label} onClick={handleStep(index)} completed={handleComplete}>
                        //         <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        //     </StepButton>
                        // </Step>
                    })}
                </Stepper>
                <Box p={3}>
                    {activeStep === steps.length ? (
                        ''
                    ) : (
                        <Layout>
                            <Layout.Section>
                                <TextStyle>{getStepContent(activeStep)}</TextStyle>
                            </Layout.Section>
                            <Layout.Section>
                                <Stack>
                                    <Stack.Item fill>
                                        &nbsp;
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Flex justifyContent='end'>
                                            <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                                Back
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                                className={classes.button}
                                            >
                                                {activeStep === steps.length - 1 ? 'Close' : 'Next'}
                                            </Button>
                                        </Flex>
                                    </Stack.Item>
                                </Stack>
                            </Layout.Section>
                        </Layout>
                    )}
                </Box>
            </div>
        </Card>
    );

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getEncounterList(patientid));
        getStepsAPI();
    }, [dispatch]);

    return (
        <Page
            fullWidth
            breadcrumbs={[{ content: 'Previous Section', onAction: () => { history.goBack() } }]}
            title={`${(caseDetails === undefined) ? `${(casehistoryname === undefined) ? "" : casehistoryname + "'s Details"}` : `${(casehistoryname === undefined) ? "" : casehistoryname + "'s Details"}`}`}
            subtitle={`${(caseDetails === undefined) ? "" : (caseDetails.whyWant2ndOpinion)}`}
            primaryAction={
                <Popover
                    active={popoverActive}
                    activator={activator}
                    onClose={togglePopoverActive}
                >
                    <ActionList items={[{ content: 'Delete case', onAction: deleteCase },]} />
                </Popover>
            }
            >
            {toSignin ? <Redirect to="/signin" /> : null}
            <ThemeProvider theme={defaultMaterialTheme}>
                {pagebody}
            </ThemeProvider>
            {toastMarkup}
        </Page>
    );
}