import React from 'react';
import ReactDOM from 'react-dom';
import { AppProvider } from '@shopify/polaris';
import '@shopify/polaris/dist/styles.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Theme from './theme';
import './style.css';
import { Provider } from 'react-redux';
import store from './redux/store/Store';

ReactDOM.render(
  <AppProvider i18n={{
    Polaris: {
      Avatar: {
        label: 'Avatar',
        labelWithInitials: 'Avatar with initials {initials}',
      },

      TextField: {
        characterCount: '{count} characters',
      },
      TopBar: {
        toggleMenuLabel: 'Toggle menu',
      },
      Modal: {
        iFrameTitle: 'body markup',
      },
      Frame: {
        skipToContent: 'Skip to content',
        Navigation: {
          closeMobileNavigationLabel: 'Close navigation',
        },
      },
    },
  }}
    features={{ newDesignLanguage: true }}
    theme={Theme}
  >
    <Provider store={store}>
      <App />
    </Provider>
    {/* <App /> */}
  </AppProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
