import React, { useCallback, useState } from 'react';
import { Box} from '@primer/components';
import {
  Layout,
  Page,
  Tabs,
} from '@shopify/polaris';
import SharePatientList from './SharePatientList';
import PersonalHealthRecord from './PersonalHealthRecord';

export default function Dashboard() {
  const [selected, setSelected] = useState(0);
  const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), []);

  const tabs = [
    {
      id: 'emaillogin',
      content: (
        <span>
          My patient list 
        </span>
      ),
      body: (
        <PersonalHealthRecord />
      )
      ,
      panelID: 'emaillogin-content',
    },
    {
      id: 'passwordlogin',
      content: (
        <span>
          Shared patient list 
        </span>
      ),
      body: (
        <div>
          <SharePatientList/>
        </div>
      ),
      accessibilityLabel: 'All customers',
      panelID: 'passwordlogin-content',
    },
  ]


  const actualPageMarkup = (
    <Layout>
      <Layout.Section>
            <Box my={1}>
              <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} >
                {tabs[selected].body}
              </Tabs>
            </Box>
      </Layout.Section>
    </Layout>
  );


  return (
    <Page title="Patient List" fullWidth>
      {actualPageMarkup}
    </Page>

  );
}
