import React, { useCallback, useEffect, useState } from 'react';
import { Banner, Button, Caption, Card, DataTable, FormLayout, InlineError, Loading, Modal, Page, Stack, TextContainer, TextField, Thumbnail, Toast } from '@shopify/polaris';
import { Box } from '@primer/components';
import { Icon, Uploader } from 'rsuite';
import { NoteMinor, EditMinor } from '@shopify/polaris-icons';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { createMuiTheme, IconButton } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import axios from 'axios';
import baseUrl from '../../Url';
import { useParams } from 'react-router-dom';

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#50B83C',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        }
    },
    typography: {
        fontSize: 18,
    }
});

export default function InsuranceDetails() {
    const { patientid } = useParams() as {
        patientid: string;
    };
    const [toSignin, setToSignin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;

    const [imageInfo, setImageInfo] = useState('');
    const [uploading] = React.useState(false);
    const [imageID, setImageID] = useState('');

    const [insuranceno, setInsuranceno] = useState('');
    const handleInsuranceChange = useCallback((value) => {
        setInsuranceno(value);
    }, []);
    const [insuranceAmmount, setInsuranceAmmount] = useState('');
    const handleInsuranceAmmountChange = useCallback((value) => setInsuranceAmmount(value), []);

    const [selectedDate, setSelectedDate] = React.useState<Date | null>(
        new Date(),
    );
    const handleDateChange = (enddate: Date | null) => {
        setSelectedDate(enddate);
    };

    const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(
        new Date(),
    );
    const handleEndDateChange = (enddate: Date | null) => {
        setSelectedEndDate(enddate);
    };

    const [insurancedetail, setInsuranceDetails] = useState('');
    const handleInsuranceDetailsChange = useCallback((value) => {
        setInsuranceDetails(value);
    }, []);

    const [insuranceNoError, setInsuranceNoError] = useState('');
    const inuranceNoErrordiv = insuranceNoError
        ? <InlineError message={insuranceNoError} fieldID="insuranceID" />
        : '';

    const [insuranceList, setInsuranceList] = useState<any[]>([]);
    const [addInsurancesModalActive, setaddInsurancesModalActive] = useState(false);
    const [insuranceDetailId, setinsuranceDetailId] = useState('');

    const addInsuranceToggle = () => {
        setaddInsurancesModalActive((addInsurancesModalActive) => !addInsurancesModalActive);
        setInsuranceno('');
        setInsuranceNoError('');
        setInsuranceno('');
        setSelectedDate(new Date());
        setSelectedEndDate(new Date());
        setImageID('');
        setImageInfo('');
        setInsuranceDetails('');
        setinsuranceDetailId('');
        setinsuranceSubmitAPIRes("");
        setInsuranceAmmount('');
        setinsuranceSubmitAPIResError("");
    }

    // Image upload body
    const uploadbody = {
        'typeName': 'patienthealthinsurance'
    };

    // Page body
    const body = {
        "patientId": patientid,
        insuranceNumber: insuranceno,
        amount: insuranceAmmount,
        fromDate: selectedDate,
        toDate: selectedEndDate,
        supportdocId: imageID, //imageInfo
        description: insurancedetail
    };

    // Image upload syle
    const styles = {
        width: 65,
        height: 65,
        borderRadius: 30
    };

    const closeSuccess = () => {
        setinsuranceSubmitAPIRes('');
        setinsuranceSubmitAPIResError('');
    }

    const [getInsuranceListError, setGetInsuranceListError] = useState('');
    const [active, setActive] = useState(false);
    const toggleActive = useCallback(() => setActive((active) => !active), []);
    const toastMarkup = active ? (
        <Toast content={getInsuranceListError} onDismiss={toggleActive} />
    ) : null;

    const [insuranceSubmitAPIRes, setinsuranceSubmitAPIRes] = useState('');
    const successDiv = insuranceSubmitAPIRes
        ? <Banner
            onDismiss={closeSuccess}
            status="success">
            <p>{insuranceSubmitAPIRes}</p>
        </Banner>
        : '';

    const [selectedError, setinsuranceSubmitAPIResError] = useState('');
    const selectedErrordiv = selectedError
        ? <Banner
            onDismiss={closeSuccess}
            status="critical">
            <p>{selectedError}</p>
        </Banner>
        : '';

    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }

    function editInsuranceDetails(response: any) {
        setaddInsurancesModalActive((addInsurancesModalActive) => !addInsurancesModalActive);
        setInsuranceNoError('');
        setinsuranceDetailId(response.id);
        setInsuranceno(response.insuranceNumber);
        setInsuranceAmmount(String(response.amount));
        console.log(String(response.amount));
        setSelectedDate(response.fromDate);
        setSelectedEndDate(response.toDate);
        setInsuranceDetails(response.description);
        let docURL = (response.supportingDocuments.length > 0) ? response.supportingDocuments[0].document : 'document.png';
        setImageID(response.supportingDocuments.id);
        setImageInfo(docURL);
        setinsuranceSubmitAPIRes("");
        setinsuranceSubmitAPIResError("");
    }

    const [docViwerActive, setDocViwerActive] = useState(false);
    const handleDocViewerChange = useCallback(() => setDocViwerActive(!docViwerActive), [docViwerActive]);

    // get document list
    const getInsuranceList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patient/get_insurance_details_by_patient',
            {"patientId": patientid}, { headers })
            .then((res) => {
                setIsLoading(false);
                let response = res.data.insuranceList;
                console.log("INSURANCE LIST==>", response);
                let insuranceList = [];
                for (var i = 0; i < response.length; i++) {
                    let supportingDocuments = '';
                    let selectedResponse = response[i];
                    (response[i].supportingDocuments.length > 0) ? supportingDocuments = response[i].supportingDocuments[0].document : supportingDocuments = 'document.png';
                    let insuranceDescription = (response[i].description == null) ? '' : response[i].description;
                    let insuranceNumber = (response[i].insuranceNumber == null) ? '' : response[i].insuranceNumber;
                    let insuranceAmmount = (response[i].amount == null) ? '' : response[i].amount;
                    let fromDate = response[i].fromDate === null ? '' : (new Date(response[i].fromDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let toDate = response[i].toDate === null ? '' : (new Date(response[i].toDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    insuranceList.push([
                        <Button icon={NoteMinor} size="large" onClick={() => {
                            setDocViwerActive(true);
                            setImageInfo(supportingDocuments);
                        }}></Button>,
                        insuranceNumber,
                        insuranceAmmount,
                        fromDate,
                        toDate,
                        insuranceDescription,
                        <Button icon={EditMinor} size="slim" onClick={() => editInsuranceDetails(selectedResponse)}></Button>
                    ]);
                    setInsuranceList(insuranceList);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setGetInsuranceListError(error.response.data.message);
                console.log(error.response.data.message)
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }

    // insurance submit API
    const SubmitInsuranceDetails = () => {
        console.log(body);
        if (insuranceno=== "") {
            setInsuranceNoError("Please enter Insurance Number");
        }
        else {
            setIsLoading(true);
            let url, insuranceEditBody;
            if (insuranceDetailId) {
                url = baseUrl.baseUrl + '/patientapp/api/patient/edit_patient_insurance';
                insuranceEditBody = Object.assign(body, { insuranceDetailId: insuranceDetailId })
            }
            else {
                url = baseUrl.baseUrl + '/patientapp/api/patient/add_patient_insurance';

            }
            axios.post(url, JSON.stringify(body), { headers }).then(result => {
                setIsLoading(false);
                if (result) {
                    setInsuranceNoError('');
                    setinsuranceSubmitAPIRes(result.data.message);
                    addInsuranceToggle();
                    getInsuranceList();
                }
            }).catch((error) => {
                console.log(error);
                setIsLoading(false);
                setinsuranceSubmitAPIResError(error.response.data.message);
            });
        }

    }

    useEffect(() => {
        getInsuranceList();
        toggleActive();
    }, []);

    const docViewermodal = (
        <Modal
            open={docViwerActive}
            onClose={handleDocViewerChange}
        >
            <Modal.Section>
                <TextContainer>
                    <p>
                        <iframe src={imageInfo} title='Small document' width='100%' height='700' frameBorder='0'>This is an embedded <a target='_blank' href='http://office.com' rel="noopener noreferrer">Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps' rel="noopener noreferrer">Office Online</a>.</iframe>
                    </p>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );

    // ModalMarkUp
    const addinsurancemodalMarkup = (
        <Modal
            large
            open={addInsurancesModalActive}
            onClose={addInsuranceToggle}
            title={insuranceDetailId ? "Edit Insurance Details" : "Add Insurance Details"} //"Add Insurance"
            primaryAction={{
                content: 'Submit',
                onAction: SubmitInsuranceDetails,
            }}
        >
            <Modal.Section>
                <Page>
                    <FormLayout>
                        <FormLayout.Group condensed>
                            <div>
                                <div>
                                    <TextField
                                        label="Enter Insurance Number"
                                        type="text"
                                        value={insuranceno}
                                        onChange={handleInsuranceChange}
                                        placeholder="Please Enter Insurance Number"
                                        id="insuranceID"
                                    />
                                    {inuranceNoErrordiv}
                                </div>
                                <br />
                                <div>
                                    <TextField
                                        label="Insurance Coverage Amount"
                                        value={insuranceAmmount}
                                        type="number"
                                        onChange={handleInsuranceAmmountChange}
                                        placeholder="Enter Insurance Coverage Ammount"
                                    />
                                </div>
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Upload Supporting Document</p>
                                <Box style={{ textAlign: "center", border: "1px #50B83C solid", borderRadius: "3px" }} p={2}>
                                    <Stack distribution="fill">
                                        <Uploader
                                            action="https://aims2health-webserver.herokuapp.com/storageapp/api/upload-image/"
                                            draggable
                                            data={uploadbody}
                                            name="document"
                                            listType="text"
                                            onSuccess={(response: Object,) => {
                                                let res = JSON.stringify(response);
                                                setImageID(JSON.parse(res).image.id);
                                                setImageInfo(JSON.parse(res).image.document);
                                            }}
                                        >
                                            <button style={styles} type="button">
                                                {uploading && <Uploader backdrop center />}
                                                {imageInfo ? (
                                                    <img src={imageInfo} alt="" width="100%" height="100%" />
                                                ) : (
                                                    <Thumbnail source={NoteMinor} size="large" alt="Small document" />

                                                )}
                                            </button>
                                        </Uploader>
                                    </Stack>
                                </Box>
                                <Caption>
                                    <p style={{ color: 'gray' }}>Document size should be maximum 3MB.</p>
                                </Caption>
                            </div>
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Insurance Start Date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => handleDateChange(date)}
                                            size="small"
                                            focused
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Insurance End Date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedEndDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => handleEndDateChange(date)}
                                            size="small"
                                            focused
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleEndDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                        </FormLayout.Group>
                        <FormLayout.Group condensed>
                            <div>
                                <TextField
                                    label="Insurance Details"
                                    type="text"
                                    value={insurancedetail}
                                    onChange={handleInsuranceDetailsChange}
                                    placeholder="Please Enter Insurance Details"
                                    multiline={2}
                                    id="insuranceDetailsID"
                                />
                            </div>
                        </FormLayout.Group>
                        <div>
                            {successDiv}
                            {selectedErrordiv}
                        </div>
                    </FormLayout>
                </Page>
            </Modal.Section>
        </Modal>
    );

    const legalList = insuranceList ? (
        <DataTable
            columnContentTypes={[
                'text',
                'text',
                'text',
                'text',
                'text',
                'text'
            ]}
            headings={[
                'Document',
                'Insurance Number',
                'Insurance Coverage Amount',
                'From Date',
                'Valid Date',
                'Description',
                'Edit'

            ]}
            rows={insuranceList}
        />
    ) : (
        <p>No Details available</p>
    );

    const actualPageMarkup = (
        <div>
            <Card>
                <Card.Header
                    title={insuranceDetailId ? "Edit Insurance Details" : "Add Insurance Details"} //"Add Insurance"
                >
                    <Button primary={true} onClick={addInsuranceToggle}>Add Insurance Details</Button>
                </Card.Header>
                <Card.Section>
                    {legalList}
                </Card.Section>
            </Card>
        </div>
    );


    return (
        <Page>
            {loadingMarkup}
            {actualPageMarkup}
            {addinsurancemodalMarkup}
            {docViewermodal}
        </Page>
    );
}